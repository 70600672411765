import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CurrencyService } from 'src/app/core/services/currency.service';
import { Locale } from '../../../../core/interfaces';
import { LocaleService } from '../../../../core/services/locale.service';
import { VisualisationService } from '../../../../core/services/visualisation.service';

@Component({
    selector: 'app-user-menu',
    templateUrl: './user-menu.component.html',
    styleUrls: ['./user-menu.component.scss'],
})
export class UserMenuComponent implements OnInit {
    showMenu = false;
    languageArray: Locale[];
    currentLocale: Locale;
    darkMode: boolean;
    currancyArray: Array<{ title: string; isCurrent: boolean }> = [
        {
            title: 'CHF',
            isCurrent: false,
        },
        {
            title: 'USD',
            isCurrent: true,
        },
        {
            title: 'EUR',
            isCurrent: false,
        },
    ];

    constructor(
        private localeService: LocaleService,
        private visualisationService: VisualisationService,
        private router: Router,
        private currencyService: CurrencyService
    ) {
        this.languageArray = this.localeService.localeList;
        visualisationService.$darkTheme.subscribe((data) => (this.darkMode = data));
    }

    ngOnInit() {
        this.localeService.$currentLocale.subscribe((locale) => {
            this.currentLocale = locale;
        });
    }

    toggleDarkMode(event) {
        this.visualisationService.toggleTheme();
        if (this.darkMode) {
            document.body.classList.remove('light');
        } else {
            document.body.classList.add('light');
        }
    }
    toggleCurrencyValueVisibility() {
        this.currencyService.toggleCurrencyValuesVisibility();
    }
    async openFagPage() {
        await this.router.navigate(['/cabinet/faq']);
    }

    async openCommunicationCenterPage() {
        await this.router.navigate(['/cabinet/communication-center']);
    }
    toggleMenu(event) {
        this.showMenu = !this.showMenu;
        event.stopPropagation();
    }

    selectLang(locale: Locale) {
        this.localeService.changeLocale(locale);
    }

    selectCurrancy(currancy: { title: string; isCurrent: boolean }) {
        this.currancyArray.forEach((item) => {
            item.isCurrent = item.title === currancy.title;
        });
    }

    clickOutside() {
        if (this.showMenu === true) {
            this.showMenu = false;
        }
    }
}
