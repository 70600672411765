import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MetricsService {
  // potentially more methods to track metrics
  getHealth(): Observable<{ health: string }> {
    return of({ health: 'UP' });
  }
}
