import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { MissingLocaleService } from './missing-locale.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { AppComponent } from './modules/app/components/app/app.component';
import { SharedModule } from './shared/shared.module';
import { UserMenuComponent } from './modules/app/components/user-menu/user-menu.component';
import { HeaderComponent } from './modules/app/components/header/header.component';
import { HeaderSearchFieldComponent } from './modules/app/components/header-search-field/header-search-field.component';
import { UserHeaderComponent } from './modules/app/components/user-header/user-header.component';
import { MatIconModule } from '@angular/material/icon';
import { NavigationMenuComponent } from './modules/app/components/navigation-menu/navigation-menu.component';
import { LocaleModule, LanguageData } from 'models';
import { environment } from 'src/environments/environment';
import { LibraryAuthModule } from 'clan-auth';
import { DateAdapter, MatNativeDateModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { EnvironmentBannerComponent } from './shared/components/environment-banner/environment-banner.component';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { CustomDateAdapter } from './core/adapters/custom-date-adapter';
import { CUSTOM_DATE_FORMATS } from './core/adapters/custom-date-formats';
import { ErrorInterceptor } from './core/interceptors/error.interceptor';
export function HttpLoadFactory(http: HttpClient): TranslateLoader {
    return new TranslateHttpLoader(http, './assets/locale/', '.json');
}

@NgModule({
    declarations: [
        AppComponent,
        UserMenuComponent,
        HeaderComponent,
        HeaderSearchFieldComponent,
        UserHeaderComponent,
        NavigationMenuComponent,
        EnvironmentBannerComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        FormsModule,
        SharedModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatButtonModule,
        MatIconModule,
        HttpClientModule,
        LocaleModule.forRoot(<LanguageData>environment.localeData),
        LibraryAuthModule.forRoot(environment.authApiUrl),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoadFactory,
                deps: [HttpClient],
            },
            missingTranslationHandler: {
                provide: MissingTranslationHandler,
                useClass: MissingLocaleService,
            },
            useDefaultLang: false,
        }),
        CoreModule,
        MatNativeDateModule,
        PdfJsViewerModule,
    ],
    providers: [
        { provide: DateAdapter, useClass: CustomDateAdapter },
        { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
