<div class="user-header">
    <!--<di v (click)="openCommunicationCenterPage()" class="user-header__communication header-communication header-block">
        <div class="header-communication__icon header-block__icon">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M27.7746 0.249817H9.95755C7.63362 0.249817 5.73218 2.15125 5.73218 4.47519V5.91294H21.8491C24.1731 5.91294 26.0745 7.81438 26.0745 10.1383V20.6725H27.7746C30.0985 20.6725 31.9999 18.7711 31.9999 16.4471V4.47519C31.9999 2.15119 30.0985 0.249817 27.7746 0.249817Z"
                    fill="#374C7C"
                />
                <path
                    d="M22.0424 5.7197H4.22538C1.90144 5.7197 0 7.62113 0 9.94507V21.9169C0 24.2409 1.90144 26.1423 4.22538 26.1423H14.9189C15.3773 26.1423 15.7196 26.5689 15.6147 27.0152C15.3311 28.2225 14.8972 29.4993 14.2557 30.8081C13.9676 31.3959 14.6229 31.9934 15.1796 31.6489C16.7111 30.7011 20 27.5 21.0073 26.6291C21.2608 26.3193 21.6421 26.1423 22.0424 26.1423C24.3663 26.1423 26.2678 24.2408 26.2678 21.9169V9.94501C26.2678 7.62113 24.3663 5.7197 22.0424 5.7197Z"
                    fill="#73B1D0"
                />
                <path
                    d="M2.73644 21.917V9.94507C2.73644 7.62113 4.63788 5.7197 6.96181 5.7197H4.22538C1.90144 5.7197 0 7.62113 0 9.94507V21.917C0 24.2409 1.90144 26.1423 4.22538 26.1423H6.96181C4.63781 26.1423 2.73644 24.241 2.73644 21.917Z"
                    fill="#73B1D0"
                />
                <path
                    d="M5.85696 17.6769C6.63133 17.6769 7.25908 17.0492 7.25908 16.2748C7.25908 15.5004 6.63133 14.8727 5.85696 14.8727C5.08259 14.8727 4.45483 15.5004 4.45483 16.2748C4.45483 17.0492 5.08259 17.6769 5.85696 17.6769Z"
                    fill="#DFEBFA"
                />
                <path
                    d="M10.3858 17.6769C11.1601 17.6769 11.7879 17.0492 11.7879 16.2748C11.7879 15.5004 11.1601 14.8727 10.3858 14.8727C9.6114 14.8727 8.98364 15.5004 8.98364 16.2748C8.98364 17.0492 9.6114 17.6769 10.3858 17.6769Z"
                    fill="#DFEBFA"
                />
                <path
                    d="M14.9143 17.6769C15.6887 17.6769 16.3165 17.0492 16.3165 16.2748C16.3165 15.5004 15.6887 14.8727 14.9143 14.8727C14.14 14.8727 13.5122 15.5004 13.5122 16.2748C13.5122 17.0492 14.14 17.6769 14.9143 17.6769Z"
                    fill="#DFEBFA"
                />
                <path
                    d="M19.4431 17.6769C20.2175 17.6769 20.8453 17.0492 20.8453 16.2748C20.8453 15.5004 20.2175 14.8727 19.4431 14.8727C18.6688 14.8727 18.041 15.5004 18.041 16.2748C18.041 17.0492 18.6688 17.6769 19.4431 17.6769Z"
                    fill="#DFEBFA"
                />
            </svg>
        </div>
        <span
            class="header-communication__title header-block__title"
            [ngClass]="{ light: (1 | colourTheme) === false }"
        >
            {{ 'header.communicationCenter' | translate }}
        </span>
    </div> -->

    <!-- <div class="user-header__ahv header-ahv header-block">
        <span class="header-ahv__title" [ngClass]="{light: (1 | colourTheme) === false}">ahv:&#8194;</span>
        <span class="header-ahv__value">{{ userSsn }}</span>
    </div> -->

    <div class="user-header__user header-user header-block">
        <!-- Name -->
        <div class="header-user__info user-info">
            <!-- Profil Icon -->
            <div class="profilPicture" src="../../../../../assets/img/">
                <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 980.85 980.85">
                    <defs>
                        <style>
                            .cls-1 {
                                fill: var(--primary);
                                fill-rule: evenodd;
                            }
                        </style>
                    </defs>
                    <title>Profile Icon Blue</title>
                    <path
                        class="cls-1"
                        d="M500,621.78A178.34,178.34,0,1,1,678.34,443.44,178.33,178.33,0,0,1,500,621.78Zm0,368.65c270.86,0,490.43-219.57,490.43-490.43S770.86,9.57,500,9.57,9.57,229.14,9.57,500,229.14,990.43,500,990.43ZM215.7,841.82c45.67-112.14,155.75-191.2,284.3-191.2s238.64,79,284.3,191.2c98-81.56,160.3-204.4,160.3-341.82C944.6,254.45,745.55,55.4,500,55.4S55.4,254.45,55.4,500C55.4,637.42,117.76,760.27,215.7,841.82Z"
                        transform="translate(-9.57 -9.57)"
                    />
                </svg>
            </div>
            <span class="user-info__title header-block__title" [ngClass]="{ light: (1 | colourTheme) === false }">
                {{ userName }}
            </span>
            <span class="userInitials" [ngClass]="{ light: (1 | colourTheme) === false }">
                {{ initials }}
            </span>
        </div>
    </div>
    <!-- smallMenu -->
    <div class="header-user__menu">
        <app-user-menu></app-user-menu>
    </div>
    <button mat-icon-button (click)="doLogout()">
        <mat-icon>logout</mat-icon>
    </button>
</div>
