export enum BookingType {
    CashWithdrawal = 'cashWithdrawal',
    CashWithdrawalCancellation = 'cashWithdrawalCancellation',
    CashDeposit = 'cashDeposit',
    CashDepositCancellation = 'cashDepositCancellation',
    Difference = 'difference',
    DifferenceCancellation = 'differenceCancellation',
    Eod = 'eod',
    EodCancellation = 'eodCancellation',
    BankTransfer = 'bankTransfer',
    BankTransferCancellation = 'bankTransferCancellation',
    BankCredit = 'bankCredit',
    BankCreditCancellation = 'bankCreditCancellation',
    AccountClosing = 'accountClosing',
    AccountClosingCancellation = 'accountClosingCancellation',
    PensionFund = 'pensionFund',
    PensionFundCancellation = 'pensionFundCancellation',
    OldAgeCredit = 'oldAgeCredit',
    OldAgeCreditCancellation = 'oldAgeCreditCancellation',
}
