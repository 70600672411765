import { VoteMeterComponent } from './components/vote-meter/vote-meter.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { CommonButtonComponent } from './components/common-button/common-button.component';
import { CommonDocumentComponent } from './components/common-document/common-document.component';
import { DatepickerComponent } from './components/datepicker/datepicker.component';
// import { FeedCardComponent } from './components/feed-card/feed-card.component';
import { FormInputComponent } from './components/form-input/form-input.component';
import { LikeButtonComponent } from './components/like-button/like-button.component';
import { MoreButtonComponent } from './components/more-button/more-button.component';
import { PensionCalcGraphComponent } from './components/pension-calc-graph/pension-calc-graph.component';
import { SwitcherComponent } from './components/switcher/switcher.component';
import { TypeChipComponent } from './components/type-chip/type-chip.component';
// import { VoteCardComponent } from './components/vote-card/vote-card.component';
import { NamedDatepickerComponent } from './components/named-datapicker/named-datapicker.component';
import { AmountLineComponent } from './components/amount-line/amount-line.component';
import { ColourThemePipe } from './pipes/colour-theme.pipe';
import { CurrencyVisibilityPipe } from './pipes/currency-visibility.pipe';
import { SplitNumberWithCommaPipe } from './pipes/split-number-with-comma.pipe';
import { SplitPhonePipe } from './pipes/split-phone.pipe';
import { searchTablepipe } from './pipes/searchTable.pipe';
import { searchMutationspipe } from './pipes/searchMutations.pipe';
import { searchTablepipeInvoices } from './pipes/searchTableInvoices.pipe';
import { searchTableInsurances } from './pipes/searchTableInsurances.pipe';
import { MaterialModule } from '../material/material.module';
import { FormInputBaseComponent } from './components/form-input-base/form-input-base.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { PaginatorCostumComponent } from './components/paginatorCustom/paginator-costum.component';
import { NgClickOutsideModule } from 'ng-click-outside2';
import { PreloaderComponent } from './components/preloader/preloader.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { DropdownTranslateComponent } from './components/dropdown-translate/dropdown-translate.component';
import { InsurantPersonaldataEditComponent } from './components/insurant-personaldata-edit/insurant-personaldata-edit.component';
import { InsurantShowComponent } from './components/insurant-show/insurant-show.component';
import { TranslateEnumPipe } from './pipes/translate-enum.pipe';
import { CashRegisterTransactionsTableComponent } from './components/cash-register-transactions-table/cash-register-transactions-table.component';
import { MatIconModule } from '@angular/material/icon';
import { SteppablePageComponent } from './components/steppable-page/steppable-page.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { MetricsComponent } from './components/metrics/metrics.component';
@NgModule({
    declarations: [
        SwitcherComponent,
        DatepickerComponent,
        MoreButtonComponent,
        PensionCalcGraphComponent,
        LikeButtonComponent,
        TypeChipComponent,
        // FeedCardComponent,
        // VoteCardComponent,
        VoteMeterComponent,
        CommonButtonComponent,
        CheckboxComponent,
        CommonDocumentComponent,
        CurrencyVisibilityPipe,
        ColourThemePipe,
        SplitNumberWithCommaPipe,
        SplitPhonePipe,
        searchMutationspipe,
        searchTablepipe,
        searchTablepipeInvoices,
        searchTableInsurances,
        NamedDatepickerComponent,
        AmountLineComponent,
        FormInputComponent,
        FormInputBaseComponent,
        PaginatorComponent,
        PaginatorCostumComponent,
        PreloaderComponent,
        DropdownComponent,
        DropdownTranslateComponent,
        InsurantPersonaldataEditComponent,
        InsurantShowComponent,
        TranslateEnumPipe,
        CashRegisterTransactionsTableComponent,
        SteppablePageComponent,
        ConfirmationDialogComponent,
        MetricsComponent,
    ],
    imports: [
        MaterialModule,
        CommonModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        NgClickOutsideModule,
        MatIconModule,
    ],
    exports: [
        SwitcherComponent,
        DatepickerComponent,
        MoreButtonComponent,
        PensionCalcGraphComponent,
        LikeButtonComponent,
        TypeChipComponent,
        // FeedCardComponent,
        FormInputComponent,
        // VoteCardComponent,
        VoteMeterComponent,
        CommonButtonComponent,
        CheckboxComponent,
        CommonDocumentComponent,
        CurrencyVisibilityPipe,
        ColourThemePipe,
        SplitNumberWithCommaPipe,
        SplitPhonePipe,
        searchMutationspipe,
        searchTablepipe,
        searchTablepipeInvoices,
        searchTableInsurances,
        NamedDatepickerComponent,
        AmountLineComponent,
        FormInputBaseComponent,
        PaginatorComponent,
        PaginatorCostumComponent,
        PreloaderComponent,
        DropdownComponent,
        DropdownTranslateComponent,
        InsurantPersonaldataEditComponent,
        InsurantShowComponent,
        TranslateEnumPipe,
        CashRegisterTransactionsTableComponent,
        ConfirmationDialogComponent,
        MetricsComponent,
    ],
    providers: [],
})
export class SharedModule {}
