import { ValidatorFn } from '@angular/forms';

export type DateFn = () => Date;

export function validateDateBefore(date: Date, compareTo: Date, errorNamespace?: string): ValidatorFn {
    return (_) => {
        if (!date) {
            return null;
        }
        let error = null;
        if (date.valueOf() > compareTo.valueOf()) {
            error = {
                dateError: 'greater',
            };
        }
        if (errorNamespace && error) {
            const customError = {};
            customError[errorNamespace] = error;
            error = customError;
        }
        return error;
    };
}

export function validateDateAfter(date: DateFn, compareTo: DateFn, errorNamespace?: string): ValidatorFn {
    return (_) => {
        if (!date() || !compareTo()) {
            return null;
        }
        let error = null;

        if (date().valueOf() < compareTo().valueOf()) {
            error = {
                dateError: 'smaller',
            };
        }
        if (errorNamespace && error) {
            const customError = {};
            customError[errorNamespace] = error;
            error = customError;
        }
        return error;
    };
}
