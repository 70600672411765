<div class="paginator" [ngClass]="{ light: (1 | colourTheme) === false }">
    <div class="paginator-rows">
        <div class="paginator-rows-text">
            {{ countOfElements }}
            {{ (countOfElements == 1 ? 'cabinet.tableFields.result' : 'cabinet.tableFields.results') | translate }}
        </div>
        <div class="paginator-rows-text" [ngClass]="{ light: (1 | colourTheme) === false }">
            {{ 'cabinet.tableFields.rows' | translate }}
        </div>
        <div class="paginator-rows-dropdown" [ngClass]="{ light: (1 | colourTheme) === false }">
            <span (click)="setShowDropdown(!showDropdown)">{{ countOnPage }}</span>
            <svg
                (click)="setShowDropdown(!showDropdown)"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="#757575"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M7 10L12 15L17 10H7Z" fill="currentColor" />
            </svg>
            <div
                class="paginator-rows-dropdown-list"
                [ngClass]="{ light: (1 | colourTheme) === false }"
                *ngIf="showDropdown"
                (clickOutside)="setShowDropdown(false)"
            >
                <div
                    class="paginator-rows-dropdown-list-element"
                    [ngClass]="{ selected: pgs === countOnPage, light: (1 | colourTheme) === false }"
                    *ngFor="let pgs of listCountOnPage"
                    (click)="setCountPerPage(pgs)"
                >
                    {{ pgs }}
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="pages > [1]" class="paginator-pages">
        <div
            class="paginator-pages-element"
            [ngClass]="{ active: page === selectedPage, light: (1 | colourTheme) === false }"
            *ngFor="let page of pages"
            (click)="setPage(page)"
        >
            <span *ngIf="page > 0">{{ page }}</span>
            <span *ngIf="page == 0"
                ><svg width="25" height="24" viewBox="0 0 25 24" fill="#757575" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M8 12C8 10.9 7.1 10 6 10C4.9 10 4 10.9 4 12C4 13.1 4.9 14 6 14C7.1 14 8 13.1 8 12ZM10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10C10.9 10 10 10.9 10 12ZM16 12C16 13.1 16.9 14 18 14C19.1 14 20 13.1 20 12C20 10.9 19.1 10 18 10C16.9 10 16 10.9 16 12Z"
                        [attr.fill]="(1 | colourTheme) === false ? '#757575' : '#757575'"
                    />
                </svg>
            </span>
        </div>
    </div>
    <div *ngIf="pages > [1]" class="paginator-to-page" [ngClass]="{ light: (1 | colourTheme) === false }">
        <div [ngClass]="{ light: (1 | colourTheme) === false }">{{ 'cabinet.tableFields.toPage' | translate }}</div>
        <input
            class="paginator-to-page-input"
            [ngClass]="{ light: (1 | colourTheme) === false }"
            type="number"
            #paginatorSetPage
            autocomplete="off"
            (keyup.enter)="setPage(this.paginatorSetPage.value)"
        />
        <div *ngIf="pages > [1]" (click)="setPage(this.selectedPage - 1)">
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="#757575"
                xmlns="http://www.w3.org/2000/svg"
                style="transform: rotate(180deg)"
            >
                <path
                    d="M9.99984 6L8.58984 7.41L13.1698 12L8.58984 16.59L9.99984 18L15.9998 12L9.99984 6Z"
                    [attr.fill]="(1 | colourTheme) === false ? '#757575' : '#757575'"
                />
            </svg>
        </div>
        <div *ngIf="pages > [1]" (click)="setPage(this.selectedPage + 1)">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="#757575" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M9.99984 6L8.58984 7.41L13.1698 12L8.58984 16.59L9.99984 18L15.9998 12L9.99984 6Z"
                    [attr.fill]="(1 | colourTheme) === false ? '#757575' : '#757575'"
                />
            </svg>
        </div>
    </div>
</div>
