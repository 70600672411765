<div class="search-field-row">
    <div class="filter-container">
        <mat-form-field appearance="outline">
            <mat-label>{{ 'ak.labels.selectBookingType' | translate }}</mat-label>
            <mat-select [(ngModel)]="selectedBookingType" (selectionChange)="applyFilters()">
                <mat-option *ngFor="let bookingType of bookingTypes" [value]="bookingType.bookingTypeId">
                    {{ bookingType.name | translateEnum : 'ak.cashRegister.bookingType' }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>{{ 'ak.labels.enterDateRange' | translate }}</mat-label>
            <mat-date-range-input [rangePicker]="picker">
                <input matStartDate [(ngModel)]="selectedDateFrom" (dateChange)="applyFilters()" />
                <input matEndDate [(ngModel)]="selectedDateTo" (dateChange)="applyFilters()" />
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>

        <div class="inline-flex">
            <mat-form-field appearance="outline">
                <mat-label>{{ 'ak.labels.amountFrom' | translate }}</mat-label>
                <input matInput type="number" [(ngModel)]="selectedAmountFrom" (ngModelChange)="applyFilters()" />
            </mat-form-field>
            <span class="margin-auto vertical-align-middle">-</span>
        </div>
        <!-- <span class="padding-right-75rem">-</span> -->
        <mat-form-field appearance="outline">
            <mat-label>{{ 'ak.labels.amountTo' | translate }}</mat-label>
            <input matInput type="number" [(ngModel)]="selectedAmountTo" (ngModelChange)="applyFilters()" />
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>{{ 'shared.tableFields.remark' | translate }}</mat-label>
            <input matInput type="text" [(ngModel)]="selectedRemark" (ngModelChange)="applyFilters()" />
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>{{ 'shared.tableFields.bookingNr' | translate }}</mat-label>
            <input matInput type="text" [(ngModel)]="selectedBookingId" (ngModelChange)="applyFilters()" />
        </mat-form-field>
        <!-- 
        <mat-checkbox [(ngModel)]="showCancelledBookings" (ngModelChange)="applyFilters()">{{
            'ak.labels.showCancelledBookings' | translate }}</mat-checkbox> -->
    </div>
</div>
<div class="table-container">
    <table
        class="full-width"
        mat-table
        [dataSource]="dataSource"
        matSort
        (matSortChange)="onSort($event)"
        matSortDirection="desc"
    >
        <ng-container matColumnDef="createdAt">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="display-none">
                {{ 'shared.tableFields.valueDate' | translate }}
            </th>
            <td mat-cell class="display-none" *matCellDef="let element">{{ element?.createdAt }}</td>
        </ng-container>
        <ng-container matColumnDef="valueDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="uppercase bold padding-left-24">
                {{ 'shared.tableFields.valueDate' | translate }}
            </th>
            <td mat-cell class="padding-left-24" *matCellDef="let element">
                {{ element?.valueDate | date : 'dd.MM.yyyy' }}
            </td>
        </ng-container>
        <ng-container matColumnDef="bookingTypeId">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="uppercase bold">
                {{ 'shared.tableFields.bookingType' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
                {{ bookingTypeService.getBookingTypeById(element?.bookingTypeId) }}
            </td>
        </ng-container>
        <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="uppercase bold">
                {{ 'shared.tableFields.amount' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
                {{ element?.amount ? (element?.amount | splitNumberWithComma : true) : '' }}
            </td>
        </ng-container>
        <ng-container matColumnDef="balance">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="uppercase bold">
                {{ 'shared.tableFields.balance' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
                {{ element?.cashRegistryBalance ? (element?.cashRegistryBalance | splitNumberWithComma : true) : '' }}
            </td>
        </ng-container>

        <ng-container matColumnDef="runningBalance">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="uppercase bold">
                {{ 'shared.tableFields.runningBalance' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
                {{ element?.runningBalance ? (element?.runningBalance | splitNumberWithComma : true) : '0' }}
            </td>
        </ng-container>

        <ng-container matColumnDef="remark">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="uppercase bold">
                {{ 'shared.tableFields.remark' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element?.comment }}</td>
        </ng-container>
        <ng-container matColumnDef="pvClanBookingId">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="uppercase bold">
                {{ 'shared.tableFields.bookingNr' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element?.pvClanBookingId }}</td>
        </ng-container>
        <ng-container matColumnDef="transactionStatus">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="uppercase bold">
                {{ 'shared.tableFields.status' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
                <span class="status-wrapper" [ngClass]="element?.transactionStatus.toLowerCase()">
                    <mat-icon *ngIf="element?.transactionStatus === 'DONE'; else cancelled"
                        >check_circle_outline
                    </mat-icon>
                    <ng-template #cancelled>
                        <mat-icon>cancel</mat-icon>
                    </ng-template>
                    <span class="full-width">{{
                        element?.transactionStatus | translateEnum : 'ak.cashRegister.bookingStatus' : false
                    }}</span>
                </span>
            </td>
        </ng-container>
        <ng-container matColumnDef="cancelBooking">
            <th mat-header-cell *matHeaderCellDef class="uppercase bold cancel-cell">
                {{ 'shared.tableFields.cancelBooking' | translate }}
            </th>
            <td mat-cell class="cancel-cell center" *matCellDef="let element">
                <a class="icon-container">
                    <mat-icon *ngIf="isCancellable(element)" (click)="cancelTransaction(element)">close</mat-icon>
                </a>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
</div>
<mat-paginator
    class="paginator"
    [length]="totalElements"
    [pageSize]="pageSize"
    [pageIndex]="pageNumber"
    [pageSizeOptions]="[10, 20, 30, 50, 100]"
    (page)="pageEvent = getServices($event)"
></mat-paginator>
