import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { VisualisationService } from 'src/app/core/services/visualisation.service';
import { UserService } from 'src/app/core/services/user.service';
import { Company } from 'models';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
    userName: string;
    ssn: string;
    isDarkTheme: boolean;
    logoColor = 'white';
    showClanLogo = false;

    constructor(
        private userService: UserService,
        private router: Router,
        private visualisationService: VisualisationService
    ) {
        this.showClanLogo = window.location.hostname === /*'localhost'*/ 'employer.pv-clan.ch';
        this.userService.$userData.subscribe((data) => {
            this.userName = data.firstName + ' ' + data.lastName;
        });

        this.ssn = '111.222.333'; // data.ssn13;
        // });
        this.visualisationService.$darkTheme.subscribe((data) => (this.isDarkTheme = data));
    }

    private static getUserFullName(userData: Company) {
        return `${userData.name}`;
    }

    async openHomePage() {
        await this.router.navigate(['/']);
    }

    ngOnInit() {}

    showPortfolio(value) {
        this.visualisationService.togglePortfolioVisibility();
    }
}
