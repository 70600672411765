import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-dropdown-translate',
    templateUrl: './dropdown-translate.component.html',
    styleUrls: ['../dropdown/dropdown.component.scss'],
})
export class DropdownTranslateComponent implements OnInit {
    @Input() title = '';
    @Input() list = [];
    @Input() selected = '';
    @Input() localeTranslationPath = '';
    @Output() selectElementEvent: EventEmitter<string> = new EventEmitter();
    value = null;
    showMenu = false;

    constructor() {}

    ngOnInit(): void {
        this.list.forEach((item) => {
            if (item === this.selected) {
                this.value = item;
            }
        });
    }

    selectElement(element) {
        this.list.forEach((item) => {
            if (item === element) {
                this.selected = element;
                this.value = item;
                this.selectElementEvent.emit(this.value);
                this.showMenu = false;
                return;
            }
        });
    }

    openMenu() {
        this.showMenu = true;
    }

    closeMenu() {
        this.showMenu = false;
    }
}
