import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { filter } from 'rxjs/operators';
import { UserService } from 'src/app/core/services/user.service';
import { VERSION } from '../../../../../assets/version';
import { Features } from 'src/app/core/models/Constants/features';
import { Subscription } from 'rxjs';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Role } from 'models/lib/models/Role';
import { AuthenticationService } from 'clan-auth';
import { GlobalEventsService } from 'src/app/core/services/global-events.service';

@Component({
    selector: 'app-navigation-menu',
    templateUrl: './navigation-menu.component.html',
    styleUrls: ['./navigation-menu.component.scss'],
    animations: [
        trigger('openCloseNav', [
            state(
                'openNav',
                style({
                    height: '325px',
                    overflow: 'hidden',
                    //opacity: 1,
                    //backgroundColor: 'red',
                })
            ),
            state(
                'closedNav',
                style({
                    height: '55px',
                    //opacity: 0,
                    overflow: 'hidden',
                })
            ),
            transition('openNav => closedNav', [animate('0.5s')]),
            transition('closedNav => openNav', [animate('0.5s')]),
        ]),
    ],
})
export class NavigationMenuComponent implements OnInit, OnDestroy {
    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.screenWidth = window.innerWidth;
    }
    @HostListener('window:scroll', ['$event'])
    onScroll(event) {
        if (this.isOpen && this.screenWidth < 900 && window.pageYOffset > 50) {
            this.menuOpen();
        }
    }
    @Output() navigationToggle: EventEmitter<boolean> = new EventEmitter<boolean>();

    screenWidth;
    isSmallNavigationMenu = false;
    showNavigation: boolean;
    isOpen = true;
    isOpenNav = true;
    isLoggedIn = false;
    trinities: Role[] = null;
    companyNameMap: Map<string, string>;
    companyForm = new FormControl();
    dataForRoute;
    version;

    public showAK = false;
    public showCabinet = false;
    public logoUrl = '';

    private subscription: Subscription = new Subscription();

    constructor(
        private router: Router,
        public userService: UserService,
        private auth: AuthenticationService,
        private globalEventsService: GlobalEventsService
    ) {
        this.version = VERSION;

        this.subscription.add(
            this.globalEventsService.hasAk.subscribe((hasAk) => {
                this.showAK = hasAk;
            })
        );

        this.subscription.add(
            this.globalEventsService.hasCabinet.subscribe((hasCabinet) => {
                this.showCabinet = hasCabinet;
            })
        );

        this.subscription.add(
            this.globalEventsService.logo.subscribe((logo) => {
                this.logoUrl = logo;
            })
        );
    }
    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    private isFaqPage(): boolean {
        return this.router.url.includes('faq');
    }

    private isCommunicationCenterPage(): boolean {
        return this.router.url.includes('communication-center');
    }

    private isShowingNavigation(): boolean {
        // let currUserData = this.userService.getCurrentUserData();
        return !this.isFaqPage() && !this.isCommunicationCenterPage() && this.isLoggedIn;
    }

    ngOnInit() {
        this.showAK = this.globalEventsService.getHasAk();
        this.showCabinet = this.globalEventsService.getHasCabinet();

        this.userService.currentUser.subscribe((user) => {
            if (user) {
                this.isLoggedIn = true;
            }
        });

        this.screenWidth = window.innerWidth;
        if (window.innerWidth < 700) {
            this.isOpen = false;
        }
        this.showNavigation = this.isShowingNavigation();
        this.router.events
            .pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd))
            .subscribe(() => {
                this.showNavigation = this.isShowingNavigation();
            });
        this.userService.$allCompanies.subscribe({
            next: (trinities) => {
                this.trinities = trinities;
                this.companyForm.setValue(this.trinities);
            },
        });
        this.userService.$companyNameMap.subscribe({
            next: (map) => (this.companyNameMap = map),
        });

        if (this.auth.isValid()) {
            this.userService.getPersons();
            this.applyAuthorization();
        }
    }

    applyAuthorization() {
        // this.showAK = this.userService.hasFeature(Features.ak);
        // this.showBase = this.userService.hasFeature(Features.base);
    }

    changeCompany(event) {
        if (event.isUserInput) {
            if (event.source.selected) {
                this.userService.addSelectedCompany(event.source.value);
            } else {
                this.userService.removeSelectedCompany(event.source.value);
            }
        }
    }

    menuOpen() {
        if (this.isOpen == true && this.isOpenNav == true) {
            this.menuSlideOpen();
        }
        this.isOpen = !this.isOpen;
        this.navigationToggle.emit(this.isOpen);
    }

    menuSlideOpen() {
        this.isOpenNav = !this.isOpenNav;
    }
}
