export * from './hexToRgba';

export function splitNumberWithComma(value: number | string): string {
    if (!value) {
        return '';
    }
    let valueToParse = value;
    if (typeof value === 'string') {
        let plainNumStr = value.toString().replace(/'/g, '').replace('.00', '');
        valueToParse = Number.parseFloat(plainNumStr);

        if (isNaN(valueToParse) || typeof valueToParse !== 'number') {
            return ''; // Return an empty string for non-numeric input
        }
    }

    return valueToParse.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
}
