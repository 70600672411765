import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'InvoicesFilter',
    pure: false,
})
export class searchTablepipeInvoices implements PipeTransform {
    value: string;
    transform(invcoicesList: Array<any>, filter: string): any {
        if (invcoicesList) {
            return invcoicesList.filter(
                (d) =>
                    (d.person.personId != null && d.person.personId.indexOf(filter) > -1) ||
                    (d.person.personalInformation.ssn13 != null &&
                        d.person.personalInformation.ssn13.indexOf(filter) > -1) ||
                    (d.contribution.amounts.total.total != null &&
                        d.contribution.amounts.total.total.toString().indexOf(filter) > -1) ||
                    (d.contribution.invoiceId != null && d.contribution.invoiceId.toString().indexOf(filter) > -1) ||
                    (d.person.insurances[0].companyId != null &&
                        d.person.insurances[0].companyId.indexOf(filter) > -1) ||
                    (d.person.personalInformation.firstName != null &&
                        d.person.personalInformation.firstName.toLowerCase().indexOf(filter.toLowerCase()) > -1) ||
                    (d.person.personalInformation.lastName != null &&
                        d.person.personalInformation.lastName.toLowerCase().indexOf(filter.toLowerCase()) > -1)
            );
        }
    }
}
