<div *ngIf="openPopup" class="popUpViewBlocker">
    <div class="popUp">
        <div class="popUpText">{{ 'common.leaveConfirmationQuestion' | translate }}</div>
        <div class="popUpTextSmall">{{ 'common.leaveConfirmationText' | translate }}</div>
        <div class="popUpButtonBox">
            <div class="popUpButtonsNo" (click)="checkBoxAnswer('yes')">{{ 'common.yes' | translate }}</div>
            <div class="popUpButtons" (click)="checkBoxAnswer('no')">{{ 'common.no' | translate }}</div>
        </div>
    </div>
</div>

<!-- New Person -->
<div *ngIf="newPerson">
    <div
        class="employer-panel__panel-header-close"
        [ngClass]="{ light: (1 | colourTheme) === false }"
        (click)="abort('closeCreationOfUser')"
    >
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
                d="M21 12C21 7.03125 16.9688 3 12 3C7.03125 3 3 7.03125 3 12C3 16.9688 7.03125 21 12 21C16.9688 21 21 16.9688 21 12Z"
                stroke="black"
                stroke-miterlimit="10"
            />
            <path d="M15 15L9 9" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M9 15L15 9" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        {{ 'cabinet.navigation.close' | translate }}
    </div>
    <form [formGroup]="form" (ngSubmit)="submit()">
        <!-- Personal Details -->
        <mat-tab-group [(selectedIndex)]="selectedIndex" class="test">
            <!-- Personal Details -->
            <div class="test" *ngIf="newPerson">
                <mat-tab
                    [label]="'cabinet.profile.personalDetails' | translate"
                    [ngClass]="{ light: (1 | colourTheme) === false }"
                >
                    <div class="personalData-MainBlock" *ngIf="!showSpinner">
                        <div class="newPersonFields">
                            <div class="createTitle">{{ 'cabinet.profile.personalDetails' | translate }}</div>
                            <div class="scrollable">
                                <div class="circleContainer">
                                    <div class="circleDone"></div>
                                </div>
                                <div [ngClass]="step1 ? 'devisionBoxesNewEmployeeDone' : 'devisionBoxesNewEmployee'">
                                    <div class="devisionBoxesInside">
                                        <mat-form-field appearance="outline">
                                            <mat-label>{{ 'cabinet.profile.personal.mandant' | translate }}</mat-label>
                                            <mat-select formControlName="mandant" (selectionChange)="step('one')">
                                                <mat-option *ngFor="let company of allCompanies" [value]="company">{{
                                                    companyNameMap.get(company.companyId)
                                                }}</mat-option>
                                            </mat-select>
                                            <mat-error *ngIf="form.controls.mandant.invalid">{{
                                                'auth.errors.mandant' | translate
                                            }}</mat-error>
                                        </mat-form-field>

                                        <mat-form-field appearance="outline">
                                            <mat-label>{{ 'cabinet.profile.personal.sov' | translate }}</mat-label>
                                            <input
                                                matInput
                                                formControlName="sov"
                                                [(ngModel)]="sovInput"
                                                (keyup)="sovPoints($event)"
                                                (keyup)="step('one')"
                                                (keypress)="numberOnly($event)"
                                            />
                                            <mat-error *ngIf="form.controls.sov.invalid">{{
                                                'auth.errors.sov' | translate
                                            }}</mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="circleContainer">
                                    <div [ngClass]="step1 ? 'circleDone' : 'circle'"></div>
                                </div>
                                <div [ngClass]="step2 ? 'devisionBoxesNewEmployeeDone' : 'devisionBoxesNewEmployee'">
                                    <div class="devisionBoxesInside">
                                        <mat-form-field appearance="outline">
                                            <mat-label>{{
                                                'cabinet.profile.personal.firstName' | translate
                                            }}</mat-label>
                                            <input
                                                matInput
                                                formControlName="firstName"
                                                type="text"
                                                (keyup)="step('two')"
                                            />
                                            <mat-error *ngIf="form.controls.firstName.invalid">{{
                                                'auth.errors.firstName' | translate
                                            }}</mat-error>
                                        </mat-form-field>
                                        <mat-form-field appearance="outline">
                                            <mat-label>{{ 'cabinet.profile.personal.lastName' | translate }}</mat-label>
                                            <input
                                                matInput
                                                formControlName="lastName"
                                                type="text"
                                                (keyup)="step('two')"
                                            />
                                            <mat-error *ngIf="form.controls.lastName.invalid">{{
                                                'auth.errors.lastName' | translate
                                            }}</mat-error>
                                        </mat-form-field>
                                        <mat-form-field appearance="outline">
                                            <mat-label>{{
                                                'cabinet.profile.personal.dateOfBirth' | translate
                                            }}</mat-label>
                                            <input
                                                matInput
                                                formControlName="DateOfBirth"
                                                type="date"
                                                (keyup)="step('two')"
                                                (change)="step('two')"
                                                min="{{ minDateOfBirth | date : 'yyyy-MM-dd' }}"
                                                max="{{ maxDateOfBirth | date : 'yyyy-MM-dd' }}"
                                            />
                                            <mat-error *ngIf="form.controls.DateOfBirth.invalid">{{
                                                'auth.errors.dateOfBirth' | translate
                                            }}</mat-error>
                                        </mat-form-field>
                                        <mat-form-field appearance="outline">
                                            <mat-label>{{ 'cabinet.profile.personal.gender' | translate }}</mat-label>
                                            <mat-select
                                                ngDefaultControl
                                                formControlName="genderSelect"
                                                required
                                                (selectionChange)="step('two')"
                                            >
                                                <mat-option *ngFor="let gender of genderList" [value]="gender">
                                                    {{ 'cabinet.profile.personal.genderCode.' + gender | translate }}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error *ngIf="form.controls.genderSelect.invalid">{{
                                                'auth.errors.gender' | translate
                                            }}</mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="circleContainer">
                                    <div [ngClass]="step2 ? 'circleDone' : 'circle'"></div>
                                </div>
                                <div [ngClass]="step3 ? 'devisionBoxesNewEmployeeDone' : 'devisionBoxesNewEmployee'">
                                    <div class="devisionBoxesInside">
                                        <mat-form-field appearance="outline">
                                            <mat-label>{{ 'cabinet.profile.personal.street' | translate }}</mat-label>
                                            <input
                                                matInput
                                                formControlName="street"
                                                type="text"
                                                (keyup)="step('three')"
                                            />
                                        </mat-form-field>
                                        <mat-form-field appearance="outline">
                                            <mat-label>{{ 'cabinet.profile.personal.poBox' | translate }}</mat-label>
                                            <input
                                                matInput
                                                formControlName="poBox"
                                                type="text"
                                                (keyup)="step('three')"
                                            />
                                        </mat-form-field>
                                        <mat-form-field appearance="outline">
                                            <mat-label>
                                                {{ 'cabinet.profile.personal.postalCode' | translate }}</mat-label
                                            >
                                            <input
                                                matInput
                                                formControlName="postalCode"
                                                type="text"
                                                (keyup)="step('three')"
                                            />
                                            <mat-error *ngIf="form.controls.postalCode.invalid">{{
                                                'auth.errors.postalCode' | translate
                                            }}</mat-error>
                                        </mat-form-field>
                                        <mat-form-field appearance="outline">
                                            <mat-label>{{ 'cabinet.profile.personal.city' | translate }}</mat-label>
                                            <input
                                                matInput
                                                formControlName="city"
                                                type="text"
                                                (keyup)="step('three')"
                                            />
                                            <mat-error *ngIf="form.controls.city.invalid">{{
                                                'auth.errors.city' | translate
                                            }}</mat-error>
                                        </mat-form-field>

                                        <mat-form-field appearance="outline">
                                            <mat-label>{{ 'cabinet.profile.personal.country' | translate }}</mat-label>
                                            <mat-select
                                                ngDefaultControl
                                                formControlName="country"
                                                (selectionChange)="step('three')"
                                            >
                                                <mat-option *ngFor="let country of countryList" [value]="country">
                                                    {{ country }}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error *ngIf="form.controls.country.invalid">{{
                                                'auth.errors.country' | translate
                                            }}</mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="circleContainer">
                                    <div [ngClass]="step3 ? 'circleDone' : 'circle'"></div>
                                </div>
                                <div [ngClass]="step4 ? 'devisionBoxesNewEmployeeDone' : 'devisionBoxesNewEmployee'">
                                    <div class="devisionBoxesInside">
                                        <mat-form-field appearance="outline">
                                            <mat-label>{{
                                                'cabinet.profile.personal.maritalStatus' | translate
                                            }}</mat-label>
                                            <mat-select
                                                ngDefaultControl
                                                formControlName="statusSelect"
                                                required
                                                (selectionChange)="step('four')"
                                            >
                                                <mat-option *ngFor="let status of maritalStatusList" [value]="status">
                                                    {{
                                                        'cabinet.profile.personal.maritalStatusCode.' + status
                                                            | translate
                                                    }}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error *ngIf="form.controls.statusSelect.invalid">{{
                                                'auth.errors.maritalStatus' | translate
                                            }}</mat-error>
                                        </mat-form-field>
                                        <mat-form-field appearance="outline">
                                            <mat-label>{{ 'cabinet.profile.personal.language' | translate }}</mat-label>
                                            <mat-select
                                                ngDefaultControl
                                                formControlName="languageSelect"
                                                required
                                                (selectionChange)="step('four')"
                                            >
                                                <mat-option *ngFor="let lang of languageList" [value]="lang">
                                                    {{ lang }}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error *ngIf="form.controls.languageSelect.invalid">{{
                                                'auth.errors.language' | translate
                                            }}</mat-error>
                                        </mat-form-field>
                                        <mat-form-field appearance="outline">
                                            <mat-label>{{ 'cabinet.profile.personal.email' | translate }}</mat-label>
                                            <input matInput formControlName="email" type="text" />
                                        </mat-form-field>

                                        <mat-form-field appearance="outline">
                                            <mat-label>{{ 'cabinet.profile.personal.phone' | translate }}</mat-label>
                                            <input matInput formControlName="phone" type="text" />
                                        </mat-form-field>

                                        <mat-form-field appearance="outline">
                                            <mat-label>{{
                                                'cabinet.profile.personal.telephone' | translate
                                            }}</mat-label>
                                            <input matInput formControlName="telephone" type="text" />
                                        </mat-form-field>
                                    </div>
                                </div>
                                <!-- <div class="circleContainer">    
            <div [ngClass]="step4 ? 'circleDone': 'circle'"></div>
        </div> -->

                                <div class="circleContainer">
                                    <div [ngClass]="step4 ? 'circleDone' : 'circle'"></div>
                                </div>
                                <div [ngClass]="step5 ? 'devisionBoxesNewEmployeeDone' : 'devisionBoxesNewEmployee'">
                                    <div class="devisionBoxesInside">
                                        <div class="workRelationTitle">
                                            {{ 'cabinet.profile.employmentInformation' | translate }}
                                        </div>
                                        <mat-form-field appearance="outline">
                                            <mat-label>{{
                                                'cabinet.profile.employment.entryDate' | translate
                                            }}</mat-label>
                                            <input
                                                matInput
                                                formControlName="entryDate"
                                                type="date"
                                                (change)="setValidityDate($event)"
                                                (keyup)="step('five')"
                                                (change)="step('five')"
                                            />
                                            <mat-error *ngIf="form.controls.entryDate.invalid">{{
                                                'auth.errors.entryDate' | translate
                                            }}</mat-error>
                                        </mat-form-field>
                                        <mat-form-field appearance="outline">
                                            <mat-label>{{ 'cabinet.profile.employment.salary' | translate }}</mat-label>
                                            <input
                                                matInput
                                                formControlName="salary"
                                                (click)="$event.target.select()"
                                                type="text"
                                                (keyup)="step('five')"
                                            />
                                            <mat-error *ngIf="form.controls.salary.invalid">{{
                                                'auth.errors.salary' | translate
                                            }}</mat-error>
                                        </mat-form-field>
                                        <mat-form-field appearance="outline">
                                            <mat-label>{{ 'cabinet.profile.employment.degree' | translate }}</mat-label>
                                            <input
                                                matInput
                                                formControlName="employmentDegree"
                                                (click)="$event.target.select()"
                                                type="number"
                                                (keyup)="step('five')"
                                            />
                                            <mat-error *ngIf="form.controls.employmentDegree.invalid">{{
                                                'auth.errors.employmentDegree' | translate
                                            }}</mat-error>
                                        </mat-form-field>
                                        <mat-form-field appearance="outline">
                                            <mat-label>{{
                                                'cabinet.profile.employment.premiumWaiver' | translate
                                            }}</mat-label>
                                            <input
                                                matInput
                                                formControlName="premiumWaiver"
                                                (click)="$event.target.select()"
                                                type="number"
                                            />
                                        </mat-form-field>

                                        <mat-form-field appearance="outline">
                                            <mat-label>{{ 'cabinet.profile.employment.grad' | translate }}</mat-label>
                                            <input
                                                matInput
                                                formControlName="iv"
                                                (click)="$event.target.select()"
                                                type="number"
                                            />
                                        </mat-form-field>
                                        <div style="display: none">
                                            <mat-form-field appearance="outline">
                                                <mat-label>{{
                                                    'cabinet.profile.employment.validityDate' | translate
                                                }}</mat-label>
                                                <input matInput formControlName="validityDate" type="date" />
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>

                                <div class="circleContainer">
                                    <div [ngClass]="step5 ? 'circleDone' : 'circle'"></div>
                                </div>
                                <div class="placeholderForScroll"></div>

                                <div *ngIf="newPerson && !showSpinner && !blendOutSaveButton"></div>
                            </div>
                        </div>
                    </div>
                </mat-tab>
            </div>

            <!-- Empolyment Information NewEmployee-->
            <mat-tab [label]="'cabinet.profile.employmentInformation' | translate">
                <!-- Spinner -->
                <!-- <div *ngIf="showSpinner" class="spinnerCenterAlignContainer">
            <div class="spinnerContainer">
                <div class="dotContainer"><div class="dot-left"></div></div>
                <div class="dotContainer"><div class="dot-middle"></div></div>
                <div class="dotContainer"><div class="dot-right"></div></div>
            </div>
        </div>     -->
                <div *ngIf="!showSpinner">
                    <!--            TODO: copied from insurant show extract to own component-->
                    <div *ngIf="!newPerson">
                        <div class="MainBox">
                            <div
                                class="employment-info-main__block-subtitle"
                                [ngClass]="{ light: (1 | colourTheme) === false }"
                            >
                                {{ 'cabinet.profile.employment.title1' | translate }}
                            </div>
                            <div
                                class="employment-info-main__block-row"
                                [ngClass]="{ light: (1 | colourTheme) === false }"
                            >
                                <div
                                    class="employment-info-main__block-row-element"
                                    [ngClass]="{ light: (1 | colourTheme) === false }"
                                >
                                    <div
                                        class="employment-info-main__block-row-element_title"
                                        class="employmentInfoTitle"
                                        [ngClass]="{ light: (1 | colourTheme) === false }"
                                    >
                                        {{ 'cabinet.profile.employment.salary' | translate }}
                                    </div>
                                    <div
                                        class="employment-info-main__block-row-element_text"
                                        class="employmentInfoText"
                                        [ngClass]="{ light: (1 | colourTheme) === false }"
                                    >
                                        {{
                                            person
                                                ? (person.insurances[0].salaries[0].amount | splitNumberWithComma)
                                                : ''
                                        }}
                                    </div>
                                </div>

                                <div
                                    class="employment-info-main__block-row-element"
                                    [ngClass]="{ light: (1 | colourTheme) === false }"
                                >
                                    <div
                                        class="employment-info-main__block-row-element_title"
                                        class="employmentInfoTitle"
                                        [ngClass]="{ light: (1 | colourTheme) === false }"
                                    >
                                        {{ 'cabinet.profile.employment.validityDate' | translate }}
                                    </div>
                                    <div
                                        class="employment-info-main__block-row-element_text"
                                        class="employmentInfoText"
                                        [ngClass]="{ light: (1 | colourTheme) === false }"
                                    >
                                        {{
                                            (person && person.insurances[0].salaries[0].validity
                                                ? convertToDate(person.insurances[0].salaries[0].validity)
                                                : ''
                                            ) | date : 'dd.MM.yyyy'
                                        }}
                                    </div>
                                </div>

                                <div
                                    class="employment-info-main__block-row-element"
                                    [ngClass]="{ light: (1 | colourTheme) === false }"
                                >
                                    <div
                                        class="employment-info-main__block-row-element_title"
                                        class="employmentInfoTitle"
                                        [ngClass]="{ light: (1 | colourTheme) === false }"
                                    >
                                        {{ 'cabinet.profile.employment.degree' | translate }}
                                    </div>
                                    <div
                                        class="employment-info-main__block-row-element_text"
                                        class="employmentInfoText"
                                        [ngClass]="{ light: (1 | colourTheme) === false }"
                                    >
                                        {{
                                            person && person.insurances
                                                ? person.insurances[0].salaries[0].levelOfEmployment
                                                : ''
                                        }}
                                    </div>
                                </div>

                                <div
                                    class="employment-info-main__block-row-element"
                                    [ngClass]="{ light: (1 | colourTheme) === false }"
                                >
                                    <div
                                        class="employment-info-main__block-row-element_title"
                                        class="employmentInfoTitle"
                                        [ngClass]="{ light: (1 | colourTheme) === false }"
                                    >
                                        {{ 'cabinet.profile.employment.premiumWaiver' | translate }}
                                    </div>
                                    <div
                                        class="employment-info-main__block-row-element_text"
                                        class="employmentInfoText"
                                        [ngClass]="{ light: (1 | colourTheme) === false }"
                                    >
                                        {{
                                            person && person.insurances
                                                ? person.insurances[0].salaries[0].levelOfPremiumWaiver
                                                : ''
                                        }}
                                    </div>
                                </div>

                                <div
                                    class="employment-info-main__block-row-element"
                                    [ngClass]="{ light: (1 | colourTheme) === false }"
                                >
                                    <div
                                        class="employment-info-main__block-row-element_title"
                                        class="employmentInfoTitle"
                                        [ngClass]="{ light: (1 | colourTheme) === false }"
                                    >
                                        {{ 'cabinet.profile.employment.grad' | translate }}
                                    </div>
                                    <div
                                        class="employment-info-main__block-row-element_text"
                                        class="employmentInfoText"
                                        [ngClass]="{ light: (1 | colourTheme) === false }"
                                    >
                                        {{
                                            person && person.insurances
                                                ? person.insurances[0].salaries[0].levelOfDisability
                                                : ''
                                        }}
                                    </div>
                                </div>
                            </div>
                            <div
                                class="employment-info-main__block-subtitle"
                                [ngClass]="{ light: (1 | colourTheme) === false }"
                            >
                                {{ 'cabinet.profile.employment.title2' | translate }}
                            </div>
                            <div
                                class="employment-info-main__block-row"
                                [ngClass]="{ light: (1 | colourTheme) === false }"
                            >
                                <div
                                    class="employment-info-main__block-row-element"
                                    [ngClass]="{ light: (1 | colourTheme) === false }"
                                >
                                    <div
                                        class="employment-info-main__block-row-element_title"
                                        class="employmentInfoTitle"
                                        [ngClass]="{ light: (1 | colourTheme) === false }"
                                    >
                                        {{ 'cabinet.profile.employment.entryDate' | translate }}
                                    </div>
                                    <div
                                        class="employment-info-main__block-row-element_text"
                                        class="employmentInfoText"
                                        [ngClass]="{ light: (1 | colourTheme) === false }"
                                    >
                                        {{
                                            (person && person.insurances[0].employmentAdmittance
                                                ? convertToDate(person.insurances[0].employmentAdmittance)
                                                : ''
                                            ) | date : 'dd.MM.yyyy'
                                        }}
                                    </div>
                                </div>

                                <div
                                    class="employment-info-main__block-row-element"
                                    [ngClass]="{ light: (1 | colourTheme) === false }"
                                >
                                    <div
                                        class="employment-info-main__block-row-element_title"
                                        class="employmentInfoTitle"
                                        [ngClass]="{ light: (1 | colourTheme) === false }"
                                    >
                                        {{ 'cabinet.profile.employment.insuranceStartDate' | translate }}
                                    </div>
                                    <div
                                        class="employment-info-main__block-row-element_text"
                                        class="employmentInfoText"
                                        [ngClass]="{ light: (1 | colourTheme) === false }"
                                    >
                                        {{
                                            (person && person.insurances[0].insuranceAdmittance
                                                ? convertToDate(person.insurances[0].insuranceAdmittance)
                                                : ''
                                            ) | date : 'dd.MM.yyyy'
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-tab>

            <!-- Family Information -->
            <mat-tab [label]="'cabinet.profile.familyInformation' | translate">
                <div class="scrollable">
                    <div class="editSubTitle" [ngClass]="{ light: (1 | colourTheme) === false }">
                        {{ 'cabinet.profile.family.partner' | translate }}
                    </div>

                    <!--TODO add Buttons for adding family members-->
                    <div *ngIf="!hasPartner" class="hasNoPartner">
                        {{ 'cabinet.profile.family.noPartner' | translate }}
                    </div>
                    <div *ngIf="hasPartner">
                        <div class="devisionBoxes">
                            <mat-form-field appearance="fill">
                                <mat-label>{{ 'cabinet.profile.personal.lastName' | translate }}</mat-label>
                                <input matInput formControlName="lastNamePartner" type="text" />
                            </mat-form-field>

                            <mat-form-field appearance="fill">
                                <mat-label>{{ 'cabinet.profile.personal.firstName' | translate }}</mat-label>
                                <input matInput formControlName="firstNamePartner" type="text" />
                            </mat-form-field>

                            <mat-form-field appearance="fill">
                                <mat-label>{{ 'cabinet.profile.personal.sov' | translate }}</mat-label>
                                <input matInput formControlName="sovPartner" type="text" />
                            </mat-form-field>

                            <mat-form-field appearance="fill">
                                <mat-label>{{ 'cabinet.profile.personal.dateOfBirth' | translate }}</mat-label>
                                <input matInput formControlName="dateOfBirthPartner" type="date" />
                            </mat-form-field>

                            <mat-form-field appearance="fill">
                                <mat-label>{{ 'cabinet.profile.personal.gender' | translate }}</mat-label>
                                <mat-select ngDefaultControl formControlName="genderSelectPartner">
                                    <mat-option *ngFor="let gender of genderList" [value]="gender">
                                        {{ 'cabinet.profile.personal.genderCode.' + gender | translate }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field appearance="fill">
                                <mat-label>{{ 'cabinet.profile.personal.maritalDate' | translate }}</mat-label>
                                <input matInput formControlName="maritalDate" type="date" />
                            </mat-form-field>

                            <mat-form-field appearance="fill">
                                <mat-label>{{ 'cabinet.profile.personal.divorceDate' | translate }}</mat-label>
                                <input matInput formControlName="divorceDate" type="date" />
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="editSubTitle" [ngClass]="{ light: (1 | colourTheme) === false }">
                        {{ 'cabinet.profile.family.children' | translate }}

                        <!--    <button type='button' *ngIf="!isAddChildren" class="btnAddChildren" (click)="addChildren()"><div><svg width="22" height="22" viewBox="0 0 16 16" style="display: block;" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.6667 8.66683H8.66668V12.6668H7.33334V8.66683H3.33334V7.3335H7.33334V3.3335H8.66668V7.3335H12.6667V8.66683Z" fill="white"/>
                </svg></div>{{ 'cabinet.profile.family.addChildren' | translate }}</button>
                <button type='button' *ngIf="isAddChildren" class="btnAddChildrenAbort" (click)="addChildrenAbort()">{{ 'cabinet.profile.buttons.cancel' | translate }}</button>
             -->
                    </div>
                    <!--  <div class="useFullLine"> -->

                    <!-- </div> -->

                    <!--            TODO: extract in language-->

                    <!--            TODO: extract children show to its own component-->

                    <!-- TODO: maybe say no children if none?-->
                    <div *ngIf="!hasChildren" class="hasNoPartner">
                        {{ 'cabinet.profile.family.noChildren' | translate }}
                    </div>
                    <div *ngIf="hasChildren">
                        <div
                            class="family-info-main__block-row-main-children"
                            [ngClass]="{ light: (1 | colourTheme) === false }"
                            *ngFor="let child of children"
                        >
                            <div
                                class="employment-info-main__block-row-children"
                                [ngClass]="{ light: (1 | colourTheme) === false }"
                            >
                                <div
                                    class="employment-info-main__block-row-element"
                                    [ngClass]="{ light: (1 | colourTheme) === false }"
                                >
                                    <div
                                        class="family-info-main__block-row-element_title"
                                        class="employmentInfoTitle"
                                        [ngClass]="{ light: (1 | colourTheme) === false }"
                                    >
                                        {{ 'cabinet.profile.family.name' | translate }}
                                    </div>
                                    <div
                                        class="family-info-main__block-row-element_text"
                                        class="employmentInfoText"
                                        [ngClass]="{ light: (1 | colourTheme) === false }"
                                    >
                                        {{ child.firstName }}
                                    </div>
                                </div>
                                <div
                                    class="employment-info-main__block-row-element"
                                    [ngClass]="{ light: (1 | colourTheme) === false }"
                                >
                                    <div
                                        class="family-info-main__block-row-element_title"
                                        class="employmentInfoTitle"
                                        [ngClass]="{ light: (1 | colourTheme) === false }"
                                    >
                                        {{ 'cabinet.profile.family.lastName' | translate }}
                                    </div>
                                    <div
                                        class="family-info-main__block-row-element_text"
                                        class="employmentInfoText"
                                        [ngClass]="{ light: (1 | colourTheme) === false }"
                                    >
                                        {{ child.lastName }}
                                    </div>
                                </div>
                                <div
                                    class="employment-info-main__block-row-element"
                                    [ngClass]="{ light: (1 | colourTheme) === false }"
                                >
                                    <div
                                        class="family-info-main__block-row-element_title"
                                        class="employmentInfoTitle"
                                        [ngClass]="{ light: (1 | colourTheme) === false }"
                                    >
                                        {{ 'cabinet.profile.family.number' | translate }}
                                    </div>
                                    <div
                                        class="family-info-main__block-row-element_text"
                                        class="employmentInfoText"
                                        [ngClass]="{ light: (1 | colourTheme) === false }"
                                    >
                                        {{ child.ssn13 }}
                                    </div>
                                </div>
                                <div
                                    class="employment-info-main__block-row-element"
                                    [ngClass]="{ light: (1 | colourTheme) === false }"
                                >
                                    <div
                                        class="family-info-main__block-row-element_title"
                                        class="employmentInfoTitle"
                                        [ngClass]="{ light: (1 | colourTheme) === false }"
                                    >
                                        {{ 'cabinet.profile.family.date' | translate }}
                                    </div>
                                    <div
                                        class="family-info-main__block-row-element_text"
                                        class="employmentInfoText"
                                        [ngClass]="{ light: (1 | colourTheme) === false }"
                                    >
                                        {{ convertToDate(child.dateOfBirth) | date : 'dd.MM.yyyy' }}
                                    </div>
                                </div>
                                <div
                                    cclass="employment-info-main__block-row-element"
                                    [ngClass]="{ light: (1 | colourTheme) === false }"
                                >
                                    <div
                                        class="family-info-main__block-row-element_title"
                                        class="employmentInfoTitle"
                                        [ngClass]="{ light: (1 | colourTheme) === false }"
                                    >
                                        {{ 'cabinet.profile.family.sex' | translate }}
                                    </div>
                                    <div
                                        class="family-info-main__block-row-element_text"
                                        class="employmentInfoText"
                                        [ngClass]="{ light: (1 | colourTheme) === false }"
                                    >
                                        {{ 'cabinet.profile.personal.genderCode.' + child.sex | translate }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="isAddChildren">
                        <form [formGroup]="childForm" (ngSubmit)="addChild()">
                            <div class="devisionBoxes">
                                <mat-form-field appearance="fill">
                                    <mat-label>{{ 'cabinet.profile.personal.lastName' | translate }}</mat-label>
                                    <input matInput formControlName="lastNameChild" type="text" />
                                </mat-form-field>

                                <mat-form-field appearance="fill">
                                    <mat-label>{{ 'cabinet.profile.personal.firstName' | translate }}</mat-label>
                                    <input matInput formControlName="firstNameChild" type="text" />
                                </mat-form-field>

                                <mat-form-field appearance="fill">
                                    <mat-label>{{ 'cabinet.profile.personal.sov' | translate }}</mat-label>
                                    <input matInput formControlName="sovChild" type="text" />
                                </mat-form-field>

                                <mat-form-field appearance="fill">
                                    <mat-label>{{ 'cabinet.profile.personal.dateOfBirth' | translate }}</mat-label>
                                    <input matInput formControlName="dateOfBirthChild" type="date" />
                                </mat-form-field>

                                <mat-form-field appearance="fill">
                                    <mat-label>{{ 'cabinet.profile.personal.gender' | translate }}</mat-label>
                                    <mat-select matNativeControl formControlName="genderSelectChild">
                                        <mat-option *ngFor="let gender of genderList" [value]="gender">
                                            {{ 'cabinet.profile.personal.genderCode.' + gender | translate }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </form>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
        <!-- Old working logic for display in 2 steps -->

        <div class="stickyBoxForCreateInsurantButton" *ngIf="selectedIndex === 0" style="padding-left: 25%">
            <button
                class="button"
                type="button"
                class="btnSubmit"
                (click)="openConfirmatinForNewEmployee()"
                style="margin-right: 40px"
                [disabled]="!firstFormValid"
            >
                {{ 'cabinet.profile.buttons.save' | translate }}
            </button>
            <button
                class="button"
                type="button"
                class="btnSubmit"
                class="cancle"
                style="float: left"
                (click)="abort('closeCreationOfUser')"
            >
                {{ 'cabinet.profile.buttons.cancel' | translate }}
            </button>
        </div>
    </form>
</div>

<!-- Edit selected Person -->
<div *ngIf="!newPerson">
    <form [formGroup]="form" (ngSubmit)="submit()">
        <div class="personalData-MainBlock" *ngIf="!showSpinner">
            <div class="progressDisplay" *ngIf="newPerson">
                {{ selectedIndexDisplay }}/2 {{ 'cabinet.profile.personalDetails' | translate }}
            </div>
            <div class="scrollable">
                <!-- Row Personal-->
                <div class="titleRow">
                    <div class="titleRow-title">{{ 'cabinet.profile.personalDetails' | translate }}</div>
                </div>
                <div class="devisionBoxes">
                    <mat-form-field appearance="fill">
                        <mat-label>{{ 'cabinet.profile.personal.firstName' | translate }}</mat-label>
                        <input matInput formControlName="firstName" type="text" />
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>{{ 'cabinet.profile.personal.lastName' | translate }}</mat-label>
                        <input matInput formControlName="lastName" type="text" />
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>{{ 'cabinet.profile.personal.sov' | translate }}</mat-label>
                        <input
                            matInput
                            formControlName="sov"
                            [(ngModel)]="sovInput"
                            (keyup)="sovPoints($event)"
                            (keypress)="numberOnly($event)"
                        />
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>{{ 'cabinet.profile.personal.dateOfBirth' | translate }}</mat-label>
                        <input
                            matInput
                            formControlName="DateOfBirth"
                            type="date"
                            min="{{ minDateOfBirth | date : 'yyyy-MM-dd' }}"
                            max="{{ maxDateOfBirth | date : 'yyyy-MM-dd' }}"
                        />
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>{{ 'cabinet.profile.personal.gender' | translate }}</mat-label>
                        <mat-select ngDefaultControl formControlName="genderSelect" required>
                            <mat-option *ngFor="let gender of genderList" [value]="gender">
                                {{ 'cabinet.profile.personal.genderCode.' + gender | translate }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>{{ 'cabinet.profile.personal.maritalStatus' | translate }}</mat-label>
                        <mat-select ngDefaultControl formControlName="statusSelect" required>
                            <mat-option *ngFor="let status of maritalStatusList" [value]="status">
                                {{ 'cabinet.profile.personal.maritalStatusCode.' + status | translate }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>{{ 'cabinet.profile.personal.language' | translate }}</mat-label>
                        <mat-select ngDefaultControl formControlName="languageSelect" required>
                            <mat-option *ngFor="let lang of languageList" [value]="lang">
                                {{ lang }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <!-- Row Contacdetails-->
                <div class="titleRow">
                    <div class="titleRow-title">{{ 'cabinet.profile.contactDetails' | translate }}</div>
                </div>
                <div class="devisionBoxes">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ 'cabinet.profile.personal.street' | translate }}</mat-label>
                        <input matInput formControlName="street" (keyup)="formChanged()" type="text" />
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>{{ 'cabinet.profile.personal.poBox' | translate }}</mat-label>
                        <input matInput formControlName="poBox" (keyup)="formChanged()" type="text" />
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label> {{ 'cabinet.profile.personal.postalCode' | translate }}</mat-label>
                        <input matInput formControlName="postalCode" (keyup)="formChanged()" type="text" />
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>{{ 'cabinet.profile.personal.city' | translate }}</mat-label>
                        <input matInput formControlName="city" (keyup)="formChanged()" type="text" />
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>{{ 'cabinet.profile.personal.country' | translate }}</mat-label>
                        <mat-select ngDefaultControl formControlName="country" (ngModelChange)="formChanged()">
                            <mat-option *ngFor="let country of countryList" [value]="country">
                                {{ country }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>{{ 'cabinet.profile.personal.phone' | translate }}</mat-label>
                        <input matInput formControlName="phone" (keyup)="formChanged()" type="text" />
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>{{ 'cabinet.profile.personal.email' | translate }}</mat-label>
                        <input matInput formControlName="email" (keyup)="formChanged()" type="text" />
                    </mat-form-field>
                </div>
                <!-- Row Family Info-->
                <div class="titleRow">
                    <div class="titleRow-title">{{ 'cabinet.profile.familyInformation' | translate }}</div>
                </div>
                <div class="devisionBoxes" style="border: none">
                    <mat-form-field appearance="fill">
                        <mat-label>{{ 'cabinet.profile.personal.firstName' | translate }}</mat-label>
                        <input matInput formControlName="firstNamePartner" type="text" />
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>{{ 'cabinet.profile.personal.lastName' | translate }}</mat-label>
                        <input matInput formControlName="lastNamePartner" type="text" />
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>{{ 'cabinet.profile.personal.dateOfBirth' | translate }}</mat-label>
                        <input
                            matInput
                            formControlName="dateOfBirthPartner"
                            type="date"
                            min="{{ minDateOfBirth | date : 'yyyy-MM-dd' }}"
                            max="{{ maxDateOfBirth | date : 'yyyy-MM-dd' }}"
                        />
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>{{ 'cabinet.profile.personal.gender' | translate }}</mat-label>
                        <mat-select ngDefaultControl formControlName="genderSelectPartner" required>
                            <mat-option *ngFor="let gender of genderList" [value]="gender">
                                {{ 'cabinet.profile.personal.genderCode.' + gender | translate }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div *ngIf="newPerson" class="newPersonContainer">
            <div class="progressDisplay" *ngIf="newPerson">{{ 'cabinet.profile.personalDetails' | translate }}</div>
            <div class="devisionBoxes">
                <mat-form-field appearance="fill">
                    <mat-label>{{ 'cabinet.profile.employment.entryDate' | translate }}</mat-label>
                    <input matInput formControlName="entryDate" type="date" (change)="setValidityDate($event)" />
                </mat-form-field>

                <mat-form-field appearance="fill">
                    <mat-label>{{ 'cabinet.profile.employment.salary' | translate }}</mat-label>
                    <input matInput formControlName="salary" type="text" />
                </mat-form-field>

                <mat-form-field appearance="fill">
                    <mat-label>{{ 'cabinet.profile.employment.degree' | translate }}</mat-label>
                    <input matInput formControlName="employmentDegree" type="number" />
                </mat-form-field>
                <div style="display: none">
                    <mat-form-field appearance="fill">
                        <mat-label>{{ 'cabinet.profile.employment.validityDate' | translate }}</mat-label>
                        <input matInput formControlName="validityDate" type="date" />
                    </mat-form-field>
                </div>
            </div>
            <div class="devisionBoxes">
                <mat-form-field appearance="fill">
                    <mat-label>{{ 'cabinet.profile.employment.premiumWaiver' | translate }}</mat-label>
                    <input matInput formControlName="premiumWaiver" type="number" />
                </mat-form-field>

                <mat-form-field appearance="fill">
                    <mat-label>{{ 'cabinet.profile.employment.grad' | translate }}</mat-label>
                    <input matInput formControlName="iv" type="number" />
                </mat-form-field>
            </div>

            <button class="button" type="submit" class="btnSubmit">
                {{ 'cabinet.profile.buttons.save' | translate }}
            </button>
        </div>
        <div *ngIf="!newPerson"></div>
        <div class="stickyBoxForSaveButton">
            <div class="placeholderForNav"></div>
            <button
                class="button"
                type="button"
                class="btnSubmit"
                [disabled]="formUntouched"
                style="float: left"
                (click)="submitStepOne()"
            >
                {{ 'cabinet.profile.buttons.save' | translate }}
            </button>
            <button class="button" type="button" class="btnSubmit" class="cancle" (click)="abort('cancel')">
                {{ 'cabinet.profile.buttons.cancel' | translate }}
            </button>
        </div>
    </form>
</div>
<div *ngIf="openPopUpConfirmationForNewEmployee" class="popUpConfirmation">
    <!-- Spinner -->
    <div *ngIf="showSpinner" class="spinnerCenterAlignContainer">
        <div class="spinnerContainer">
            <div class="dotContainer"><div class="dot-left"></div></div>
            <div class="dotContainer"><div class="dot-middle"></div></div>
            <div class="dotContainer"><div class="dot-right"></div></div>
        </div>
    </div>
    <div *ngIf="!showSpinner">
        <div class="confirmationContainer">
            <div class="confirmationContainer-svg">
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 20 20" fill="none">
                    <path
                        d="M14.2238 4.89258L3.39844 15.7422L2.5 17.5L4.25742 16.6016L15.1074 5.77617L14.2238 4.89258Z"
                        fill="black"
                        stroke="var(--primary)"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M16.4332 2.6834L15.5496 3.567L16.4332 4.45098L17.3167 3.567C17.4339 3.4498 17.4997 3.29089 17.4997 3.1252C17.4997 2.95951 17.4339 2.8006 17.3167 2.6834C17.1995 2.56628 17.0406 2.50049 16.875 2.50049C16.7093 2.50049 16.5504 2.56628 16.4332 2.6834Z"
                        fill="black"
                        stroke="var(--primary)"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
            </div>
            <div class="confirmationContainer-title">{{ 'cabinet.dashboard.createNewEmployee' | translate }}</div>
            <div class="confirmationContainer-text">{{ 'cabinet.dashboard.createNewEmployeeText' | translate }}</div>
            <div class="confirmationContainer-buttonContainer">
                <button class="button" type="button" class="btnSubmit" style="margin: 0" (click)="submit()">
                    {{ 'cabinet.profile.buttons.confirm' | translate }}
                </button>
                <button
                    class="button"
                    type="button"
                    class="btnSubmit"
                    class="cancle"
                    style="margin: 0"
                    (click)="abort('closeConfirmation')"
                >
                    {{ 'cabinet.profile.buttons.cancel' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>
<div *ngIf="openPopUpConfirmation" class="popUpConfirmation">
    <!-- Spinner -->
    <div *ngIf="showSpinner" class="spinnerCenterAlignContainer">
        <div class="spinnerContainer">
            <div class="dotContainer"><div class="dot-left"></div></div>
            <div class="dotContainer"><div class="dot-middle"></div></div>
            <div class="dotContainer"><div class="dot-right"></div></div>
        </div>
    </div>
    <div *ngIf="!showSpinner">
        <div class="confirmationContainer">
            <div class="confirmationContainer-svg">
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 20 20" fill="none">
                    <path
                        d="M14.2238 4.89258L3.39844 15.7422L2.5 17.5L4.25742 16.6016L15.1074 5.77617L14.2238 4.89258Z"
                        fill="black"
                        stroke="var(--primary)"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M16.4332 2.6834L15.5496 3.567L16.4332 4.45098L17.3167 3.567C17.4339 3.4498 17.4997 3.29089 17.4997 3.1252C17.4997 2.95951 17.4339 2.8006 17.3167 2.6834C17.1995 2.56628 17.0406 2.50049 16.875 2.50049C16.7093 2.50049 16.5504 2.56628 16.4332 2.6834Z"
                        fill="black"
                        stroke="var(--primary)"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
            </div>
            <div class="confirmationContainer-title">{{ 'cabinet.dashboard.changePersonalData' | translate }}</div>
            <div class="confirmationContainer-text">
                {{ 'cabinet.dashboard.changePersonalDataConfirmationText' | translate }}
            </div>
            <div class="confirmationContainer-buttonContainer">
                <button class="button" type="button" class="btnSubmit" style="margin: 0" (click)="submit()">
                    {{ 'cabinet.profile.buttons.confirm' | translate }}
                </button>
                <button
                    class="button"
                    type="button"
                    class="btnSubmit"
                    class="cancle"
                    style="margin: 0"
                    (click)="abort('closeConfirmation')"
                >
                    {{ 'cabinet.profile.buttons.cancel' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>
