import { ValidatorFn } from '@angular/forms';

export type BooleanFn = () => boolean;

/**
 * Adds a validator to a formControl if the predicate is true
 * @param predicate boolean predicate which determines if the validator should be added to the formControl or not
 * @param validator validator which should be added depending the outcome of the predicate
 * @param errorNamespace Optionally also name the error to check for in the form
 */
export function conditionalValidator(
    predicate: BooleanFn,
    validator: ValidatorFn,
    errorNamespace?: string
): ValidatorFn {
    return (formControl) => {
        if (!formControl.parent) {
            return null;
        }
        let error = null;
        if (predicate()) {
            error = validator(formControl);
        }
        if (errorNamespace && error) {
            const customError = {};
            customError[errorNamespace] = error;
            error = customError;
        }

        return error;
    };
}
