<app-environment-banner></app-environment-banner>
<div class="app-main" [class.showNavigation]="isAuthenticated">
    <app-navigation-menu
        *ngIf="isAuthenticated === true"
        [@openClose]="isOpen ? 'open' : 'closed'"
        (navigationToggle)="toggleNavigation($event)"
    ></app-navigation-menu>

    <div class="app-main__content-wrapper">
        <app-header *ngIf="isAuthenticated"></app-header>
        <router-outlet></router-outlet>
    </div>
</div>
