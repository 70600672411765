import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'myfilter',
    pure: false,
})
export class searchTablepipe implements PipeTransform {
    value: string;
    transform(invcoicesList: Array<any>, filter: string): any {
        if (invcoicesList) {
            return invcoicesList.filter(
                (d) =>
                    (d.personId != null && d.personId.indexOf(filter) > -1) ||
                    (d.personalInformation.ssn13 !== null && d.personalInformation.ssn13.indexOf(filter) > -1) ||
                    (d.insurances[0].salaries[0].amount !== null &&
                        d.insurances[0].salaries[0].amount.toString().indexOf(filter) > -1) ||
                    (d.insurances[0].calculationDate.toString() != null &&
                        d.insurances[0].calculationDate.toString().indexOf(filter) > -1) ||
                    (d.insurances[0].companyId != null && d.insurances[0].companyId.indexOf(filter) > -1) ||
                    (d.personalInformation.firstName != null &&
                        d.personalInformation.firstName.toLowerCase().indexOf(filter.toLowerCase()) > -1) ||
                    (d.personalInformation.lastName != null &&
                        d.personalInformation.lastName.toLowerCase().indexOf(filter.toLowerCase()) > -1) ||
                    (d.insurances[0].salaries[0].levelOfDisability != null &&
                        d.insurances[0].salaries[0].levelOfDisability.toString().indexOf(filter) > -1) ||
                    (d.insurances[0].salaries[0].levelOfEmployment != null &&
                        d.insurances[0].salaries[0].levelOfEmployment.toString().indexOf(filter) > -1)
            );
        }
    }
}
