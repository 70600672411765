<div *ngIf="openPopup" class="popUpViewBlocker">
    <div class="popUp">
        <div class="popUpText">{{ 'common.leaveConfirmationQuestion' | translate }}</div>
        <div class="popUpTextSmall">{{ 'common.leaveConfirmationText' | translate }}</div>
        <div class="popUpButtonBox">
            <div class="popUpButtonsNo" (click)="checkBoxAnswer('yes')">{{ 'common.yes' | translate }}</div>
            <div class="popUpButtons" (click)="checkBoxAnswer('no')">{{ 'common.no' | translate }}</div>
        </div>
    </div>
</div>
<div class="employer-panel__panel-header-close" [ngClass]="{ light: (1 | colourTheme) === false }" (click)="abort(0)">
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
            d="M21 12C21 7.03125 16.9688 3 12 3C7.03125 3 3 7.03125 3 12C3 16.9688 7.03125 21 12 21C16.9688 21 21 16.9688 21 12Z"
            stroke="black"
            stroke-miterlimit="10"
        />
        <path d="M15 15L9 9" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M9 15L15 9" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    {{ 'cabinet.navigation.close' | translate }}
</div>
<div class="employer-panel__panel-tabs" [ngClass]="{ light: (1 | colourTheme) === false }">
    <div class="side-Navigation">
        <div class="headerWithPersonalInfo">
            <div class="profilPictureAndName">
                <div class="profilPicture">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        id="Layer_1"
                        data-name="Layer 1"
                        viewBox="0 0 980.85 980.85"
                    >
                        <defs>
                            <style>
                                .cls-1 {
                                    fill: var(--primary);
                                    fill-rule: evenodd;
                                }
                            </style>
                        </defs>
                        <title>Profile Icon Blue</title>
                        <path
                            class="cls-1"
                            d="M500,621.78A178.34,178.34,0,1,1,678.34,443.44,178.33,178.33,0,0,1,500,621.78Zm0,368.65c270.86,0,490.43-219.57,490.43-490.43S770.86,9.57,500,9.57,9.57,229.14,9.57,500,229.14,990.43,500,990.43ZM215.7,841.82c45.67-112.14,155.75-191.2,284.3-191.2s238.64,79,284.3,191.2c98-81.56,160.3-204.4,160.3-341.82C944.6,254.45,745.55,55.4,500,55.4S55.4,254.45,55.4,500C55.4,637.42,117.76,760.27,215.7,841.82Z"
                            transform="translate(-9.57 -9.57)"
                        />
                    </svg>
                </div>
                <div class="titleName">
                    <div class="titleName_title">
                        {{ 'cabinet.profile.employment.employee' | translate }}
                        {{ 'cabinet.tableFields.lastName' | translate }}
                    </div>
                    <div class="titleName_name">
                        {{ person && person.personalInformation ? person.personalInformation.firstName : '' }}
                        {{ person && person.personalInformation ? person.personalInformation.lastName : '' }}
                    </div>
                </div>
            </div>
        </div>
        <div class="navigation">
            <div
                class="navigation-elements"
                [className]="
                    pageOpen === 'personalData' || pageOpen === 'personalDataEdit'
                        ? 'navigationButtonActive'
                        : 'navigation-elements'
                "
                (click)="open('personalData')"
            >
                {{ 'cabinet.dashboard.mutationButtons.addressMutation' | translate }}
            </div>
            <div
                class="navigation-elements"
                [className]="pageOpen === 'employment' ? 'navigationButtonActive' : 'navigation-elements'"
                (click)="open('employment')"
            >
                {{ 'cabinet.profile.employmentInformation' | translate }}
            </div>
            <div
                class="navigation-elements"
                [className]="pageOpen === 'salary' ? 'navigationButtonActive' : 'navigation-elements'"
                (click)="open('salary')"
            >
                {{ 'cabinet.profile.salary' | translate }}
            </div>
            <!--                     <div class="navigation-elements" [className]="pageOpen === 'unpaidHoliday' ? 'navigationButtonActive' : 'navigation-elements'"(click)="open('unpaidHoliday')">{{ 'cabinet.profile.unpaidHoliday' | translate }}</div>
 -->
        </div>
    </div>
    <div class="content">
        <!-- Start Page -->
        <div *ngIf="pageOpen === 'startPage'">
            <div class="headerWithPersonalInfo-tabletView">
                <div class="profilPictureAndName">
                    <div class="profilPicture">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            id="Layer_1"
                            data-name="Layer 1"
                            viewBox="0 0 980.85 980.85"
                        >
                            <defs>
                                <style>
                                    .cls-1 {
                                        fill: var(--primary);
                                        fill-rule: evenodd;
                                    }
                                </style>
                            </defs>
                            <title>Profile Icon Blue</title>
                            <path
                                class="cls-1"
                                d="M500,621.78A178.34,178.34,0,1,1,678.34,443.44,178.33,178.33,0,0,1,500,621.78Zm0,368.65c270.86,0,490.43-219.57,490.43-490.43S770.86,9.57,500,9.57,9.57,229.14,9.57,500,229.14,990.43,500,990.43ZM215.7,841.82c45.67-112.14,155.75-191.2,284.3-191.2s238.64,79,284.3,191.2c98-81.56,160.3-204.4,160.3-341.82C944.6,254.45,745.55,55.4,500,55.4S55.4,254.45,55.4,500C55.4,637.42,117.76,760.27,215.7,841.82Z"
                                transform="translate(-9.57 -9.57)"
                            />
                        </svg>
                    </div>
                    <div class="titleName">
                        <div class="titleName_title">
                            {{ 'cabinet.profile.employment.employee' | translate }}
                            {{ 'cabinet.tableFields.lastName' | translate }}
                        </div>
                        <div class="titleName_name">
                            {{ person && person.personalInformation ? person.personalInformation.firstName : '' }}
                            {{ person && person.personalInformation ? person.personalInformation.lastName : '' }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="navigation-tabletView">
                <div
                    class="navigation-elements"
                    [className]="
                        pageOpen === 'personalData' || pageOpen === 'personalDataEdit'
                            ? 'navigationButtonActive'
                            : 'navigation-elements'
                    "
                    (click)="open('personalData')"
                >
                    {{ 'cabinet.dashboard.mutationButtons.addressMutation' | translate }}
                </div>
                <div
                    class="navigation-elements"
                    [className]="pageOpen === 'employment' ? 'navigationButtonActive' : 'navigation-elements'"
                    (click)="open('employment')"
                >
                    {{ 'cabinet.profile.employmentInformation' | translate }}
                </div>
                <div
                    class="navigation-elements"
                    [className]="pageOpen === 'salary' ? 'navigationButtonActive' : 'navigation-elements'"
                    (click)="open('salary')"
                >
                    {{ 'cabinet.profile.salary' | translate }}
                </div>
                <!--                         <div class="navigation-elements" [className]="pageOpen === 'unpaidHoliday' ? 'navigationButtonActive' : 'navigation-elements'"(click)="open('unpaidHoliday')">{{ 'cabinet.profile.unpaidHoliday' | translate }}</div>
 -->
            </div>
            <div class="content-title">{{ 'cabinet.insurances.welcomeText' | translate }}</div>
            <div class="content-subTitle">{{ 'cabinet.insurances.subText' | translate }}</div>
            <div *ngIf="!edit" class="tasks">
                <div class="tasks-items" (click)="open('salary')">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path
                            d="M2.25 15.75L21.75 15.75C22.1642 15.75 22.5 15.4142 22.5 15V4.5C22.5 4.08579 22.1642 3.75 21.75 3.75L2.25 3.75C1.83579 3.75 1.5 4.08579 1.5 4.5V15C1.5 15.4142 1.83579 15.75 2.25 15.75Z"
                            stroke="var(--primary)"
                            stroke-linejoin="round"
                        />
                        <path d="M3 18H21" stroke="var(--primary)" stroke-linecap="round" stroke-linejoin="round" />
                        <path
                            d="M4.5 20.25H19.5"
                            stroke="var(--primary)"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M12 13.5C14.0711 13.5 15.75 11.8211 15.75 9.75C15.75 7.67893 14.0711 6 12 6C9.92893 6 8.25 7.67893 8.25 9.75C8.25 11.8211 9.92893 13.5 12 13.5Z"
                            stroke="var(--primary)"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M22.5 7.5C21.5054 7.5 20.5516 7.10491 19.8483 6.40165C19.1451 5.69839 18.75 4.74456 18.75 3.75"
                            stroke="var(--primary)"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M1.5 7.5C1.99246 7.5 2.48009 7.403 2.93506 7.21455C3.39003 7.02609 3.80343 6.74987 4.15165 6.40165C4.49987 6.05343 4.77609 5.64003 4.96455 5.18506C5.153 4.73009 5.25 4.24246 5.25 3.75"
                            stroke="var(--primary)"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M22.5 12C21.5054 12 20.5516 12.3951 19.8483 13.0983C19.1451 13.8016 18.75 14.7554 18.75 15.75"
                            stroke="var(--primary)"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M1.5 12C1.99246 12 2.48009 12.097 2.93506 12.2855C3.39003 12.4739 3.80343 12.7501 4.15165 13.0983C4.49987 13.4466 4.77609 13.86 4.96455 14.3149C5.153 14.7699 5.25 15.2575 5.25 15.75"
                            stroke="var(--primary)"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                    {{ 'cabinet.dashboard.changeSalary' | translate }}
                </div>
                <div class="tasks-items" (click)="open('employment')">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path
                            d="M17.25 2.25H6.75C5.50736 2.25 4.5 3.25736 4.5 4.5V19.5C4.5 20.7426 5.50736 21.75 6.75 21.75H17.25C18.4926 21.75 19.5 20.7426 19.5 19.5V4.5C19.5 3.25736 18.4926 2.25 17.25 2.25Z"
                            stroke="var(--primary)"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M8.25 6H15.75"
                            stroke="var(--primary)"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M8.25 9.75H15.75"
                            stroke="var(--primary)"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M8.25 13.5H12"
                            stroke="var(--primary)"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                    {{ 'cabinet.dashboard.terminateUser' | translate }}
                </div>
                <div class="tasks-items" (click)="open('personalData')">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path
                            d="M16.125 6.75C15.9413 9.22828 14.0625 11.25 12 11.25C9.93754 11.25 8.05551 9.22875 7.87504 6.75C7.68754 4.17188 9.51566 2.25 12 2.25C14.4844 2.25 16.3125 4.21875 16.125 6.75Z"
                            stroke="var(--primary)"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M12 14.25C7.92187 14.25 3.7828 16.5 3.01687 20.7469C2.92452 21.2588 3.21421 21.75 3.74999 21.75H20.25C20.7862 21.75 21.0759 21.2588 20.9836 20.7469C20.2172 16.5 16.0781 14.25 12 14.25Z"
                            stroke="var(--primary)"
                            stroke-miterlimit="10"
                        />
                    </svg>
                    {{ 'cabinet.dashboard.changePersonalData' | translate }}
                </div>
                <!-- <div class="tasks-items" (click)="open('unpaidHoliday')">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M19.5 3.75H4.5C3.25736 3.75 2.25 4.75736 2.25 6V19.5C2.25 20.7426 3.25736 21.75 4.5 21.75H19.5C20.7426 21.75 21.75 20.7426 21.75 19.5V6C21.75 4.75736 20.7426 3.75 19.5 3.75Z" stroke="var(--primary)" stroke-linejoin="round"/>
                                <path d="M5.35219 4.25H18.6478C20.0749 4.25 21.25 5.43217 21.25 6.9V8.84401C21.1493 8.66603 21.0239 8.5043 20.8848 8.3652C20.5334 8.01377 20.0376 7.75 19.5 7.75H4.5C3.96238 7.75 3.46662 8.01377 3.1152 8.3652C2.97609 8.5043 2.85072 8.66603 2.75 8.84401V6.9C2.75 5.43217 3.92505 4.25 5.35219 4.25Z" fill="var(--primary)" stroke="var(--primary)"/>
                                <path d="M14.5 10.875C14.5 11.2202 14.2202 11.5 13.875 11.5C13.5298 11.5 13.25 11.2202 13.25 10.875C13.25 10.5298 13.5298 10.25 13.875 10.25C14.2202 10.25 14.5 10.5298 14.5 10.875Z" fill="var(--primary)" stroke="var(--primary)"/>
                                <path d="M18.25 10.875C18.25 11.2202 17.9702 11.5 17.625 11.5C17.2798 11.5 17 11.2202 17 10.875C17 10.5298 17.2798 10.25 17.625 10.25C17.9702 10.25 18.25 10.5298 18.25 10.875Z" fill="var(--primary)" stroke="var(--primary)"/>
                                <path d="M14.5 14.625C14.5 14.9702 14.2202 15.25 13.875 15.25C13.5298 15.25 13.25 14.9702 13.25 14.625C13.25 14.2798 13.5298 14 13.875 14C14.2202 14 14.5 14.2798 14.5 14.625Z" fill="var(--primary)" stroke="var(--primary)"/>
                                <path d="M18.25 14.625C18.25 14.9702 17.9702 15.25 17.625 15.25C17.2798 15.25 17 14.9702 17 14.625C17 14.2798 17.2798 14 17.625 14C17.9702 14 18.25 14.2798 18.25 14.625Z" fill="var(--primary)" stroke="var(--primary)"/>
                                <path d="M7 14.625C7 14.9702 6.72018 15.25 6.375 15.25C6.02982 15.25 5.75 14.9702 5.75 14.625C5.75 14.2798 6.02982 14 6.375 14C6.72018 14 7 14.2798 7 14.625Z" fill="var(--primary)" stroke="var(--primary)"/>
                                <path d="M10.75 14.625C10.75 14.9702 10.4702 15.25 10.125 15.25C9.77982 15.25 9.5 14.9702 9.5 14.625C9.5 14.2798 9.77982 14 10.125 14C10.4702 14 10.75 14.2798 10.75 14.625Z" fill="var(--primary)" stroke="var(--primary)"/>
                                <path d="M7 18.375C7 18.7202 6.72018 19 6.375 19C6.02982 19 5.75 18.7202 5.75 18.375C5.75 18.0298 6.02982 17.75 6.375 17.75C6.72018 17.75 7 18.0298 7 18.375Z" fill="var(--primary)" stroke="var(--primary)"/>
                                <path d="M10.75 18.375C10.75 18.7202 10.4702 19 10.125 19C9.77982 19 9.5 18.7202 9.5 18.375C9.5 18.0298 9.77982 17.75 10.125 17.75C10.4702 17.75 10.75 18.0298 10.75 18.375Z" fill="var(--primary)" stroke="var(--primary)"/>
                                <path d="M14.5 18.375C14.5 18.7202 14.2202 19 13.875 19C13.5298 19 13.25 18.7202 13.25 18.375C13.25 18.0298 13.5298 17.75 13.875 17.75C14.2202 17.75 14.5 18.0298 14.5 18.375Z" fill="var(--primary)" stroke="var(--primary)"/>
                                <path d="M6 2.25V3.75" stroke="var(--primary)" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M18 2.25V3.75" stroke="var(--primary)" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            {{ 'cabinet.dashboard.setUnpaidHolidayPeriod' | translate }}
                        </div> -->
            </div>
            <div *ngIf="edit" class="tasks">
                <div class="tasks-items" (click)="open('salary')">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path
                            d="M2.25 15.75L21.75 15.75C22.1642 15.75 22.5 15.4142 22.5 15V4.5C22.5 4.08579 22.1642 3.75 21.75 3.75L2.25 3.75C1.83579 3.75 1.5 4.08579 1.5 4.5V15C1.5 15.4142 1.83579 15.75 2.25 15.75Z"
                            stroke="var(--primary)"
                            stroke-linejoin="round"
                        />
                        <path d="M3 18H21" stroke="var(--primary)" stroke-linecap="round" stroke-linejoin="round" />
                        <path
                            d="M4.5 20.25H19.5"
                            stroke="var(--primary)"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M12 13.5C14.0711 13.5 15.75 11.8211 15.75 9.75C15.75 7.67893 14.0711 6 12 6C9.92893 6 8.25 7.67893 8.25 9.75C8.25 11.8211 9.92893 13.5 12 13.5Z"
                            stroke="var(--primary)"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M22.5 7.5C21.5054 7.5 20.5516 7.10491 19.8483 6.40165C19.1451 5.69839 18.75 4.74456 18.75 3.75"
                            stroke="var(--primary)"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M1.5 7.5C1.99246 7.5 2.48009 7.403 2.93506 7.21455C3.39003 7.02609 3.80343 6.74987 4.15165 6.40165C4.49987 6.05343 4.77609 5.64003 4.96455 5.18506C5.153 4.73009 5.25 4.24246 5.25 3.75"
                            stroke="var(--primary)"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M22.5 12C21.5054 12 20.5516 12.3951 19.8483 13.0983C19.1451 13.8016 18.75 14.7554 18.75 15.75"
                            stroke="var(--primary)"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M1.5 12C1.99246 12 2.48009 12.097 2.93506 12.2855C3.39003 12.4739 3.80343 12.7501 4.15165 13.0983C4.49987 13.4466 4.77609 13.86 4.96455 14.3149C5.153 14.7699 5.25 15.2575 5.25 15.75"
                            stroke="var(--primary)"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                    {{ 'cabinet.dashboard.changeSalaryEdit' | translate }}
                </div>
                <div class="tasks-items" (click)="open('employment')">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path
                            d="M17.25 2.25H6.75C5.50736 2.25 4.5 3.25736 4.5 4.5V19.5C4.5 20.7426 5.50736 21.75 6.75 21.75H17.25C18.4926 21.75 19.5 20.7426 19.5 19.5V4.5C19.5 3.25736 18.4926 2.25 17.25 2.25Z"
                            stroke="var(--primary)"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M8.25 6H15.75"
                            stroke="var(--primary)"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M8.25 9.75H15.75"
                            stroke="var(--primary)"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M8.25 13.5H12"
                            stroke="var(--primary)"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                    {{ 'cabinet.dashboard.terminateUserEdit' | translate }}
                </div>
                <div class="tasks-items" (click)="open('personalData')">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path
                            d="M16.125 6.75C15.9413 9.22828 14.0625 11.25 12 11.25C9.93754 11.25 8.05551 9.22875 7.87504 6.75C7.68754 4.17188 9.51566 2.25 12 2.25C14.4844 2.25 16.3125 4.21875 16.125 6.75Z"
                            stroke="var(--primary)"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M12 14.25C7.92187 14.25 3.7828 16.5 3.01687 20.7469C2.92452 21.2588 3.21421 21.75 3.74999 21.75H20.25C20.7862 21.75 21.0759 21.2588 20.9836 20.7469C20.2172 16.5 16.0781 14.25 12 14.25Z"
                            stroke="var(--primary)"
                            stroke-miterlimit="10"
                        />
                    </svg>
                    {{ 'cabinet.dashboard.changePersonalDataEdit' | translate }}
                </div>
                <!-- <div class="tasks-items" (click)="open('unpaidHoliday')">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M19.5 3.75H4.5C3.25736 3.75 2.25 4.75736 2.25 6V19.5C2.25 20.7426 3.25736 21.75 4.5 21.75H19.5C20.7426 21.75 21.75 20.7426 21.75 19.5V6C21.75 4.75736 20.7426 3.75 19.5 3.75Z" stroke="var(--primary)" stroke-linejoin="round"/>
                                <path d="M5.35219 4.25H18.6478C20.0749 4.25 21.25 5.43217 21.25 6.9V8.84401C21.1493 8.66603 21.0239 8.5043 20.8848 8.3652C20.5334 8.01377 20.0376 7.75 19.5 7.75H4.5C3.96238 7.75 3.46662 8.01377 3.1152 8.3652C2.97609 8.5043 2.85072 8.66603 2.75 8.84401V6.9C2.75 5.43217 3.92505 4.25 5.35219 4.25Z" fill="var(--primary)" stroke="var(--primary)"/>
                                <path d="M14.5 10.875C14.5 11.2202 14.2202 11.5 13.875 11.5C13.5298 11.5 13.25 11.2202 13.25 10.875C13.25 10.5298 13.5298 10.25 13.875 10.25C14.2202 10.25 14.5 10.5298 14.5 10.875Z" fill="var(--primary)" stroke="var(--primary)"/>
                                <path d="M18.25 10.875C18.25 11.2202 17.9702 11.5 17.625 11.5C17.2798 11.5 17 11.2202 17 10.875C17 10.5298 17.2798 10.25 17.625 10.25C17.9702 10.25 18.25 10.5298 18.25 10.875Z" fill="var(--primary)" stroke="var(--primary)"/>
                                <path d="M14.5 14.625C14.5 14.9702 14.2202 15.25 13.875 15.25C13.5298 15.25 13.25 14.9702 13.25 14.625C13.25 14.2798 13.5298 14 13.875 14C14.2202 14 14.5 14.2798 14.5 14.625Z" fill="var(--primary)" stroke="var(--primary)"/>
                                <path d="M18.25 14.625C18.25 14.9702 17.9702 15.25 17.625 15.25C17.2798 15.25 17 14.9702 17 14.625C17 14.2798 17.2798 14 17.625 14C17.9702 14 18.25 14.2798 18.25 14.625Z" fill="var(--primary)" stroke="var(--primary)"/>
                                <path d="M7 14.625C7 14.9702 6.72018 15.25 6.375 15.25C6.02982 15.25 5.75 14.9702 5.75 14.625C5.75 14.2798 6.02982 14 6.375 14C6.72018 14 7 14.2798 7 14.625Z" fill="var(--primary)" stroke="var(--primary)"/>
                                <path d="M10.75 14.625C10.75 14.9702 10.4702 15.25 10.125 15.25C9.77982 15.25 9.5 14.9702 9.5 14.625C9.5 14.2798 9.77982 14 10.125 14C10.4702 14 10.75 14.2798 10.75 14.625Z" fill="var(--primary)" stroke="var(--primary)"/>
                                <path d="M7 18.375C7 18.7202 6.72018 19 6.375 19C6.02982 19 5.75 18.7202 5.75 18.375C5.75 18.0298 6.02982 17.75 6.375 17.75C6.72018 17.75 7 18.0298 7 18.375Z" fill="var(--primary)" stroke="var(--primary)"/>
                                <path d="M10.75 18.375C10.75 18.7202 10.4702 19 10.125 19C9.77982 19 9.5 18.7202 9.5 18.375C9.5 18.0298 9.77982 17.75 10.125 17.75C10.4702 17.75 10.75 18.0298 10.75 18.375Z" fill="var(--primary)" stroke="var(--primary)"/>
                                <path d="M14.5 18.375C14.5 18.7202 14.2202 19 13.875 19C13.5298 19 13.25 18.7202 13.25 18.375C13.25 18.0298 13.5298 17.75 13.875 17.75C14.2202 17.75 14.5 18.0298 14.5 18.375Z" fill="var(--primary)" stroke="var(--primary)"/>
                                <path d="M6 2.25V3.75" stroke="var(--primary)" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M18 2.25V3.75" stroke="var(--primary)" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            {{ 'cabinet.dashboard.setUnpaidHolidayPeriodEdit' | translate }}
                        </div> -->
            </div>
        </div>
        <!-- PersonalData -->
        <div *ngIf="pageOpen === 'personalData'">
            <div class="employer-panel__panel-tabs-pane" [ngClass]="{ light: (1 | colourTheme) === false }">
                <div class="navigation-tabletView">
                    <div
                        class="navigation-elements"
                        [className]="
                            pageOpen === 'personalData' || pageOpen === 'personalDataEdit'
                                ? 'navigationButtonActive'
                                : 'navigation-elements'
                        "
                        (click)="open('personalData')"
                    >
                        {{ 'cabinet.dashboard.mutationButtons.addressMutation' | translate }}
                    </div>
                    <div
                        class="navigation-elements"
                        [className]="pageOpen === 'employment' ? 'navigationButtonActive' : 'navigation-elements'"
                        (click)="open('employment')"
                    >
                        {{ 'cabinet.profile.employmentInformation' | translate }}
                    </div>
                    <div
                        class="navigation-elements"
                        [className]="pageOpen === 'salary' ? 'navigationButtonActive' : 'navigation-elements'"
                        (click)="open('salary')"
                    >
                        {{ 'cabinet.profile.salary' | translate }}
                    </div>
                    <!--                             <div class="navigation-elements" [className]="pageOpen === 'unpaidHoliday' ? 'navigationButtonActive' : 'navigation-elements'"(click)="open('unpaidHoliday')">{{ 'cabinet.profile.unpaidHoliday' | translate }}</div>
 -->
                </div>
                <!-- Row Personal-->
                <div class="titleRow">
                    <div class="titleRow-title">{{ 'cabinet.profile.personalDetails' | translate }}</div>
                    <!-- call (click)="callEditPerson()" original
 -->
                    <div
                        class="family-info-main__block-header-button"
                        class="button"
                        [ngClass]="{ light: (1 | colourTheme) === false }"
                        style="color: gray"
                    >
                        {{ 'cabinet.profile.buttons.edit' | translate }}
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="gray">
                            <path
                                d="M14.2238 4.89258L3.39844 15.7422L2.5 17.5L4.25742 16.6016L15.1074 5.77617L14.2238 4.89258Z"
                                fill="black"
                                stroke="#gray"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M16.4332 2.6834L15.5496 3.567L16.4332 4.45098L17.3167 3.567C17.4339 3.4498 17.4997 3.29089 17.4997 3.1252C17.4997 2.95951 17.4339 2.8006 17.3167 2.6834C17.1995 2.56628 17.0406 2.50049 16.875 2.50049C16.7093 2.50049 16.5504 2.56628 16.4332 2.6834Z"
                                fill="black"
                                stroke="#gray"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    </div>
                </div>
                <div class="personal-info-main__block-row">
                    <!-- FirstName -->
                    <div
                        class="personal-info-main__block-row-element"
                        [ngClass]="{ light: (1 | colourTheme) === false }"
                    >
                        <div
                            class="personal-info-main__block-row-element_title"
                            [ngClass]="{ light: (1 | colourTheme) === false }"
                        >
                            {{ 'cabinet.profile.personal.firstName' | translate }}
                        </div>
                        <div
                            class="personal-info-main__block-row-element_text"
                            [ngClass]="{ light: (1 | colourTheme) === false }"
                        >
                            {{ person && person.personalInformation ? person.personalInformation.firstName : '' }}
                        </div>
                    </div>
                    <!-- LastName -->
                    <div
                        class="personal-info-main__block-row-element"
                        [ngClass]="{ light: (1 | colourTheme) === false }"
                    >
                        <div
                            class="personal-info-main__block-row-element_title"
                            [ngClass]="{ light: (1 | colourTheme) === false }"
                        >
                            {{ 'cabinet.profile.personal.lastName' | translate }}
                        </div>
                        <div
                            class="personal-info-main__block-row-element_text"
                            [ngClass]="{ light: (1 | colourTheme) === false }"
                        >
                            {{ person && person.personalInformation ? person.personalInformation.lastName : '' }}
                        </div>
                    </div>
                    <!-- SSN -->
                    <div
                        class="personal-info-main__block-row-element"
                        [ngClass]="{ light: (1 | colourTheme) === false }"
                    >
                        <div
                            class="personal-info-main__block-row-element_title"
                            [ngClass]="{ light: (1 | colourTheme) === false }"
                        >
                            {{ 'cabinet.profile.personal.sov' | translate }}
                        </div>
                        <div
                            class="personal-info-main__block-row-element_text"
                            [ngClass]="{ light: (1 | colourTheme) === false }"
                        >
                            {{ person && person.personalInformation ? person.personalInformation.ssn13 : '' }}
                        </div>
                    </div>
                    <!-- Date of Birth -->
                    <div
                        class="personal-info-main__block-row-element"
                        [ngClass]="{ light: (1 | colourTheme) === false }"
                    >
                        <div
                            class="personal-info-main__block-row-element_title"
                            [ngClass]="{ light: (1 | colourTheme) === false }"
                        >
                            {{ 'cabinet.profile.personal.dateOfBirth' | translate }}
                        </div>
                        <div
                            class="personal-info-main__block-row-element_text"
                            [ngClass]="{ light: (1 | colourTheme) === false }"
                        >
                            {{
                                (person && person.personalInformation.dateOfBirth
                                    ? convertToDate(person.personalInformation.dateOfBirth)
                                    : ''
                                ) | date : 'dd.MM.yyyy'
                            }}
                        </div>
                    </div>
                    <!-- Gender -->
                    <div
                        class="personal-info-main__block-row-element"
                        [ngClass]="{ light: (1 | colourTheme) === false }"
                    >
                        <div
                            class="personal-info-main__block-row-element_title"
                            [ngClass]="{ light: (1 | colourTheme) === false }"
                        >
                            {{ 'cabinet.profile.personal.gender' | translate }}
                        </div>
                        <div
                            class="personal-info-main__block-row-element_text"
                            [ngClass]="{ light: (1 | colourTheme) === false }"
                        >
                            <!--                    {{ person && person.personalInformation ? person.personalInformation.sex : '' }}-->
                            <!--                    TODO: needs check if person is set??-->
                            {{ 'cabinet.profile.personal.genderCode.' + person.personalInformation.sex | translate }}
                        </div>
                    </div>
                    <!-- martial status -->
                    <div
                        class="personal-info-main__block-row-element"
                        [ngClass]="{ light: (1 | colourTheme) === false }"
                    >
                        <div
                            class="personal-info-main__block-row-element_title"
                            [ngClass]="{ light: (1 | colourTheme) === false }"
                        >
                            {{ 'cabinet.profile.personal.maritalStatus' | translate }}
                        </div>
                        <div
                            class="personal-info-main__block-row-element_text"
                            [ngClass]="{ light: (1 | colourTheme) === false }"
                        >
                            <!--                    TODO: needs check if person is set??-->
                            {{
                                'cabinet.profile.personal.maritalStatusCode.' +
                                    person.personalInformation.maritalStatus.code | translate
                            }}
                        </div>
                    </div>
                    <!-- Language -->
                    <div
                        class="personal-info-main__block-row-element"
                        [ngClass]="{ light: (1 | colourTheme) === false }"
                    >
                        <div
                            class="personal-info-main__block-row-element_title"
                            [ngClass]="{ light: (1 | colourTheme) === false }"
                        >
                            {{ 'cabinet.profile.personal.language' | translate }}
                        </div>
                        <div
                            class="personal-info-main__block-row-element_text"
                            [ngClass]="{ light: (1 | colourTheme) === false }"
                        >
                            {{
                                (person && person.personalInformation ? person.personalInformation.language : '')
                                    | uppercase
                            }}
                        </div>
                    </div>
                </div>
                <!-- <div class="personal-info-main__block-row-element" [ngClass]="{ light: (1 | colourTheme) === false }">
                                <div class="personal-info-main__block-row-element_title" [ngClass]="{ light: (1 | colourTheme) === false }">
                                    {{ 'cabinet.profile.personal.mandant' | translate }}
                                </div>
                                <div class="personal-info-main__block-row-element_text" [ngClass]="{ light: (1 | colourTheme) === false }">
                                    {{ person && person.insurances ? person.insurances[0].companyId : '' }}
                                </div>
                            </div> -->

                <!-- Row Contact details -->
                <div class="titleRow">
                    <div class="titleRow-title">{{ 'cabinet.profile.contactDetails' | translate }}</div>
                    <div
                        *ngIf="!edit"
                        class="family-info-main__block-header-button"
                        class="button"
                        (click)="open('personalDataEdit')"
                    >
                        {{ 'cabinet.profile.buttons.edit' | translate }}
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path
                                d="M14.2238 4.89258L3.39844 15.7422L2.5 17.5L4.25742 16.6016L15.1074 5.77617L14.2238 4.89258Z"
                                fill="black"
                                stroke="var(--primary)"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M16.4332 2.6834L15.5496 3.567L16.4332 4.45098L17.3167 3.567C17.4339 3.4498 17.4997 3.29089 17.4997 3.1252C17.4997 2.95951 17.4339 2.8006 17.3167 2.6834C17.1995 2.56628 17.0406 2.50049 16.875 2.50049C16.7093 2.50049 16.5504 2.56628 16.4332 2.6834Z"
                                fill="black"
                                stroke="var(--primary)"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    </div>
                    <div *ngIf="edit" class="family-info-main__block-header-button" class="button" class="gray">
                        {{ 'cabinet.profile.buttons.edit' | translate }}
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path
                                d="M14.2238 4.89258L3.39844 15.7422L2.5 17.5L4.25742 16.6016L15.1074 5.77617L14.2238 4.89258Z"
                                fill="black"
                                stroke="gray"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M16.4332 2.6834L15.5496 3.567L16.4332 4.45098L17.3167 3.567C17.4339 3.4498 17.4997 3.29089 17.4997 3.1252C17.4997 2.95951 17.4339 2.8006 17.3167 2.6834C17.1995 2.56628 17.0406 2.50049 16.875 2.50049C16.7093 2.50049 16.5504 2.56628 16.4332 2.6834Z"
                                fill="black"
                                stroke="#gray"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    </div>
                </div>
                <div class="personal-info-main__block-row" [ngClass]="{ light: (1 | colourTheme) === false }">
                    <!-- Street -->
                    <div
                        class="personal-info-main__block-row-element"
                        [ngClass]="{ light: (1 | colourTheme) === false }"
                    >
                        <div
                            class="personal-info-main__block-row-element_title"
                            [ngClass]="{ light: (1 | colourTheme) === false }"
                        >
                            {{ 'cabinet.profile.personal.street' | translate }}
                        </div>
                        <div
                            class="personal-info-main__block-row-element_text"
                            [ngClass]="{ light: (1 | colourTheme) === false }"
                        >
                            {{
                                person && person.personalInformation && person.personalInformation.addresses
                                    ? person.personalInformation.addresses[0].street
                                    : ''
                            }}
                        </div>
                    </div>
                    <!-- Po Box -->
                    <div
                        class="personal-info-main__block-row-element"
                        [ngClass]="{ light: (1 | colourTheme) === false }"
                    >
                        <div
                            class="personal-info-main__block-row-element_title"
                            [ngClass]="{ light: (1 | colourTheme) === false }"
                        >
                            {{ 'cabinet.profile.personal.poBox' | translate }}
                        </div>
                        <div
                            class="personal-info-main__block-row-element_text"
                            [ngClass]="{ light: (1 | colourTheme) === false }"
                        >
                            {{
                                person && person.personalInformation && person.personalInformation.addresses
                                    ? person.personalInformation.addresses[0].poBox
                                    : ''
                            }}
                        </div>
                    </div>
                    <!-- Postal code -->
                    <div
                        class="personal-info-main__block-row-element"
                        [ngClass]="{ light: (1 | colourTheme) === false }"
                    >
                        <div
                            class="personal-info-main__block-row-element_title"
                            [ngClass]="{ light: (1 | colourTheme) === false }"
                        >
                            {{ 'cabinet.profile.personal.postalCode' | translate }}
                        </div>
                        <div
                            class="personal-info-main__block-row-element_text"
                            [ngClass]="{ light: (1 | colourTheme) === false }"
                        >
                            {{
                                person && person.personalInformation && person.personalInformation.addresses
                                    ? person.personalInformation.addresses[0].postalCode
                                    : ''
                            }}
                        </div>
                    </div>
                    <!-- City -->
                    <div
                        class="personal-info-main__block-row-element"
                        [ngClass]="{ light: (1 | colourTheme) === false }"
                    >
                        <div
                            class="personal-info-main__block-row-element_title"
                            [ngClass]="{ light: (1 | colourTheme) === false }"
                        >
                            {{ 'cabinet.profile.personal.city' | translate }}
                        </div>
                        <div
                            class="personal-info-main__block-row-element_text"
                            [ngClass]="{ light: (1 | colourTheme) === false }"
                        >
                            {{
                                person && person.personalInformation && person.personalInformation.addresses
                                    ? person.personalInformation.addresses[0].city
                                    : ''
                            }}
                        </div>
                    </div>
                    <!-- Country -->
                    <div
                        class="personal-info-main__block-row-element"
                        [ngClass]="{ light: (1 | colourTheme) === false }"
                    >
                        <div
                            class="personal-info-main__block-row-element_title"
                            [ngClass]="{ light: (1 | colourTheme) === false }"
                        >
                            {{ 'cabinet.profile.personal.country' | translate }}
                        </div>
                        <div
                            class="personal-info-main__block-row-element_text"
                            [ngClass]="{ light: (1 | colourTheme) === false }"
                        >
                            {{
                                person && person.personalInformation && person.personalInformation.addresses
                                    ? person.personalInformation.addresses[0].country
                                    : ''
                            }}
                        </div>
                    </div>
                    <!-- MobilePhone -->
                    <div
                        class="personal-info-main__block-row-element"
                        [ngClass]="{ light: (1 | colourTheme) === false }"
                    >
                        <div
                            class="personal-info-main__block-row-element_title"
                            [ngClass]="{ light: (1 | colourTheme) === false }"
                        >
                            {{ 'cabinet.profile.personal.phone' | translate }}
                        </div>
                        <div
                            class="personal-info-main__block-row-element_text"
                            [ngClass]="{ light: (1 | colourTheme) === false }"
                        >
                            {{
                                person && person.personalInformation && person.personalInformation.addresses
                                    ? person.personalInformation.addresses[0].mobile
                                    : ''
                            }}
                        </div>
                    </div>
                    <!-- email -->
                    <div
                        class="personal-info-main__block-row-element"
                        [ngClass]="{ light: (1 | colourTheme) === false }"
                    >
                        <div
                            class="personal-info-main__block-row-element_title"
                            [ngClass]="{ light: (1 | colourTheme) === false }"
                        >
                            {{ 'cabinet.profile.personal.email' | translate }}
                        </div>
                        <div
                            class="personal-info-main__block-row-element_text"
                            [ngClass]="{ light: (1 | colourTheme) === false }"
                        >
                            {{
                                person && person.personalInformation && person.personalInformation.addresses
                                    ? person.personalInformation.addresses[0].email
                                    : ''
                            }}
                        </div>
                    </div>
                    <!-- <div class="personal-info-main__block-row-element" [ngClass]="{ light: (1 | colourTheme) === false }">
                                <div class="personal-info-main__block-row-element_title" [ngClass]="{ light: (1 | colourTheme) === false }">
                                    {{ 'cabinet.profile.personal.personalId' | translate }}
                                </div>
                                <div class="personal-info-main__block-row-element_text" [ngClass]="{ light: (1 | colourTheme) === false }">
                                    {{ person ? person.personId : '' }}
                                </div>
                            </div> -->
                    <!-- <div class="personal-info-main__block-row-element" [ngClass]="{ light: (1 | colourTheme) === false }">
                                    <div class="personal-info-main__block-row-element_title" [ngClass]="{ light: (1 | colourTheme) === false }">
                                        {{ 'cabinet.profile.personal.telephone' | translate }}
                                    </div>
                                    <div class="personal-info-main__block-row-element_text" [ngClass]="{ light: (1 | colourTheme) === false }">
                                        {{
                                        person && person.personalInformation && person.personalInformation.addresses
                                            ? person.personalInformation.addresses[0].phone
                                            : ''
                                        }}
                                    </div>
                                </div> -->
                </div>
                <!-- Row Family -->
                <div class="titleRow">
                    <div class="titleRow-title">{{ 'cabinet.profile.familyInformation' | translate }}</div>
                    <div
                        class="family-info-main__block-header-button"
                        class="button"
                        [ngClass]="{ light: (1 | colourTheme) === false }"
                        style="color: gray"
                    >
                        {{ 'cabinet.profile.buttons.edit' | translate }}
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path
                                d="M14.2238 4.89258L3.39844 15.7422L2.5 17.5L4.25742 16.6016L15.1074 5.77617L14.2238 4.89258Z"
                                fill="black"
                                stroke="gray"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M16.4332 2.6834L15.5496 3.567L16.4332 4.45098L17.3167 3.567C17.4339 3.4498 17.4997 3.29089 17.4997 3.1252C17.4997 2.95951 17.4339 2.8006 17.3167 2.6834C17.1995 2.56628 17.0406 2.50049 16.875 2.50049C16.7093 2.50049 16.5504 2.56628 16.4332 2.6834Z"
                                fill="black"
                                stroke="gray"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    </div>
                </div>

                <div class="personal-info-main__block-row" [ngClass]="{ light: (1 | colourTheme) === false }">
                    <!-- FirstName -->
                    <div
                        class="personal-info-main__block-row-element"
                        [ngClass]="{ light: (1 | colourTheme) === false }"
                    >
                        <div
                            class="personal-info-main__block-row-element_title"
                            [ngClass]="{ light: (1 | colourTheme) === false }"
                        >
                            {{ 'cabinet.profile.family.name' | translate }}
                        </div>
                        <div
                            class="personal-info-main__block-row-element_text"
                            [ngClass]="{ light: (1 | colourTheme) === false }"
                        >
                            {{
                                person && person.personalInformation.partner
                                    ? person.personalInformation.partner[0].firstName
                                    : ''
                            }}
                        </div>
                    </div>
                    <!-- LasttName -->
                    <div
                        class="personal-info-main__block-row-element"
                        [ngClass]="{ light: (1 | colourTheme) === false }"
                    >
                        <div
                            class="personal-info-main__block-row-element_title"
                            [ngClass]="{ light: (1 | colourTheme) === false }"
                        >
                            {{ 'cabinet.profile.family.lastName' | translate }}
                        </div>
                        <div
                            class="personal-info-main__block-row-element_text"
                            [ngClass]="{ light: (1 | colourTheme) === false }"
                        >
                            {{
                                person && person.personalInformation.partner
                                    ? person.personalInformation.partner[0].lastName
                                    : ''
                            }}
                        </div>
                    </div>
                    <!-- Date of Birth -->
                    <div
                        class="personal-info-main__block-row-element"
                        [ngClass]="{ light: (1 | colourTheme) === false }"
                    >
                        <div
                            class="personal-info-main__block-row-element_title"
                            [ngClass]="{ light: (1 | colourTheme) === false }"
                        >
                            {{ 'cabinet.profile.personal.dateOfBirth' | translate }}
                        </div>
                        <div
                            class="personal-info-main__block-row-element_text"
                            [ngClass]="{ light: (1 | colourTheme) === false }"
                        >
                            {{
                                person && person.personalInformation.partner
                                    ? (convertToDate(person.personalInformation.partner[0].dateOfBirth)
                                      | date : 'dd.MM.yyyy')
                                    : ''
                            }}
                        </div>
                    </div>
                    <!-- Gender -->
                    <div
                        class="personal-info-main__block-row-element"
                        [ngClass]="{ light: (1 | colourTheme) === false }"
                    >
                        <div
                            class="personal-info-main__block-row-element_title"
                            [ngClass]="{ light: (1 | colourTheme) === false }"
                        >
                            {{ 'cabinet.profile.personal.gender' | translate }}
                        </div>
                        <div
                            class="personal-info-main__block-row-element_text"
                            [ngClass]="{ light: (1 | colourTheme) === false }"
                        >
                            <!--                    {{ person && person.personalInformation ? person.personalInformation.sex : '' }}-->
                            <!--                    TODO: needs check if person is set??-->
                            {{
                                person && person.personalInformation.partner
                                    ? ('cabinet.profile.personal.genderCode.' +
                                          person.personalInformation.partner[0].sex | translate)
                                    : ''
                            }}
                        </div>
                    </div>
                    <!-- MobilePhone -->
                    <!--  <div class="personal-info-main__block-row-element" [ngClass]="{ light: (1 | colourTheme) === false }">
                                <div class="personal-info-main__block-row-element_title" [ngClass]="{ light: (1 | colourTheme) === false }">
                                    {{ 'cabinet.profile.personal.phone' | translate }}
                                </div>
                                <div class="personal-info-main__block-row-element_text" [ngClass]="{ light: (1 | colourTheme) === false }">
                                    {{
                                    person && person.personalInformation.partner && person.personalInformation.addresses
                                        ? person.personalInformation.addresses[0].mobile
                                        : '-'
                                    }}
                                </div>
                            </div> -->
                    <!-- email -->
                    <!--  <div class="personal-info-main__block-row-element" [ngClass]="{ light: (1 | colourTheme) === false }">
                                <div class="personal-info-main__block-row-element_title" [ngClass]="{ light: (1 | colourTheme) === false }">
                                    {{ 'cabinet.profile.personal.email' | translate }}
                                </div>
                                <div class="personal-info-main__block-row-element_text" [ngClass]="{ light: (1 | colourTheme) === false }">
                                    {{
                                    person && person.personalInformation && person.personalInformation.addresses
                                        ? person.personalInformation.addresses[0].email
                                        : '-'
                                    }}
                                </div>
                            </div> -->
                </div>
            </div>
        </div>
        <!-- PersonalData EDIT-->
        <div *ngIf="pageOpen === 'personalDataEdit'">
            <app-insurant-personaldata-edit
                [person]="person"
                [newPerson]="false"
                (editDone)="isEditPersonalDetails = false"
                (editDonee)="pageOpen = 'personalData'"
            >
            </app-insurant-personaldata-edit>
        </div>
        <!-- Employment Relation -->
        <div *ngIf="pageOpen === 'employment'">
            <div class="navigation-tabletView">
                <div
                    class="navigation-elements"
                    [className]="
                        pageOpen === 'personalData' || pageOpen === 'personalDataEdit'
                            ? 'navigationButtonActive'
                            : 'navigation-elements'
                    "
                    (click)="open('personalData')"
                >
                    {{ 'cabinet.dashboard.mutationButtons.addressMutation' | translate }}
                </div>
                <div
                    class="navigation-elements"
                    [className]="pageOpen === 'employment' ? 'navigationButtonActive' : 'navigation-elements'"
                    (click)="open('employment')"
                >
                    {{ 'cabinet.profile.employmentInformation' | translate }}
                </div>
                <div
                    class="navigation-elements"
                    [className]="pageOpen === 'salary' ? 'navigationButtonActive' : 'navigation-elements'"
                    (click)="open('salary')"
                >
                    {{ 'cabinet.profile.salary' | translate }}
                </div>
                <div
                    class="navigation-elements"
                    [className]="pageOpen === 'unpaidHoliday' ? 'navigationButtonActive' : 'navigation-elements'"
                    (click)="open('unpaidHoliday')"
                >
                    {{ 'cabinet.profile.unpaidHoliday' | translate }}
                </div>
            </div>
            <!-- Confirmation Box -->
            <div *ngIf="terminateConfirmationDisplay" class="confirmPageTerminateContainer">
                <!-- Spinner -->
                <div *ngIf="!stepTwoEmployment && showSpinner" class="spinnerCenterAlignContainer">
                    <div class="spinnerContainer">
                        <div class="dotContainer">
                            <div class="dot-left"></div>
                        </div>
                        <div class="dotContainer">
                            <div class="dot-middle"></div>
                        </div>
                        <div class="dotContainer">
                            <div class="dot-right"></div>
                        </div>
                    </div>
                </div>
                <!-- Confirmation Box 1 -->
                <div class="confirmPageTerminateContainerPopUp" *ngIf="!stepTwoEmployment && !showSpinner">
                    <div class="svgIcon">
                        <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M34.25 11.25L32.0102 32.7922C31.9386 33.4004 31.6462 33.9612 31.1886 34.3682C30.7309 34.7752 30.1398 35 29.5273 35H11.4734C10.861 35 10.2699 34.7752 9.81222 34.3682C9.35456 33.9612 9.0622 33.4004 8.99063 32.7922L6.75 11.25"
                                stroke="#FF0000"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M36.75 5H4.25C3.55964 5 3 5.55964 3 6.25V10C3 10.6904 3.55964 11.25 4.25 11.25H36.75C37.4404 11.25 38 10.6904 38 10V6.25C38 5.55964 37.4404 5 36.75 5Z"
                                stroke="#FF0000"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M24.875 18.75L16.125 27.5"
                                stroke="#FF0000"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M24.875 27.5L16.125 18.75"
                                stroke="#FF0000"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    </div>
                    <div class="confirmationTitle">
                        {{ 'cabinet.profile.employment.terminateThisEmployee' | translate }}
                    </div>
                    <div class="confirmationText">
                        {{ 'cabinet.profile.employment.confirmationTextP1' | translate }}
                        <div style="font-weight: 500; display: inline-block">
                            {{ person && person.personalInformation ? person.personalInformation.firstName : '' }}
                            {{ person && person.personalInformation ? person.personalInformation.lastName : '' }}
                        </div>
                    </div>
                    <div class="terminationOverview">
                        <div class="leftBox">
                            <div>{{ 'cabinet.profile.employment.terminationDate' | translate }}:</div>
                            <div>{{ 'cabinet.profile.employment.terminationReason' | translate }}:</div>
                        </div>
                        <div class="rightBox">
                            <div>{{ terminateDate | date : 'dd.MM.yyyy' }}</div>
                            <div>{{ terminationReason }}</div>
                        </div>
                        <div class="rightBox"></div>
                    </div>
                    <div class="buttonContainer">
                        <button class="btnSubmit" style="margin: 0" (click)="terminate()">
                            {{ 'cabinet.profile.buttons.confirm' | translate }}
                        </button>
                        <button class="cancle" (click)="cancleConfirmation()">
                            {{ 'cabinet.profile.buttons.cancel' | translate }}
                        </button>
                    </div>
                </div>
            </div>
            <!-- Confirmation Box 2 -->
            <div *ngIf="stepTwoEmployment" class="confirmation">
                <div class="textContainer">
                    <div class="confirmationText">
                        {{ 'cabinet.profile.employment.confirmationTerminationTextP1' | translate }}
                    </div>
                    <div class="confirmationText" style="font-weight: 500; margin: 0px 5px">
                        {{ person && person.personalInformation ? person.personalInformation.lastName : '' }}
                        {{ person && person.personalInformation ? person.personalInformation.firstName : '' }}
                    </div>
                    <div class="confirmationText">
                        {{ 'cabinet.profile.employment.confirmationTerminationTextP2' | translate }}
                    </div>
                </div>
                <div class="linkIconContainer">
                    <!-- <div class="previewButton">{{ 'cabinet.profile.buttons.undo' | translate }}</div>
                            <div class="icons">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M7.375 14.5625V10.8125C11.9219 10.8125 13.6012 12.1312 15.5 14.5625C15.5 9.90508 13.9543 5.1875 7.375 5.1875V1.4375L0.5 8L7.375 14.5625Z" stroke="var(--primary)" stroke-linejoin="round"/>
                                </svg>
                            </div> -->
                    <div class="previewButton" (click)="goToPreview()">
                        {{ 'cabinet.dashboard.mutationButtons.previewInMutations' | translate }}
                    </div>
                    <div class="icons">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path
                                d="M1.33301 11.9606L12.6271 0.666504"
                                stroke="var(--primary)"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M5.00391 0.666504H12.6274V8.29003"
                                stroke="var(--primary)"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    </div>
                </div>
            </div>
            <div class="employer-panel__panel-tabs-pane" [ngClass]="{ light: (1 | colourTheme) === false }">
                <!-- Sub Title -->
                <div
                    class="employment-info-main__block-subtitle"
                    class="editSubTitle"
                    [ngClass]="{ light: (1 | colourTheme) === false }"
                >
                    {{ 'cabinet.profile.employment.title2' | translate }}
                </div>

                <div class="employment-info-main__block-row" [ngClass]="{ light: (1 | colourTheme) === false }">
                    <div
                        class="employment-info-main__block-row-element"
                        [ngClass]="{ light: (1 | colourTheme) === false }"
                    >
                        <div
                            class="employment-info-main__block-row-element_title"
                            [ngClass]="{ light: (1 | colourTheme) === false }"
                        >
                            {{ 'cabinet.profile.employment.entryDate' | translate }}
                        </div>
                        <div
                            class="employment-info-main__block-row-element_text"
                            [ngClass]="{ light: (1 | colourTheme) === false }"
                        >
                            {{
                                (person && person.insurances[0].employmentAdmittance
                                    ? convertToDate(person.insurances[0].employmentAdmittance)
                                    : ''
                                ) | date : 'dd.MM.yyyy'
                            }}
                        </div>
                    </div>

                    <div
                        class="employment-info-main__block-row-element"
                        [ngClass]="{ light: (1 | colourTheme) === false }"
                    >
                        <div
                            class="employment-info-main__block-row-element_title"
                            [ngClass]="{ light: (1 | colourTheme) === false }"
                        >
                            {{ 'cabinet.profile.employment.insuranceStartDate' | translate }}
                        </div>
                        <div
                            class="employment-info-main__block-row-element_text"
                            [ngClass]="{ light: (1 | colourTheme) === false }"
                        >
                            {{
                                (person && person.insurances[0].insuranceAdmittance
                                    ? convertToDate(person.insurances[0].insuranceAdmittance)
                                    : ''
                                ) | date : 'dd.MM.yyyy'
                            }}
                        </div>
                    </div>
                </div>
                <div
                    *ngIf="edit"
                    class="employment-info-main__block-row"
                    [ngClass]="{ light: (1 | colourTheme) === false }"
                >
                    <div
                        class="employment-info-main__block-row-element"
                        [ngClass]="{ light: (1 | colourTheme) === false }"
                    >
                        <div
                            class="employment-info-main__block-row-element_title"
                            [ngClass]="{ light: (1 | colourTheme) === false }"
                        >
                            {{ 'cabinet.profile.employment.terminationDate' | translate }}
                        </div>
                        <div
                            class="employment-info-main__block-row-element_text"
                            [ngClass]="{ light: (1 | colourTheme) === false }"
                        >
                            {{
                                (person && person.insurances[0].employmentTermination
                                    ? convertToDate(person.insurances[0].employmentTermination)
                                    : ''
                                ) | date : 'dd.MM.yyyy'
                            }}
                        </div>
                    </div>

                    <div
                        class="employment-info-main__block-row-element"
                        [ngClass]="{ light: (1 | colourTheme) === false }"
                    >
                        <div
                            class="employment-info-main__block-row-element_title"
                            [ngClass]="{ light: (1 | colourTheme) === false }"
                        >
                            {{ 'cabinet.profile.employment.insuranceEndDate' | translate }}
                        </div>
                        <div
                            class="employment-info-main__block-row-element_text"
                            [ngClass]="{ light: (1 | colourTheme) === false }"
                        >
                            {{
                                (person && person.insurances[0].insuranceTermination
                                    ? convertToDate(person.insurances[0].insuranceTermination)
                                    : ''
                                ) | date : 'dd.MM.yyyy'
                            }}
                        </div>
                    </div>
                </div>
                <div
                    *ngIf="!edit"
                    class="employment-info-main__block-subtitle"
                    class="editSubTitle"
                    [ngClass]="{ light: (1 | colourTheme) === false }"
                >
                    {{ 'cabinet.profile.buttons.terminate' | translate }}
                </div>
                <div *ngIf="!edit" class="personal-info-main__block-row">
                    <div
                        class="employment-info-main__block-row-element"
                        [ngClass]="{ light: (1 | colourTheme) === false }"
                    >
                        <mat-form-field appearance="outline" class="testWidth">
                            <mat-label>{{ 'cabinet.profile.employment.terminationDate' | translate }}</mat-label>
                            <input
                                matInput
                                (change)="terminationDateChange($event)"
                                [(ngModel)]="terminationDateValue"
                                type="date"
                                min="{{ minDateOfBirth | date : 'yyyy-MM-dd' }}"
                                max="{{ maxDateOfBirth | date : 'yyyy-MM-dd' }}"
                            />
                        </mat-form-field>
                    </div>
                    <div
                        class="employment-info-main__block-row-element"
                        [ngClass]="{ light: (1 | colourTheme) === false }"
                    >
                        <mat-form-field appearance="outline" class="testWidth">
                            <mat-label>{{ 'cabinet.profile.employment.terminationReason' | translate }}</mat-label>
                            <mat-select (selectionChange)="terminateReasonChange($event)">
                                <mat-option
                                    *ngFor="let reason of reasonList"
                                    [(ngModel)]="terminationReasonValue"
                                    [value]="reason"
                                    name="fieldName"
                                    ngDefaultControl
                                >
                                    {{ reason }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="pageOpen === 'salary'">
            <div class="scrollable">
                <div class="navigation-tabletView">
                    <div
                        class="navigation-elements"
                        [className]="
                            pageOpen === 'personalData' || pageOpen === 'personalDataEdit'
                                ? 'navigationButtonActive'
                                : 'navigation-elements'
                        "
                        (click)="open('personalData')"
                    >
                        {{ 'cabinet.dashboard.mutationButtons.addressMutation' | translate }}
                    </div>
                    <div
                        class="navigation-elements"
                        [className]="pageOpen === 'employment' ? 'navigationButtonActive' : 'navigation-elements'"
                        (click)="open('employment')"
                    >
                        {{ 'cabinet.profile.employmentInformation' | translate }}
                    </div>
                    <div
                        class="navigation-elements"
                        [className]="pageOpen === 'salary' ? 'navigationButtonActive' : 'navigation-elements'"
                        (click)="open('salary')"
                    >
                        {{ 'cabinet.profile.salary' | translate }}
                    </div>
                    <div
                        class="navigation-elements"
                        [className]="pageOpen === 'unpaidHoliday' ? 'navigationButtonActive' : 'navigation-elements'"
                        (click)="open('unpaidHoliday')"
                    >
                        {{ 'cabinet.profile.unpaidHoliday' | translate }}
                    </div>
                </div>
                <div *ngIf="salaryConfirmationDisplay" class="confirmPageTerminateContainer">
                    <!-- Spinner -->
                    <div *ngIf="!stepTwoSalary && showSpinner" class="spinnerCenterAlignContainer">
                        <div class="spinnerContainer">
                            <div class="dotContainer">
                                <div class="dot-left"></div>
                            </div>
                            <div class="dotContainer">
                                <div class="dot-middle"></div>
                            </div>
                            <div class="dotContainer">
                                <div class="dot-right"></div>
                            </div>
                        </div>
                    </div>
                    <!-- Confirmation Box 1 -->
                    <div class="confirmPageTerminateContainerPopUp" *ngIf="!stepTwoSalary && !showSpinner">
                        <div class="svgIcon">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="40"
                                height="40"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <path
                                    d="M2.25 15.75L21.75 15.75C22.1642 15.75 22.5 15.4142 22.5 15V4.5C22.5 4.08579 22.1642 3.75 21.75 3.75L2.25 3.75C1.83579 3.75 1.5 4.08579 1.5 4.5V15C1.5 15.4142 1.83579 15.75 2.25 15.75Z"
                                    stroke="var(--primary)"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M3 18H21"
                                    stroke="var(--primary)"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M4.5 20.25H19.5"
                                    stroke="var(--primary)"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M12 13.5C14.0711 13.5 15.75 11.8211 15.75 9.75C15.75 7.67893 14.0711 6 12 6C9.92893 6 8.25 7.67893 8.25 9.75C8.25 11.8211 9.92893 13.5 12 13.5Z"
                                    stroke="var(--primary)"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M22.5 7.5C21.5054 7.5 20.5516 7.10491 19.8483 6.40165C19.1451 5.69839 18.75 4.74456 18.75 3.75"
                                    stroke="var(--primary)"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M1.5 7.5C1.99246 7.5 2.48009 7.403 2.93506 7.21455C3.39003 7.02609 3.80343 6.74987 4.15165 6.40165C4.49987 6.05343 4.77609 5.64003 4.96455 5.18506C5.153 4.73009 5.25 4.24246 5.25 3.75"
                                    stroke="var(--primary)"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M22.5 12C21.5054 12 20.5516 12.3951 19.8483 13.0983C19.1451 13.8016 18.75 14.7554 18.75 15.75"
                                    stroke="var(--primary)"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M1.5 12C1.99246 12 2.48009 12.097 2.93506 12.2855C3.39003 12.4739 3.80343 12.7501 4.15165 13.0983C4.49987 13.4466 4.77609 13.86 4.96455 14.3149C5.153 14.7699 5.25 15.2575 5.25 15.75"
                                    stroke="var(--primary)"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </div>
                        <div class="confirmationTitle">{{ 'cabinet.profile.salaryChange' | translate }}</div>
                        <div class="confirmationText">
                            {{ 'cabinet.profile.employment.confirmationTextSalary' | translate }}
                            <div style="font-weight: 500; display: inline-block">
                                {{ person && person.personalInformation ? person.personalInformation.firstName : '' }}
                                {{ person && person.personalInformation ? person.personalInformation.lastName : '' }}
                            </div>
                        </div>
                        <div class="terminationOverview">
                            <div class="leftBox">
                                <div>{{ 'cabinet.wage.salaryChangeDate' | translate }}:</div>
                                <div>{{ 'cabinet.tableFields.annualSalary' | translate }}:</div>
                                <div>{{ 'cabinet.profile.employment.degreeWithoutPercent' | translate }}:</div>
                            </div>
                            <div class="rightBox">
                                <div>{{ salaryDate | date : 'dd.MM.yyyy' }}</div>
                                <div>{{ salary | splitNumberWithComma }} CHF</div>
                                <div>{{ degreeOfEmployment }}%</div>
                            </div>
                            <div class="rightBox"></div>
                        </div>
                        <div class="buttonContainer">
                            <button class="btnSubmit" style="margin: 0" (click)="changeSalary()">
                                {{ 'cabinet.profile.buttons.confirm' | translate }}
                            </button>
                            <button class="cancle" (click)="cancleConfirmation()">
                                {{ 'cabinet.profile.buttons.cancel' | translate }}
                            </button>
                        </div>
                    </div>
                </div>
                <!-- Confirmation Box 2 -->
                <div *ngIf="stepTwoSalary" class="confirmation">
                    <div class="textContainer">
                        <div class="confirmationText">
                            {{ 'cabinet.profile.employment.confirmationSalaryTextP1' | translate }}
                        </div>
                        <div class="confirmationText" style="font-weight: 500; margin: 0px 5px">
                            {{ person && person.personalInformation ? person.personalInformation.lastName : '' }}
                            {{ person && person.personalInformation ? person.personalInformation.firstName : '' }}
                        </div>
                        <div class="confirmationText">
                            {{ 'cabinet.profile.employment.confirmationSalaryTextP2' | translate }}
                        </div>
                    </div>
                    <div class="linkIconContainer">
                        <!-- <div class="previewButton">{{ 'cabinet.profile.buttons.undo' | translate }}</div>
                                <div class="icons">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M7.375 14.5625V10.8125C11.9219 10.8125 13.6012 12.1312 15.5 14.5625C15.5 9.90508 13.9543 5.1875 7.375 5.1875V1.4375L0.5 8L7.375 14.5625Z" stroke="var(--primary)" stroke-linejoin="round"/>
                                    </svg>
                                </div> -->
                        <div class="previewButton" (click)="goToPreview()">
                            {{ 'cabinet.dashboard.mutationButtons.previewInMutations' | translate }}
                        </div>
                        <div class="icons">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <path
                                    d="M1.33301 11.9606L12.6271 0.666504"
                                    stroke="var(--primary)"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M5.00391 0.666504H12.6274V8.29003"
                                    stroke="var(--primary)"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </div>
                    </div>
                </div>
                <div class="employer-panel__panel-tabs-pane" [ngClass]="{ light: (1 | colourTheme) === false }">
                    <div class="editSubTitle" [ngClass]="{ light: (1 | colourTheme) === false }">
                        {{ 'cabinet.profile.employment.title1' | translate }}
                    </div>
                    <div class="employment-info-main__block-row" [ngClass]="{ light: (1 | colourTheme) === false }">
                        <div
                            class="employment-info-main__block-row-element"
                            [ngClass]="{ light: (1 | colourTheme) === false }"
                        >
                            <div
                                class="employment-info-main__block-row-element_title"
                                [ngClass]="{ light: (1 | colourTheme) === false }"
                            >
                                {{ 'cabinet.profile.employment.validityDate' | translate }}
                            </div>
                            <div
                                class="employment-info-main__block-row-element_text"
                                [ngClass]="{ light: (1 | colourTheme) === false }"
                            >
                                {{
                                    (person && person.insurances[0].salaries[0].validity
                                        ? convertToDate(person.insurances[0].salaries[0].validity)
                                        : ''
                                    ) | date : 'dd.MM.yyyy'
                                }}
                            </div>
                        </div>

                        <div
                            class="employment-info-main__block-row-element"
                            [ngClass]="{ light: (1 | colourTheme) === false }"
                        >
                            <div
                                class="employment-info-main__block-row-element_title"
                                [ngClass]="{ light: (1 | colourTheme) === false }"
                            >
                                {{ 'cabinet.profile.employment.salary' | translate }}
                            </div>
                            <div
                                class="employment-info-main__block-row-element_text"
                                [ngClass]="{ light: (1 | colourTheme) === false }"
                            >
                                {{
                                    person
                                        ? (person.insurances[0].salaries[0].amount | splitNumberWithComma : true)
                                        : ''
                                }}
                            </div>
                        </div>

                        <div
                            class="employment-info-main__block-row-element"
                            [ngClass]="{ light: (1 | colourTheme) === false }"
                        >
                            <div
                                class="employment-info-main__block-row-element_title"
                                [ngClass]="{ light: (1 | colourTheme) === false }"
                            >
                                {{ 'cabinet.profile.employment.degree' | translate }}
                            </div>
                            <div
                                class="employment-info-main__block-row-element_text"
                                [ngClass]="{ light: (1 | colourTheme) === false }"
                            >
                                {{
                                    person && person.insurances
                                        ? (person.insurances[0].salaries[0].levelOfEmployment
                                          | splitNumberWithComma : true)
                                        : ''
                                }}
                            </div>
                        </div>

                        <div
                            class="employment-info-main__block-row-element"
                            [ngClass]="{ light: (1 | colourTheme) === false }"
                        >
                            <div
                                class="employment-info-main__block-row-element_title"
                                [ngClass]="{ light: (1 | colourTheme) === false }"
                            >
                                {{ 'cabinet.profile.employment.premiumWaiver' | translate }}
                            </div>
                            <div
                                class="employment-info-main__block-row-element_text"
                                [ngClass]="{ light: (1 | colourTheme) === false }"
                            >
                                {{
                                    person && person.insurances
                                        ? (person.insurances[0].salaries[0].levelOfPremiumWaiver
                                          | splitNumberWithComma : true)
                                        : ''
                                }}
                            </div>
                        </div>

                        <div
                            class="employment-info-main__block-row-element"
                            [ngClass]="{ light: (1 | colourTheme) === false }"
                        >
                            <div
                                class="employment-info-main__block-row-element_title"
                                [ngClass]="{ light: (1 | colourTheme) === false }"
                            >
                                {{ 'cabinet.profile.employment.grad' | translate }}
                            </div>
                            <div
                                class="employment-info-main__block-row-element_text"
                                [ngClass]="{ light: (1 | colourTheme) === false }"
                            >
                                {{
                                    person && person.insurances
                                        ? (person.insurances[0].salaries[0].levelOfDisability
                                          | splitNumberWithComma : true)
                                        : ''
                                }}
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="employer-panel__panel-tabs-pane"
                    style="padding-top: 0"
                    [ngClass]="{ light: (1 | colourTheme) === false }"
                >
                    <div *ngIf="!edit" class="editSubTitle" [ngClass]="{ light: (1 | colourTheme) === false }">
                        {{ 'cabinet.wage.salary' | translate }}
                    </div>
                    <div *ngIf="!edit" class="salary-block-row">
                        <div
                            class="employment-info-main__block-row-element"
                            [ngClass]="{ light: (1 | colourTheme) === false }"
                        >
                            <mat-form-field appearance="outline" class="testWidth">
                                <mat-label>{{ 'cabinet.wage.salaryChangeDate' | translate }}</mat-label>
                                <input
                                    (keydown)="onlyNumbers($event)"
                                    matInput
                                    (change)="salaryDateChange($event)"
                                    type="date"
                                    [(ngModel)]="salaryDateValue"
                                    min="{{ minDateOfBirth | date : 'yyyy-MM-dd' }}"
                                    max="{{ maxDateOfBirth | date : 'yyyy-MM-dd' }}"
                                />
                            </mat-form-field>
                        </div>
                        <div
                            class="employment-info-main__block-row-element"
                            [ngClass]="{ light: (1 | colourTheme) === false }"
                        >
                            <mat-form-field appearance="outline" class="testWidth">
                                <mat-label>{{ 'cabinet.profile.employment.salary' | translate }}</mat-label>
                                <input
                                    (keydown)="onlyNumbers($event)"
                                    (keyup)="salaryChange($event)"
                                    [(ngModel)]="salaryValue"
                                    matInput
                                    type="text"
                                />
                            </mat-form-field>
                        </div>
                        <div
                            class="employment-info-main__block-row-element"
                            [ngClass]="{ light: (1 | colourTheme) === false }"
                        >
                            <mat-form-field appearance="outline" class="testWidth">
                                <mat-label>{{ 'cabinet.profile.employment.degree' | translate }}</mat-label>
                                <input
                                    (keydown)="onlyNumbers($event)"
                                    (keyup)="degreeOfEmplyomentChange($event)"
                                    [(ngModel)]="degreeOfEmplymentValue"
                                    matInput
                                    type="text"
                                    maxlength="3"
                                />
                            </mat-form-field>
                            <div *ngIf="employmentDegreeInvalid" class="error">
                                {{ 'cabinet.profile.employment.employmentDegreeError' | translate }}
                            </div>
                        </div>
                    </div>
                    <!-- History -->
                    <div class="historyBlock">
                        <div (click)="showHistory()">
                            <div class="historyButton">{{ 'cabinet.wage.showHistory' | translate }}</div>
                            <svg
                                *ngIf="showSalaryHistory"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24px"
                                height="24px"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <path
                                    d="M4 8L12 16L20 8"
                                    stroke="black"
                                    stroke-width="1"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                            <svg
                                *ngIf="!showSalaryHistory"
                                style="transform: rotate(180deg)"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24px"
                                height="24px"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <path
                                    d="M4 8L12 16L20 8"
                                    stroke="black"
                                    stroke-width="1"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </div>
                        <div class="tableContainer" [@openClose]="isOpen ? 'open' : 'closed'">
                            <div class="tableHeader">
                                <div style="width: 15%">{{ 'cabinet.tableFields.valueSalary' | translate }}</div>
                                <div style="width: 2%"></div>
                                <div style="width: 18%; text-align: right">
                                    {{ 'cabinet.tableFields.annualSalary' | translate }}
                                </div>
                                <div style="width: 2%"></div>
                                <div style="width: 20%; text-align: right">
                                    {{ 'cabinet.tableFields.degreeEmployment' | translate }}
                                </div>
                                <div style="width: 2%"></div>
                                <div style="width: 20%; text-align: right">
                                    {{ 'cabinet.tableFields.degreeOfPremiumWaiver' | translate }}
                                </div>
                                <div style="width: 2%"></div>
                                <div style="width: 15%; text-align: right">
                                    {{ 'cabinet.tableFields.degreeOfDisability' | translate }}
                                </div>
                                <div style="width: 2%"></div>
                            </div>
                            <div class="tableBody">
                                <div class="tableBodyRow" *ngFor="let item of person.insurances[0].salaries">
                                    <div style="width: 15%">{{ item.validity | date : 'dd.MM.yyyy' }}</div>
                                    <div style="width: 2%"></div>
                                    <div style="width: 18%; display: flex; flex-direction: row-reverse">
                                        {{ item.amount | splitNumberWithComma : true }}
                                    </div>
                                    <div style="width: 2%"></div>
                                    <div style="width: 20%; display: flex; flex-direction: row-reverse">
                                        {{ item.levelOfEmployment }}
                                    </div>
                                    <div style="width: 2%"></div>
                                    <div style="width: 20%; display: flex; flex-direction: row-reverse">
                                        {{ item.levelOfPremiumWaiver }}
                                    </div>
                                    <div style="width: 2%"></div>
                                    <div style="width: 15%; display: flex; flex-direction: row-reverse">
                                        {{ item.levelOfDisability }}
                                    </div>
                                    <div style="width: 2%"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="height: 100px"></div>
            </div>
        </div>
        <div *ngIf="pageOpen === 'unpaidHoliday'">
            <div class="navigation-tabletView">
                <div
                    class="navigation-elements"
                    [className]="
                        pageOpen === 'personalData' || pageOpen === 'personalDataEdit'
                            ? 'navigationButtonActive'
                            : 'navigation-elements'
                    "
                    (click)="open('personalData')"
                >
                    {{ 'cabinet.dashboard.mutationButtons.addressMutation' | translate }}
                </div>
                <div
                    class="navigation-elements"
                    [className]="pageOpen === 'employment' ? 'navigationButtonActive' : 'navigation-elements'"
                    (click)="open('employment')"
                >
                    {{ 'cabinet.profile.employmentInformation' | translate }}
                </div>
                <div
                    class="navigation-elements"
                    [className]="pageOpen === 'salary' ? 'navigationButtonActive' : 'navigation-elements'"
                    (click)="open('salary')"
                >
                    {{ 'cabinet.profile.salary' | translate }}
                </div>
                <div
                    class="navigation-elements"
                    [className]="pageOpen === 'unpaidHoliday' ? 'navigationButtonActive' : 'navigation-elements'"
                    (click)="open('unpaidHoliday')"
                >
                    {{ 'cabinet.profile.unpaidHoliday' | translate }}
                </div>
            </div>
            <div *ngIf="unpaidHolidayConfirmationDisplay" class="confirmPageTerminateContainer">
                <!-- Confirmation Box 1 -->
                <div class="confirmPageTerminateContainerPopUp" *ngIf="!stepTwoUnpaidHoliday && !showSpinner">
                    <div class="confirmationTitle">
                        {{ 'cabinet.profile.unpaidHolidays.addUnpaidHolidayText' | translate }}
                    </div>
                    <div class="confirmationText">
                        {{ 'cabinet.profile.unpaidHolidays.confirmationTextUnpaidHoliday' | translate }}
                        <div style="font-weight: 500; display: inline-block">
                            {{ person && person.personalInformation ? person.personalInformation.firstName : '' }}
                            {{ person && person.personalInformation ? person.personalInformation.lastName : '' }}
                        </div>
                    </div>
                    <div class="terminationOverviewHoliday">
                        <div>
                            {{ 'cabinet.profile.unpaidHolidays.from' | translate }}:
                            {{ holidayStartDate | date : 'dd.MM.yyyy' }}
                        </div>
                        <div>
                            {{ 'cabinet.profile.unpaidHolidays.to' | translate }}:
                            {{ holidayStartDate | date : 'dd.MM.yyyy' }}
                        </div>
                        <div>
                            {{ 'cabinet.profile.unpaidHolidays.continueTheInsurance' | translate }}:
                            {{ holidayInsurance }}
                        </div>
                    </div>
                    <div class="buttonContainerHoliday">
                        <button class="btnSubmit" style="margin: 0" (click)="unpaidHolidayRequest()">
                            {{ 'cabinet.profile.buttons.confirm' | translate }}
                        </button>
                        <button class="cancle" (click)="cancleConfirmation()">
                            {{ 'cabinet.profile.buttons.cancel' | translate }}
                        </button>
                    </div>
                </div>
            </div>
            <!-- Confirmation Box 2 -->
            <div *ngIf="stepTwoUnpaidHoliday" class="confirmation">
                <div class="textContainer">
                    <div class="confirmationText">
                        {{ 'cabinet.profile.employment.confirmationUnpaidHolidayTextP1' | translate }}
                    </div>
                    <div class="confirmationText" style="font-weight: 500; margin: 0px 5px">
                        {{ person && person.personalInformation ? person.personalInformation.lastName : '' }}
                        {{ person && person.personalInformation ? person.personalInformation.firstName : '' }}
                    </div>
                    <div class="confirmationText">
                        {{ 'cabinet.profile.employment.confirmationUnpaidHolidayTextP2' | translate }}
                    </div>
                </div>
                <div class="linkIconContainer">
                    <!-- <div class="previewButton">{{ 'cabinet.profile.buttons.undo' | translate }}</div>
                            <div class="icons">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M7.375 14.5625V10.8125C11.9219 10.8125 13.6012 12.1312 15.5 14.5625C15.5 9.90508 13.9543 5.1875 7.375 5.1875V1.4375L0.5 8L7.375 14.5625Z" stroke="var(--primary)" stroke-linejoin="round"/>
                                </svg>
                            </div> -->
                    <div class="previewButton" (click)="goToPreview()">
                        {{ 'cabinet.dashboard.mutationButtons.previewInMutations' | translate }}
                    </div>
                    <div class="icons">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path
                                d="M1.33301 11.9606L12.6271 0.666504"
                                stroke="var(--primary)"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M5.00391 0.666504H12.6274V8.29003"
                                stroke="var(--primary)"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    </div>
                </div>
            </div>
            <div class="employer-panel__panel-tabs-pane" [ngClass]="{ light: (1 | colourTheme) === false }">
                <div *ngIf="!edit" class="editSubTitle" [ngClass]="{ light: (1 | colourTheme) === false }">
                    {{ 'cabinet.profile.unpaidHoliday' | translate }}
                </div>
                <div *ngIf="!edit" class="salary-block-row">
                    <div
                        class="employment-info-main__block-row-element"
                        [ngClass]="{ light: (1 | colourTheme) === false }"
                    >
                        <mat-form-field appearance="outline" class="testWidth">
                            <mat-label>{{ 'cabinet.profile.unpaidHolidays.from' | translate }}</mat-label>
                            <input
                                matInput
                                (change)="unpaidHolidayDateChange($event, 'start')"
                                [(ngModel)]="unpaidHolidayStartValue"
                                type="date"
                                min="{{ minDateOfBirth | date : 'yyyy-MM-dd' }}"
                                max="{{ maxDateOfBirth | date : 'yyyy-MM-dd' }}"
                            />
                        </mat-form-field>
                    </div>
                    <div
                        class="employment-info-main__block-row-element"
                        [ngClass]="{ light: (1 | colourTheme) === false }"
                    >
                        <mat-form-field appearance="outline" class="testWidth">
                            <mat-label>{{ 'cabinet.profile.unpaidHolidays.to' | translate }}</mat-label>
                            <input
                                matInput
                                (change)="unpaidHolidayDateChange($event, 'end')"
                                [(ngModel)]="unpaidHolidayEndValue"
                                type="date"
                                min="{{ minDateOfBirth | date : 'yyyy-MM-dd' }}"
                                max="{{ maxDateOfBirth | date : 'yyyy-MM-dd' }}"
                            />
                        </mat-form-field>
                    </div>
                    <div
                        class="employment-info-main__block-row-element"
                        [ngClass]="{ light: (1 | colourTheme) === false }"
                    >
                        <mat-form-field appearance="outline" class="testWidth">
                            <mat-label
                                >{{ 'cabinet.profile.unpaidHolidays.continueTheInsurance' | translate }}
                            </mat-label>
                            <mat-select
                                (selectionChange)="continueInsuranceChange($event)"
                                [(ngModel)]="insuranceChangeValue"
                            >
                                <mat-option *ngFor="let reason of reasonListContinueInsurance" [value]="reason">
                                    {{ reason }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div
                        class="employment-info-main__block-row-element"
                        [ngClass]="{ light: (1 | colourTheme) === false }"
                    >
                        <mat-form-field appearance="outline" class="testWidth">
                            <mat-label>{{ 'cabinet.profile.unpaidHolidays.delete' | translate }}</mat-label>
                            <mat-select (selectionChange)="deleteChange($event)" [(ngModel)]="deleteValue">
                                <mat-option *ngFor="let reason of reasonListCreateDelete" [value]="reason">
                                    {{ reason }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <!-- History -->
                <div class="historyBlock">
                    <div (click)="showHistory()">
                        <div class="historyButton">{{ 'cabinet.wage.showHistory' | translate }}</div>
                        <svg
                            *ngIf="showSalaryHistory"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            fill="none"
                        >
                            <path
                                d="M4 8L12 16L20 8"
                                stroke="black"
                                stroke-width="1"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                        <svg
                            *ngIf="!showSalaryHistory"
                            style="transform: rotate(180deg)"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            fill="none"
                        >
                            <path
                                d="M4 8L12 16L20 8"
                                stroke="black"
                                stroke-width="1"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    </div>
                    <div class="tableContainer" [@openClose]="isOpen ? 'open' : 'closed'">
                        <div class="tableHeader">
                            <div style="width: 15%">{{ 'cabinet.tableFields.start' | translate }}</div>
                            <div style="width: 2%"></div>
                            <div style="width: 15%">{{ 'cabinet.tableFields.end' | translate }}</div>
                            <div style="width: 2%"></div>
                            <div style="width: 35%">
                                {{ 'cabinet.tableFields.continuationOfInsurance' | translate }}
                            </div>
                            <div style="width: 2%"></div>
                            <div style="width: 25%">{{ 'cabinet.tableFields.status' | translate }}</div>
                            <div style="width: 2%"></div>
                        </div>
                        <div class="tableBody">
                            <div class="tableBodyRow" *ngFor="let item of fakeData">
                                <div style="width: 15%">{{ item.start | date : 'dd.MM.yyyy' }}</div>
                                <div style="width: 2%"></div>
                                <div style="width: 15%">{{ item.end | date : 'dd.MM.yyyy' }}</div>
                                <!-- <div style="width: 18%; display: flex; flex-direction: row-reverse;">{{item.amount | splitNumberWithComma: true }}</div>
    -->
                                <div style="width: 2%"></div>
                                <div style="width: 35%">
                                    {{
                                        'cabinet.profile.employment.reasonListContinueInsurance.riskToEmployee'
                                            | translate
                                    }}
                                </div>
                                <!--                                <div style="width: 20%; display: flex; flex-direction: row-reverse;">{{item.levelOfEmployment}}</div>
    -->
                                <div style="width: 2%"></div>
                                <div style="width: 25%">{{ 'cabinet.dashboard.statuses.done' | translate }}</div>
                                <!--                                <div style="width: 20%; display: flex; flex-direction: row-reverse;">{{item.levelOfPremiumWaiver}}</div>
    -->
                                <div style="width: 2%"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Employment -->
<!-- Employment -->
<div
    class="stickyBoxForEmploymentRelationship"
    *ngIf="pageOpen === 'employment' && !terminateConfirmationDisplay && !edit"
>
    <button
        *ngIf="terminateButtonActive && !terminateConfirmationDisplay"
        class="button"
        type="button"
        class="btnSubmit"
        style="float: left"
        (click)="openTerminationConfirmation()"
    >
        {{ 'cabinet.profile.buttons.terminate' | translate }}
    </button>
    <button
        *ngIf="!terminateButtonActive"
        class="button"
        type="button"
        class="btnSubmit"
        class="inactive"
        style="float: left"
    >
        {{ 'cabinet.profile.buttons.terminate' | translate }}
    </button>
    <button class="cancleClose" type="button" style="float: left" (click)="abort()">
        {{ 'cabinet.profile.buttons.cancel' | translate }}
    </button>
</div>
<!-- Salary -->
<div class="stickyBoxForEmploymentRelationship" *ngIf="pageOpen === 'salary' && !salaryConfirmationDisplay && !edit">
    <button
        *ngIf="salaryButtonActive && !salaryConfirmationDisplay"
        class="button"
        type="button"
        class="btnSubmit"
        style="float: left"
        (click)="openSalaryConfirmation()"
    >
        {{ 'cabinet.profile.buttons.newSalary' | translate }}
    </button>
    <button
        *ngIf="!salaryButtonActive"
        class="button"
        type="button"
        class="btnSubmit"
        class="inactive"
        style="float: left"
    >
        {{ 'cabinet.profile.buttons.newSalary' | translate }}
    </button>
    <button class="cancleClose" type="button" style="float: left" (click)="abort()">
        {{ 'cabinet.profile.buttons.cancel' | translate }}
    </button>
</div>
<!-- Unpaid Holiday -->
<div
    class="stickyBoxForEmploymentRelationship"
    *ngIf="pageOpen === 'unpaidHoliday' && !unpaidHolidayConfirmationDisplay && !edit"
>
    <button
        *ngIf="unpaidHolidayButtonAcitve && !salaryConfirmationDisplay"
        class="button"
        type="button"
        class="btnSubmit"
        style="float: left"
        (click)="openUnpaidHolidayConfirmation()"
    >
        {{ 'cabinet.profile.buttons.save' | translate }}
    </button>
    <button
        *ngIf="!unpaidHolidayButtonAcitve"
        class="button"
        type="button"
        class="btnSubmit"
        class="inactive"
        style="float: left"
    >
        {{ 'cabinet.profile.buttons.save' | translate }}
    </button>
    <button class="cancleClose" type="button" style="float: left" (click)="abort()">
        {{ 'cabinet.profile.buttons.cancel' | translate }}
    </button>
</div>

<div class="errorContainer" *ngIf="errorMessage">
    <div class="errorContainer-Box">
        <div class="errorContainer-Box-Text">
            <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 24 24" fill="none">
                <path
                    d="M21 12C21 7.03125 16.9688 3 12 3C7.03125 3 3 7.03125 3 12C3 16.9688 7.03125 21 12 21C16.9688 21 21 16.9688 21 12Z"
                    stroke="#FF0000"
                    stroke-miterlimit="10"
                />
                <path
                    d="M11.731 7.7837L12.0001 13.5001L12.2686 7.7837C12.2703 7.74716 12.2645 7.71066 12.2516 7.67644C12.2386 7.64222 12.2189 7.61099 12.1935 7.58467C12.1681 7.55834 12.1376 7.53748 12.1038 7.52334C12.0701 7.5092 12.0338 7.5021 11.9972 7.50245V7.50245C11.9611 7.50279 11.9254 7.51041 11.8923 7.52486C11.8591 7.53931 11.8292 7.56028 11.8044 7.58653C11.7795 7.61278 11.7602 7.64377 11.7476 7.67765C11.735 7.71152 11.7294 7.74759 11.731 7.7837V7.7837Z"
                    stroke="#FF0000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M12 17.2459C11.8146 17.2459 11.6333 17.1909 11.4792 17.0879C11.325 16.9848 11.2048 16.8384 11.1339 16.6671C11.0629 16.4958 11.0443 16.3073 11.0805 16.1255C11.1167 15.9436 11.206 15.7765 11.3371 15.6454C11.4682 15.5143 11.6352 15.425 11.8171 15.3889C11.999 15.3527 12.1875 15.3713 12.3588 15.4422C12.5301 15.5132 12.6765 15.6333 12.7795 15.7875C12.8825 15.9417 12.9375 16.1229 12.9375 16.3084C12.9375 16.557 12.8387 16.7954 12.6629 16.9713C12.4871 17.1471 12.2486 17.2459 12 17.2459Z"
                    fill="#FF0000"
                />
            </svg>
        </div>
        <div class="errorContainer-Box-Text">{{ 'auth.errors.workflows.error' | translate }}</div>
        <div class="errorContainer-Box-Text"><a href="mailto:service@swarm.cloud">service@swarm.cloud</a></div>
        <div type="button" class="btnClose" (click)="clickCloseErrorMessage()">
            {{ 'cabinet.navigation.close' | translate }}
        </div>
    </div>
</div>
