<button class="user-menu__btn" (click)="toggleMenu($event)" [ngClass]="{ light: (1 | colourTheme) === false }">
    <svg
        _ngcontent-ent-c169=""
        xmlns="http://www.w3.org/2000/svg"
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        fill="none"
        class="ng-tns-c169-68"
    >
        <path
            _ngcontent-ent-c169=""
            d="M4 8L12 16L20 8"
            stroke="black"
            stroke-width="1"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="ng-tns-c169-68"
        ></path>
    </svg>
    <div
        class="user-menu__modal"
        [ngClass]="{ light: (1 | colourTheme) === false }"
        *ngIf="showMenu"
        (clickOutside)="clickOutside()"
        (click)="$event.stopPropagation()"
    >
        <div class="user-menu__modal-item">
            <p class="user-menu__modal-item-title" [ngClass]="{ light: (1 | colourTheme) === false }">
                {{ 'header.userMenu.language' | translate }}
            </p>
            <div class="user-menu__modal-values-wrapper">
                <p
                    class="user-menu__modal-value"
                    [ngClass]="{ light: (1 | colourTheme) === false }"
                    *ngFor="let item of languageArray"
                    [class.user-menu__modal-value--active]="item === currentLocale"
                    (click)="selectLang(item)"
                >
                    {{ item }}
                </p>
            </div>
        </div>
        <!-- <div class="user-menu__modal-item-icons">
        <div
        class="user-header__eye-btn-wrapper"
        (click)="toggleCurrencyValueVisibility()"
        [ngClass]="{ light: (1 | colourTheme) === false }"
        >
        
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" fill="var(--primary)"/>
            <path d="M23.0081 11.1844C21.7678 9.26625 20.1586 7.63688 18.3548 6.47203C16.3594 5.18203 14.1562 4.5 11.9841 4.5C9.99093 4.5 8.03109 5.06953 6.1589 6.19266C4.24968 7.33781 2.51999 9.01078 1.01765 11.1647C0.848044 11.4081 0.754638 11.6965 0.749297 11.9931C0.743955 12.2897 0.826919 12.5813 0.987645 12.8306C2.22561 14.768 3.8189 16.3997 5.59452 17.5486C7.59374 18.8437 9.74531 19.5 11.9841 19.5C14.1736 19.5 16.3814 18.8236 18.3684 17.5444C20.1713 16.3833 21.7772 14.7478 23.0128 12.8137C23.168 12.5702 23.2501 12.2872 23.2492 11.9984C23.2484 11.7096 23.1647 11.4271 23.0081 11.1844V11.1844ZM12 16.5C11.11 16.5 10.2399 16.2361 9.49993 15.7416C8.75991 15.2471 8.18313 14.5443 7.84254 13.7221C7.50194 12.8998 7.41283 11.995 7.58646 11.1221C7.76009 10.2492 8.18868 9.44736 8.81801 8.81802C9.44735 8.18868 10.2492 7.7601 11.1221 7.58647C11.995 7.41283 12.8998 7.50195 13.7221 7.84254C14.5443 8.18314 15.2471 8.75991 15.7416 9.49993C16.2361 10.24 16.5 11.11 16.5 12C16.4986 13.1931 16.0241 14.3369 15.1805 15.1805C14.3369 16.0241 13.1931 16.4986 12 16.5V16.5Z" fill="var(--primary)"/>
            </svg>
        </div>
        <div (click)="openFagPage()" class="user-header__fag header-faq header-block">
            <div class="header-faq__icon header-block__icon">
                
                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                    <path d="M10.5 0C4.70312 0 0 4.70312 0 10.5C0 16.2969 4.70312 21 10.5 21C16.2969 21 21 16.2969 21 10.5C21 4.70312 16.2969 0 10.5 0ZM10.1719 16.625C9.95555 16.625 9.74409 16.5609 9.56422 16.4407C9.38435 16.3205 9.24417 16.1497 9.16138 15.9498C9.0786 15.75 9.05694 15.53 9.09914 15.3179C9.14134 15.1057 9.24551 14.9108 9.39848 14.7579C9.55144 14.6049 9.74633 14.5007 9.9585 14.4585C10.1707 14.4163 10.3906 14.438 10.5904 14.5208C10.7903 14.6035 10.9611 14.7437 11.0813 14.9236C11.2015 15.1035 11.2656 15.3149 11.2656 15.5312C11.2656 15.8213 11.1504 16.0995 10.9453 16.3046C10.7402 16.5098 10.462 16.625 10.1719 16.625ZM12.0006 11.0469C11.1141 11.6419 10.9922 12.1871 10.9922 12.6875C10.9922 12.8906 10.9115 13.0853 10.7679 13.2289C10.6244 13.3725 10.4296 13.4531 10.2266 13.4531C10.0235 13.4531 9.82877 13.3725 9.68518 13.2289C9.5416 13.0853 9.46094 12.8906 9.46094 12.6875C9.46094 11.4893 10.0122 10.5366 11.1464 9.77484C12.2008 9.06719 12.7969 8.61875 12.7969 7.63273C12.7969 6.96227 12.4141 6.45312 11.6216 6.07633C11.4352 5.98773 11.0201 5.90133 10.5093 5.90734C9.86836 5.91555 9.3707 6.06867 8.98734 6.37711C8.26437 6.95898 8.20312 7.59227 8.20312 7.60156C8.19828 7.70211 8.17367 7.80071 8.13072 7.89174C8.08776 7.98278 8.0273 8.06446 7.95278 8.13213C7.87825 8.1998 7.79113 8.25212 7.69639 8.28612C7.60164 8.32012 7.50113 8.33512 7.40059 8.33027C7.30004 8.32543 7.20144 8.30082 7.1104 8.25787C7.01937 8.21491 6.93769 8.15445 6.87002 8.07992C6.80235 8.0054 6.75003 7.91828 6.71603 7.82354C6.68203 7.72879 6.66703 7.62828 6.67188 7.52773C6.67789 7.39484 6.77031 6.19773 8.02648 5.18711C8.67781 4.6632 9.50633 4.39086 10.4874 4.37883C11.182 4.37063 11.8344 4.4882 12.2768 4.69711C13.6008 5.32328 14.3281 6.36727 14.3281 7.63273C14.3281 9.48281 13.0916 10.3135 12.0006 11.0469Z" fill="var(--primary)"/>
                </svg>
            </div>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M20.6287 15.9989C20.5509 15.9052 20.4745 15.8114 20.3995 15.7209C19.3683 14.4736 18.7444 13.7208 18.7444 10.1897C18.7444 8.36156 18.307 6.86156 17.445 5.73656C16.8094 4.90547 15.9501 4.275 14.8176 3.80906C14.8031 3.80096 14.7901 3.79032 14.7792 3.77766C14.3719 2.41359 13.2572 1.5 12 1.5C10.7428 1.5 9.62858 2.41359 9.22123 3.77625C9.21036 3.78845 9.19753 3.79875 9.18327 3.80672C6.54045 4.89469 5.25608 6.98203 5.25608 10.1883C5.25608 13.7208 4.63311 14.4736 3.60092 15.7195C3.52592 15.81 3.44952 15.9019 3.37171 15.9975C3.17071 16.2399 3.04336 16.5348 3.00473 16.8473C2.9661 17.1598 3.01781 17.4769 3.15374 17.7609C3.44296 18.3703 4.05936 18.7486 4.76296 18.7486H19.2422C19.9425 18.7486 20.5547 18.3708 20.8448 17.7642C20.9813 17.4801 21.0335 17.1628 20.9952 16.8499C20.9569 16.537 20.8297 16.2417 20.6287 15.9989V15.9989Z" fill="var(--primary)"/>
            <path d="M12 22.5C12.6774 22.4994 13.342 22.3156 13.9233 21.9679C14.5046 21.6202 14.981 21.1217 15.3019 20.5252C15.317 20.4966 15.3245 20.4646 15.3236 20.4322C15.3226 20.3999 15.3134 20.3684 15.2967 20.3407C15.28 20.313 15.2564 20.2901 15.2282 20.2742C15.2001 20.2583 15.1683 20.25 15.1359 20.25H8.86501C8.83263 20.2499 8.80077 20.2582 8.77254 20.274C8.74431 20.2899 8.72066 20.3128 8.70391 20.3405C8.68715 20.3682 8.67786 20.3997 8.67693 20.4321C8.676 20.4645 8.68346 20.4965 8.6986 20.5252C9.01948 21.1216 9.49578 21.6201 10.077 21.9678C10.6582 22.3155 11.3227 22.4994 12 22.5Z" fill="var(--primary)"/>
        </svg> -->

        <!-- Dark Mode -->
        <!-- <div class="user-menu__modal-item">
            <p class="user-menu__modal-item-title" [ngClass]="{light: (1 | colourTheme) === false}">{{ 'header.userMenu.darkMode' | translate }}</p>
            <label class="user-menu__modal-switch" [ngClass]="{light: (1 | colourTheme) === false}">
                <input type="checkbox" (click)="toggleDarkMode($event)" />
                <span class="slider" [ngClass]="{light: (1 | colourTheme) === false}"></span>
            </label>
        </div> -->
        <!-- </div> -->
    </div>
</button>
