<div class="form-input" [ngClass]="{ long: isLong }">
    <mat-form-field appearance="outline">
        <mat-label>{{ title }}</mat-label>
        <span v-if="showSearch" matPrefix> </span>
        <input
            matInput
            type="text"
            [(ngModel)]="value"
            [formControl]="formControl"
            [name]="nameField()"
            autocomplete="off"
            [ngClass]="{ light: (1 | colourTheme) === false }"
        />
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="svg">
            <path
                d="M15.5 13.9999H14.71L14.43 13.7299C15.63 12.3299 16.25 10.4199 15.91 8.38989C15.44 5.60989 13.12 3.38989 10.32 3.04989C6.09001 2.52989 2.53002 6.08989 3.05002 10.3199C3.39002 13.1199 5.61002 15.4399 8.39002 15.9099C10.42 16.2499 12.33 15.6299 13.73 14.4299L14 14.7099V15.4999L18.25 19.7499C18.66 20.1599 19.33 20.1599 19.74 19.7499C20.15 19.3399 20.15 18.6699 19.74 18.2599L15.5 13.9999ZM9.50002 13.9999C7.01002 13.9999 5.00002 11.9899 5.00002 9.49989C5.00002 7.00989 7.01002 4.99989 9.50002 4.99989C11.99 4.99989 14 7.00989 14 9.49989C14 11.9899 11.99 13.9999 9.50002 13.9999Z"
                fill="#5A678A"
            />
        </svg>
    </mat-form-field>
</div>
