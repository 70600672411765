import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment';
import { lastValueFrom, Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import {
    Insurance,
    PersonalInformation,
    Originator,
    RoleType,
    Company,
    Salary,
    Role,
    TransactionType,
    Person,
    Assignee,
    WhoAmI,
    SimulationCertificate,
    SimulationPurchase,
    Transaction,
    AddressList,
    EmployerTerminationTransaction,
    EmployerMutationSalaryTransaction,
    EmployeeMutationAddressTransaction,
} from 'models';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarService } from 'src/app/shared/services/snackbar.service';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    private clanMyData: ReplaySubject<Person> = new ReplaySubject<Person>(1);
    private originatorReplay: ReplaySubject<Originator> = new ReplaySubject<Originator>(1);
    private personsListData: Person[];
    private companyPersonsList: ReplaySubject<Person[]> = new ReplaySubject<[]>();
    private selectedCompanies: ReplaySubject<Role[]> = new ReplaySubject<Role[]>(1);
    private allCompanies: ReplaySubject<Role[]> = new ReplaySubject<Role[]>();
    private companyNameMap: ReplaySubject<Map<string, string>> = new ReplaySubject<Map<string, string>>();
    private selectedCompaniesData: Role[];
    private currentUserData: Person;

    constructor(
        private http: HttpClient,
        private translateService: TranslateService,
        private snackBarService: SnackBarService
    ) {
        this.clanMyData.subscribe((user) => (this.currentUserData = user));

        this.currentCompanies.subscribe({
            next: (_) => {
                if (this.personsListData != null) {
                    this.filterPersonsBySelectedCompanies();
                }

                if (typeof this.currentUserData !== 'undefined') {
                    //TODO how to properly handle this Originator when multiple or no companies selected
                    if (this.selectedCompaniesData.length != 0) {
                        let role = this.selectedCompaniesData[0];
                        let originator = new Originator();
                        originator.personId = this.currentUserData.personId;
                        originator.companyId = role.companyId;
                        originator.pensionFundId = role.pensionFundId;
                        originator.foundationId = role.foundationId;
                        this.originatorReplay.next(originator);
                    }
                }
            },
        });
    }

    get currentUser(): Observable<Person> {
        return this.clanMyData.asObservable();
    }

    get $companyPersonsList(): Observable<Person[]> {
        return this.companyPersonsList.asObservable();
    }

    get $companyNameMap(): Observable<Map<string, string>> {
        return this.companyNameMap.asObservable();
    }

    get $userData(): Observable<PersonalInformation> {
        return this.currentUser.pipe(map((clanMyData) => clanMyData.personalInformation));
    }

    get $insurances(): Observable<Insurance[]> {
        return this.currentUser.pipe(map((clanMyData) => clanMyData.insurances));
    }

    get currentCompanies(): Observable<Role[]> {
        return this.selectedCompanies.asObservable();
    }

    get $allCompanies(): Observable<Role[]> {
        return this.allCompanies.asObservable();
    }

    get currentOriginator(): Observable<Originator> {
        return this.originatorReplay.asObservable();
    }
    get apiUrl(): string {
        return sessionStorage.getItem('api-url');
    }
    // company(): Observable<Company> {
    //     return this.http.get(`${this.apiUrl}/companies/${this.companyId}`);
    // }

    private getAllCompanies() {
        return this.http.get<Company[]>(`${this.apiUrl}/companies`);
    }

    getCurrentPerson() {
        return this.http.get<Person>(`${this.apiUrl}/mydata`);
    }
    private getSimulationCertificate() {
        return this.http.post<SimulationCertificate>(`${this.apiUrl}/simulations/certificate`, null);
    }

    private getAllPersonsOfAllCompanies() {
        return this.http.get<Person[]>(`${this.apiUrl}/persons/companyId`);
    }

    // private async getPersonsByCompanyId() {
    //     await this.getData();
    //     return this.http.get<Person[]>(`${this.apiUrl}/persons/companyId?companyId=${this.companyId}&foundationId=${this.foundationId}&pensionFundId=${this.pensionFundId}`);
    // }

    private createEmployeeTransaction(body): Observable<Transaction> {
        return this.http.post<Transaction>(`${this.apiUrl}/transactions`, body);
    }

    openSimulationCertificate(): void {
        this.getSimulationCertificate().subscribe((res) => {
            const link = document.createElement('a');
            link.href = `data:${res.Dokument[0].MimeType};base64,${res.Dokument[0].Content}`;
            link.download = res.Dokument[0].Name;
            link.click();
            link.remove();
        });
    }

    //TODO: not sure how this works??
    async getData(): Promise<Person> {
        let data = await lastValueFrom(this.getCurrentPerson());

        this.clanMyData.next(data);
        this.getCurrentPerson().subscribe({
            next: (myData) => {
                this.clanMyData.next(myData);
                this.selectedCompaniesData = myData.roles.filter((role) => role.roleType == RoleType.EMPLOYER);
                this.allCompanies.next(Array.from(this.selectedCompaniesData));
                this.selectedCompanies.next(this.selectedCompaniesData);
            },
            error: (error) => {
                const errMessage = this.translateService.instant('auth.errors.user.hasNoPermission');
                this.snackBarService.showErrorAlert(errMessage);
            },
        });
        this.getPersons();
        this.getAllCompanies().subscribe({
            next: (companies) => {
                let map: Map<string, string> = new Map<string, string>();
                for (const company of companies) {
                    map.set(company.companyId, company.name);
                }
                this.companyNameMap.next(map);
            },
        });
        return data;
    }

    public removeSelectedCompany(role: Role): void {
        const i = this.selectedCompaniesData.indexOf(role);
        this.selectedCompaniesData.splice(i, 1);
        this.selectedCompanies.next(Array.from(this.selectedCompaniesData));
    }

    public addSelectedCompany(role: Role): void {
        this.selectedCompaniesData.push(role);
        this.selectedCompanies.next(Array.from(this.selectedCompaniesData));
    }

    //TODO if multiple insurances update this
    private filterPersonsBySelectedCompanies(): void {
        let persons: Person[] = [];
        if (Array.isArray(this.selectedCompaniesData)) {
            for (const trinity of this.selectedCompaniesData) {
                let trinitypersons = this.personsListData.filter(
                    (person) =>
                        person.insurances[0].foundationId == trinity.foundationId &&
                        person.insurances[0].pensionFundId == trinity.pensionFundId &&
                        person.insurances[0].companyId == trinity.companyId
                );
                persons = trinitypersons.concat(persons);
            }
        }

        this.companyPersonsList.next(persons);
    }

    getPersons(): void {
        this.getAllPersonsOfAllCompanies().subscribe({
            next: (persons) => {
                this.personsListData = persons;
                this.filterPersonsBySelectedCompanies();
            },
            error: (error) => {
                this.companyPersonsList.next(null);
            },
        });
    }

    createMutationAddressTransaction(person: Person): Observable<EmployeeMutationAddressTransaction> {
        const body = new EmployeeMutationAddressTransaction();
        body.originator = new Originator();
        body.originator.personId = person.personId;
        body.originator.companyId = person.insurances[0].companyId;
        body.originator.pensionFundId = person.insurances[0].pensionFundId;
        body.originator.foundationId = person.insurances[0].foundationId;
        body.assignee = Assignee.PVCLAN;
        body.data = new AddressList();
        body.data.addresses = person.personalInformation.addresses;
        return this.createEmployeeTransaction(body).pipe(
            map((t: Transaction) => t as EmployeeMutationAddressTransaction)
        );
    }

    changeSalaryTransaction(person: Person, newSalary: Salary): Observable<EmployerMutationSalaryTransaction> {
        const body = {
            originator: {
                personId: person.personId,
                companyId: person.insurances[0].companyId,
                pensionFundId: person.insurances[0].pensionFundId,
                foundationId: person.insurances[0].foundationId,
            },
            transactionId: '',
            transactionType: TransactionType.EMPLOYER_MUTATION_SALARY_NEW,
            assignee: Assignee.PVCLAN,
            data: {
                salaries: [newSalary],
            },
        };

        return this.createEmployeeTransaction(body).pipe(
            map((t: Transaction) => t as EmployerMutationSalaryTransaction)
        );
    }

    terminateUserTransaction(person: Person, date: string): Observable<EmployerTerminationTransaction> {
        const body = {
            originator: {
                personId: person.personId,
                companyId: person.insurances[0].companyId,
                pensionFundId: person.insurances[0].pensionFundId,
                foundationId: person.insurances[0].foundationId,
            },
            transactionType: TransactionType.EMPLOYER_TERMINATION,
            assignee: Assignee.PVCLAN,
            data: {
                insurances: [
                    {
                        plan: person.insurances[0].plan,
                        companyId: person.insurances[0].companyId,
                        pensionFundId: person.insurances[0].pensionFundId,
                        foundationId: person.insurances[0].foundationId,
                        insuranceTermination: date,
                        employmentTermination: date,
                        terminationReason: 1,
                    },
                ],
            },
        };
        return this.createEmployeeTransaction(body).pipe(map((t: Transaction) => t as EmployerTerminationTransaction));
    }
}
