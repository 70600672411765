import { Pipe, PipeTransform } from '@angular/core';
import { splitNumberWithComma } from '../functions';

@Pipe({
    name: 'splitPhone',
    pure: false,
})
export class SplitPhonePipe implements PipeTransform {
    transform(value: string): string {
        let res = '';
        value.split('').forEach((el, index) => {
            if (index === 3 || index === 5 || index === 8 || index === 10) {
                res += ' ';
            }
            res += el;
        });
        return res;
    }
}
