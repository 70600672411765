<div class="colapseButtonWithoutNavigation" *ngIf="!isOpen" (click)="menuOpen()">
    <svg class="rotated" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
            class="svgActive"
            d="M2.25 12C2.25 17.3845 6.61547 21.75 12 21.75C17.3845 21.75 21.75 17.3845 21.75 12C21.75 6.61547 17.3845 2.25 12 2.25C6.61547 2.25 2.25 6.61547 2.25 12ZM12.218 7.7175C12.2879 7.78687 12.3435 7.86934 12.3816 7.96019C12.4196 8.05105 12.4394 8.14851 12.4398 8.24702C12.4402 8.34552 12.4212 8.44314 12.3838 8.5343C12.3465 8.62545 12.2916 8.70836 12.2222 8.77828L9.76969 11.25H16.0312C16.2302 11.25 16.4209 11.329 16.5616 11.4697C16.7022 11.6103 16.7812 11.8011 16.7812 12C16.7812 12.1989 16.7022 12.3897 16.5616 12.5303C16.4209 12.671 16.2302 12.75 16.0312 12.75H9.76969L12.2222 15.2217C12.2916 15.2917 12.3465 15.3747 12.3838 15.4659C12.4211 15.5571 12.4401 15.6548 12.4396 15.7533C12.4392 15.8519 12.4194 15.9494 12.3812 16.0402C12.3431 16.1311 12.2875 16.2136 12.2175 16.283C12.1475 16.3523 12.0645 16.4073 11.9733 16.4446C11.8821 16.4819 11.7845 16.5009 11.6859 16.5004C11.5874 16.5 11.4899 16.4801 11.399 16.442C11.3081 16.4039 11.2256 16.3483 11.1562 16.2783L7.43484 12.5283C7.29544 12.3878 7.21722 12.1979 7.21722 12C7.21722 11.8021 7.29544 11.6122 7.43484 11.4717L11.1562 7.72172C11.2256 7.65164 11.3082 7.59593 11.3991 7.55778C11.49 7.51964 11.5876 7.4998 11.6862 7.49941C11.7849 7.49902 11.8826 7.51808 11.9738 7.5555C12.0651 7.59292 12.148 7.64797 12.218 7.7175Z"
            fill="var(--primary)"
        />
    </svg>
    {{ 'cabinet.navigation.menu' | translate }}
</div>

<div class="nav-menu-tablet" *ngIf="isOpen">
    <div class="menuColapseButtonTablet">
        <li class="menu-list__item">
            <a (click)="menuOpen()" [ngClass]="{ light: (1 | colourTheme) === false }">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                        class="svgActive"
                        d="M2.25 12C2.25 17.3845 6.61547 21.75 12 21.75C17.3845 21.75 21.75 17.3845 21.75 12C21.75 6.61547 17.3845 2.25 12 2.25C6.61547 2.25 2.25 6.61547 2.25 12ZM12.218 7.7175C12.2879 7.78687 12.3435 7.86934 12.3816 7.96019C12.4196 8.05105 12.4394 8.14851 12.4398 8.24702C12.4402 8.34552 12.4212 8.44314 12.3838 8.5343C12.3465 8.62545 12.2916 8.70836 12.2222 8.77828L9.76969 11.25H16.0312C16.2302 11.25 16.4209 11.329 16.5616 11.4697C16.7022 11.6103 16.7812 11.8011 16.7812 12C16.7812 12.1989 16.7022 12.3897 16.5616 12.5303C16.4209 12.671 16.2302 12.75 16.0312 12.75H9.76969L12.2222 15.2217C12.2916 15.2917 12.3465 15.3747 12.3838 15.4659C12.4211 15.5571 12.4401 15.6548 12.4396 15.7533C12.4392 15.8519 12.4194 15.9494 12.3812 16.0402C12.3431 16.1311 12.2875 16.2136 12.2175 16.283C12.1475 16.3523 12.0645 16.4073 11.9733 16.4446C11.8821 16.4819 11.7845 16.5009 11.6859 16.5004C11.5874 16.5 11.4899 16.4801 11.399 16.442C11.3081 16.4039 11.2256 16.3483 11.1562 16.2783L7.43484 12.5283C7.29544 12.3878 7.21722 12.1979 7.21722 12C7.21722 11.8021 7.29544 11.6122 7.43484 11.4717L11.1562 7.72172C11.2256 7.65164 11.3082 7.59593 11.3991 7.55778C11.49 7.51964 11.5876 7.4998 11.6862 7.49941C11.7849 7.49902 11.8826 7.51808 11.9738 7.5555C12.0651 7.59292 12.148 7.64797 12.218 7.7175Z"
                        fill="var(--primary)"
                    />
                </svg>
                <div class="navText">{{ 'cabinet.navigation.closeMenu' | translate }}</div>
            </a>
        </li>
    </div>
    <div class="companySelectionContainer">
        <div *ngIf="!logoUrl" class="logo">
            <img src="../../../../../assets/img/clanLogo/swarm_logo_svg.png" alt="logo" />
        </div>
        <div *ngIf="logoUrl" class="logo ak">
            <img class="ak-logo" [src]="logoUrl" alt="logo" />
        </div>
        <div class="dropDown" *ngIf="companyNameMap && !showAK">
            <mat-form-field appearance="standard">
                <mat-label>{{ 'cabinet.companies' | translate }}</mat-label>
                <mat-select [formControl]="companyForm" multiple>
                    <mat-select-trigger>
                        {{companyNameMap.get(companyForm.value?.[0]?.companyId)}}
                        <span *ngIf="(companyForm.value?.length || 0) > 1" class="example-additional-selection">
                            (+{{ (companyForm.value?.length || 0) - 1 }}
                            {{ (companyForm.value?.length === 2 ? 'cabinet.other' : 'cabinet.others') | translate }})
                        </span>
                    </mat-select-trigger>
                    <mat-option
                        (onSelectionChange)="changeCompany($event)"
                        *ngFor="let company of trinities"
                        [value]="company"
                        >{{ companyNameMap.get(company.companyId) }}</mat-option
                    >
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <ul class="nav-menu__list menu-list">
        <li class="menu-list__item">
            <a
                routerLink="/home"
                routerLinkActive="active"
                [ngClass]="{ light: (1 | colourTheme) === false }"
                (click)="menuOpen()"
            >
                <div class="navText">{{ 'cabinet.navigation.home' | translate }}</div>
            </a>
        </li>
        <li class="menu-list__item" *ngIf="showCabinet">
            <a
                routerLink="/cabinet/insurances"
                routerLinkActive="active"
                [ngClass]="{ light: (1 | colourTheme) === false }"
                (click)="menuOpen()"
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                        class="svgActive"
                        d="M15.7501 12C14.7863 12 13.8545 11.5697 13.1251 10.7888C12.4159 10.027 11.9827 9.01125 11.9063 7.92938C11.8248 6.77531 12.1768 5.71406 12.8973 4.94063C13.6177 4.16719 14.6251 3.75 15.7501 3.75C16.8671 3.75 17.8773 4.17469 18.5954 4.94625C19.3206 5.72531 19.6735 6.78469 19.592 7.92891C19.5137 9.01219 19.081 10.0275 18.3732 10.7883C17.6457 11.5697 16.7143 12 15.7501 12Z"
                        fill="var(--primary)"
                    />
                    <path
                        class="svgActive"
                        d="M21.9295 20.25H9.5709C9.3722 20.2511 9.17591 20.2065 8.99713 20.1198C8.81834 20.0331 8.66184 19.9065 8.53965 19.7498C8.41004 19.58 8.32054 19.383 8.27784 19.1736C8.23514 18.9643 8.24036 18.748 8.29309 18.5409C8.68778 16.9561 9.66559 15.6417 11.1206 14.7403C12.412 13.9406 14.0559 13.5 15.75 13.5C17.4773 13.5 19.0781 13.9219 20.377 14.7211C21.8353 15.6178 22.8145 16.9397 23.2073 18.5438C23.2594 18.751 23.264 18.9673 23.2208 19.1765C23.1777 19.3857 23.0878 19.5825 22.9579 19.7522C22.8359 19.9082 22.6797 20.0341 22.5014 20.1204C22.3232 20.2067 22.1275 20.251 21.9295 20.25Z"
                        fill="var(--primary)"
                    />
                    <path
                        class="svgActive"
                        d="M6.89067 12.1875C5.24115 12.1875 3.79083 10.6538 3.6563 8.76891C3.58974 7.80328 3.89068 6.91031 4.50005 6.25547C5.10286 5.60719 5.95318 5.25 6.89067 5.25C7.82817 5.25 8.67192 5.60906 9.27802 6.26109C9.89208 6.92109 10.1921 7.81219 10.1218 8.76984C9.98723 10.6542 8.53739 12.1875 6.89067 12.1875Z"
                        fill="var(--primary)"
                    />
                    <path
                        class="svgActive"
                        d="M9.96839 13.6617C9.14386 13.2586 8.0737 13.057 6.89104 13.057C5.51011 13.057 4.16901 13.417 3.11432 14.0705C1.91854 14.8125 1.11417 15.893 0.789322 17.1975C0.741783 17.3852 0.737287 17.5811 0.776169 17.7707C0.815051 17.9604 0.896305 18.1387 1.01385 18.2925C1.12539 18.4357 1.26828 18.5514 1.43154 18.6307C1.5948 18.71 1.77407 18.7508 1.95557 18.75H7.1587C7.24652 18.75 7.33155 18.7192 7.39897 18.6629C7.46638 18.6066 7.51191 18.5285 7.52761 18.4421C7.53276 18.4125 7.53933 18.383 7.54683 18.3539C7.94433 16.7574 8.87573 15.4083 10.252 14.4249C10.3026 14.3884 10.3433 14.3398 10.3704 14.2836C10.3976 14.2274 10.4102 14.1654 10.4073 14.103C10.4044 14.0407 10.3859 13.9801 10.3536 13.9267C10.3214 13.8733 10.2763 13.8288 10.2225 13.7972C10.1489 13.7541 10.0645 13.7086 9.96839 13.6617Z"
                        fill="var(--primary)"
                    />
                </svg>
                <div class="navText">{{ 'cabinet.navigation.insurances' | translate }}</div>
            </a>
        </li>
        <li class="menu-list__item" *ngIf="showCabinet">
            <a
                routerLink="/cabinet/mutations"
                routerLinkActive="active"
                [ngClass]="{ light: (1 | colourTheme) === false }"
                (click)="menuOpen()"
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                        class="svgActive"
                        d="M22.2028 9.84374C21.5466 9.35717 20.7403 8.99999 19.8891 8.80733C19.7493 8.77589 19.6214 8.70533 19.5202 8.60391C19.419 8.50248 19.3488 8.37436 19.3177 8.23452C18.952 6.60561 18.165 5.20874 17.0081 4.1503C15.6633 2.91796 13.8848 2.24014 12 2.24014C10.343 2.24014 8.8125 2.75952 7.57641 3.74249C6.73867 4.40973 6.06434 5.25945 5.60484 6.22686C5.55503 6.33271 5.48114 6.42544 5.38908 6.49763C5.29703 6.56983 5.18936 6.61949 5.07469 6.64264C3.82078 6.89577 2.68875 7.43342 1.82437 8.19514C0.630937 9.24983 0 10.6519 0 12.2526C0 13.8708 0.679219 15.3464 1.91203 16.4133C3.08953 17.43 4.67484 17.9901 6.375 17.9901H11.25V10.8009L9.53016 12.5208C9.45835 12.5926 9.37272 12.649 9.27845 12.6867C9.18418 12.7245 9.08323 12.7426 8.98172 12.7402C8.88021 12.7377 8.78026 12.7147 8.68792 12.6725C8.59558 12.6302 8.51278 12.5697 8.44453 12.4945C8.17266 12.1959 8.19891 11.7305 8.48438 11.445L11.4698 8.45999C11.6105 8.31944 11.8012 8.24049 12 8.24049C12.1988 8.24049 12.3895 8.31944 12.5302 8.45999L15.5156 11.444C15.81 11.7389 15.8269 12.2222 15.5339 12.518C15.4644 12.5882 15.3817 12.6439 15.2906 12.6821C15.1995 12.7202 15.1017 12.74 15.0029 12.7403C14.9041 12.7405 14.8063 12.7213 14.715 12.6836C14.6236 12.6459 14.5407 12.5906 14.4708 12.5208L12.75 10.8009V17.9901H18.5625C20.0316 17.9901 21.3708 17.5776 22.3336 16.829C23.4239 15.9806 24 14.7665 24 13.3214C24 11.918 23.3784 10.7147 22.2028 9.84374Z"
                        fill="var(--primary)"
                    />
                    <path
                        class="svgActive"
                        d="M11.25 21.0098C11.25 21.2088 11.329 21.3995 11.4697 21.5402C11.6103 21.6808 11.8011 21.7598 12 21.7598C12.1989 21.7598 12.3897 21.6808 12.5303 21.5402C12.671 21.3995 12.75 21.2088 12.75 21.0098V17.9902H11.25V21.0098Z"
                        fill="var(--primary)"
                    />
                </svg>
                <div class="navText">{{ 'cabinet.navigation.fileupload' | translate }}</div>
            </a>
        </li>
        <li class="menu-list__item" *ngIf="showCabinet">
            <a
                routerLink="/cabinet/mutationHistory"
                routerLinkActive="active"
                [ngClass]="{ light: (1 | colourTheme) === false }"
                (click)="menuOpen()"
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path
                        class="svgActive"
                        d="M10 0.25C4.61547 0.25 0.25 4.61547 0.25 10C0.25 15.3845 4.61547 19.75 10 19.75C15.3845 19.75 19.75 15.3845 19.75 10C19.75 4.61547 15.3845 0.25 10 0.25ZM14.5 11.5H10C9.80109 11.5 9.61032 11.421 9.46967 11.2803C9.32902 11.1397 9.25 10.9489 9.25 10.75V4C9.25 3.80109 9.32902 3.61032 9.46967 3.46967C9.61032 3.32902 9.80109 3.25 10 3.25C10.1989 3.25 10.3897 3.32902 10.5303 3.46967C10.671 3.61032 10.75 3.80109 10.75 4V10H14.5C14.6989 10 14.8897 10.079 15.0303 10.2197C15.171 10.3603 15.25 10.5511 15.25 10.75C15.25 10.9489 15.171 11.1397 15.0303 11.2803C14.8897 11.421 14.6989 11.5 14.5 11.5Z"
                        fill="var(--primary)"
                    />
                </svg>
                <div class="navText">{{ 'cabinet.navigation.mutationHistory' | translate }}</div>
            </a>
        </li>
        <!-- <li class="menu-list__item">
              <a routerLink="/cabinet/wage" routerLinkActive="active" [ngClass]="{ light: (1 | colourTheme) === false }">
                  {{ 'cabinet.navigation.wage' | translate }}
              </a>
          </li> -->
        <li class="menu-list__item" *ngIf="showCabinet">
            <a
                routerLink="/cabinet/invoices"
                routerLinkActive="active"
                [ngClass]="{ light: (1 | colourTheme) === false }"
                (click)="menuOpen()"
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                        class="svgActive"
                        d="M20.0625 10.5H13.5C12.9033 10.5 12.331 10.2629 11.909 9.84099C11.4871 9.41903 11.25 8.84674 11.25 8.25V1.6875C11.25 1.63777 11.2302 1.59008 11.1951 1.55492C11.1599 1.51975 11.1122 1.5 11.0625 1.5H6.75C5.95435 1.5 5.19129 1.81607 4.62868 2.37868C4.06607 2.94129 3.75 3.70435 3.75 4.5V19.5C3.75 20.2956 4.06607 21.0587 4.62868 21.6213C5.19129 22.1839 5.95435 22.5 6.75 22.5H17.25C18.0456 22.5 18.8087 22.1839 19.3713 21.6213C19.9339 21.0587 20.25 20.2956 20.25 19.5V10.6875C20.25 10.6378 20.2302 10.5901 20.1951 10.5549C20.1599 10.5198 20.1122 10.5 20.0625 10.5ZM15.75 18H8.25C8.05109 18 7.86032 17.921 7.71967 17.7803C7.57902 17.6397 7.5 17.4489 7.5 17.25C7.5 17.0511 7.57902 16.8603 7.71967 16.7197C7.86032 16.579 8.05109 16.5 8.25 16.5H15.75C15.9489 16.5 16.1397 16.579 16.2803 16.7197C16.421 16.8603 16.5 17.0511 16.5 17.25C16.5 17.4489 16.421 17.6397 16.2803 17.7803C16.1397 17.921 15.9489 18 15.75 18ZM15.75 14.25H8.25C8.05109 14.25 7.86032 14.171 7.71967 14.0303C7.57902 13.8897 7.5 13.6989 7.5 13.5C7.5 13.3011 7.57902 13.1103 7.71967 12.9697C7.86032 12.829 8.05109 12.75 8.25 12.75H15.75C15.9489 12.75 16.1397 12.829 16.2803 12.9697C16.421 13.1103 16.5 13.3011 16.5 13.5C16.5 13.6989 16.421 13.8897 16.2803 14.0303C16.1397 14.171 15.9489 14.25 15.75 14.25Z"
                        fill="var(--primary)"
                    />
                    <path
                        class="svgActive"
                        d="M19.6509 8.84015L12.9098 2.09906C12.8967 2.08602 12.8801 2.07716 12.8619 2.07357C12.8438 2.06999 12.825 2.07184 12.8079 2.07891C12.7908 2.08597 12.7762 2.09793 12.7659 2.11327C12.7556 2.12862 12.7501 2.14666 12.75 2.16515V8.24999C12.75 8.4489 12.829 8.63967 12.9697 8.78032C13.1103 8.92098 13.3011 8.99999 13.5 8.99999H19.5848C19.6033 8.99992 19.6214 8.99438 19.6367 8.98407C19.6521 8.97376 19.664 8.95915 19.6711 8.94207C19.6781 8.92498 19.68 8.9062 19.6764 8.88806C19.6728 8.86993 19.664 8.85326 19.6509 8.84015Z"
                        fill="var(--primary)"
                    />
                </svg>
                <div class="navText">{{ 'cabinet.navigation.invoices' | translate }}</div>
            </a>
        </li>

        <div class="navDropDown" *ngIf="showAK" [@openCloseNav]="isOpenNav ? 'openNav' : 'closedNav'">
            <li class="menu-list__item buttonToOpenDropDownInNav">
                <a [ngClass]="{ light: (1 | colourTheme) === false }" (click)="menuSlideOpen()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <g clip-path="url(#clip0_1675_76238)">
                            <path
                                d="M4.875 23.25H3.375C3.07663 23.25 2.79048 23.1315 2.5795 22.9205C2.36853 22.7095 2.25 22.4234 2.25 22.125V15.375C2.25 15.0766 2.36853 14.7905 2.5795 14.5795C2.79048 14.3685 3.07663 14.25 3.375 14.25H4.875C5.17337 14.25 5.45952 14.3685 5.6705 14.5795C5.88147 14.7905 6 15.0766 6 15.375V22.125C6 22.4234 5.88147 22.7095 5.6705 22.9205C5.45952 23.1315 5.17337 23.25 4.875 23.25Z"
                                fill="var(--primary)"
                            />
                            <path
                                d="M15.375 23.25H13.875C13.5766 23.25 13.2905 23.1315 13.0795 22.9205C12.8685 22.7095 12.75 22.4234 12.75 22.125V10.875C12.75 10.5766 12.8685 10.2905 13.0795 10.0795C13.2905 9.86854 13.5766 9.75002 13.875 9.75002H15.375C15.6734 9.75002 15.9595 9.86854 16.1705 10.0795C16.3815 10.2905 16.5 10.5766 16.5 10.875V22.125C16.5 22.4234 16.3815 22.7095 16.1705 22.9205C15.9595 23.1315 15.6734 23.25 15.375 23.25Z"
                                fill="var(--primary)"
                            />
                            <path
                                d="M20.625 23.25H19.125C18.8266 23.25 18.5405 23.1315 18.3295 22.9205C18.1185 22.7095 18 22.4234 18 22.125V5.62502C18 5.32665 18.1185 5.0405 18.3295 4.82952C18.5405 4.61854 18.8266 4.50002 19.125 4.50002H20.625C20.9234 4.50002 21.2095 4.61854 21.4205 4.82952C21.6315 5.0405 21.75 5.32665 21.75 5.62502V22.125C21.75 22.4234 21.6315 22.7095 21.4205 22.9205C21.2095 23.1315 20.9234 23.25 20.625 23.25Z"
                                fill="var(--primary)"
                            />
                            <path
                                d="M10.125 23.25H8.625C8.32663 23.25 8.04048 23.1315 7.8295 22.9205C7.61853 22.7095 7.5 22.4234 7.5 22.125V1.87502C7.5 1.57665 7.61853 1.2905 7.8295 1.07952C8.04048 0.868542 8.32663 0.750015 8.625 0.750015H10.125C10.4234 0.750015 10.7095 0.868542 10.9205 1.07952C11.1315 1.2905 11.25 1.57665 11.25 1.87502V22.125C11.25 22.4234 11.1315 22.7095 10.9205 22.9205C10.7095 23.1315 10.4234 23.25 10.125 23.25Z"
                                fill="var(--primary)"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_1675_76238">
                                <rect width="24" height="24" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                    <div class="navText">{{ 'cabinet.navigation.ak' | translate }}</div>
                    <svg
                        _ngcontent-jwx-c158=""
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="8"
                        viewBox="0 0 12 8"
                        fill="none"
                        [ngClass]="isOpenNav ? 'rotated' : ''"
                    >
                        <path
                            _ngcontent-jwx-c158=""
                            d="M2.11997 1.28957L5.99997 5.16957L9.87997 1.28957C10.27 0.89957 10.9 0.89957 11.29 1.28957C11.68 1.67957 11.68 2.30957 11.29 2.69957L6.69997 7.28957C6.30997 7.67957 5.67997 7.67957 5.28997 7.28957L0.699971 2.69957C0.309971 2.30957 0.309971 1.67957 0.699971 1.28957C1.08997 0.90957 1.72997 0.89957 2.11997 1.28957Z"
                            fill="#04050E"
                        ></path>
                    </svg>
                </a>
            </li>
            <li class="menu-list__item buttonInDropDownNav">
                <a
                    routerLink="/employeeFund/cashRegister"
                    routerLinkActive="active"
                    class="link"
                    [ngClass]="{ light: (1 | colourTheme) === false }"
                    (click)="menuOpen()"
                >
                    <svg
                        _ngcontent-clx-c235=""
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 26 25"
                        fill="var(--primary)"
                    >
                        <path
                            _ngcontent-clx-c235=""
                            class="svgActive"
                            d="M2.07979 9.61911C0.442588 11.2165 0.988367 12.2814 3.17121 12.2814"
                            stroke="var(--primary)"
                        ></path>
                        <path
                            _ngcontent-clx-c235=""
                            class="svgActive"
                            d="M6.44554 20.8007C3.17125 18.1384 2.62548 15.4761 3.17127 12.8138C4.91756 5.57244 13.358 5.53694 17.3599 6.42437C19.1063 4.72051 20.9981 4.64952 21.7257 4.827L21.18 8.55419C22.4897 8.98015 23.181 10.5065 23.3629 11.2165H25V17.6059H23.3629C23.3629 18.8838 21.5438 20.2682 20.6343 20.8007C21.18 22.9305 20.0885 23.9954 18.9971 23.9954C17.9056 23.9954 16.8142 23.108 16.2685 22.398C14.0856 23.2499 11.357 22.753 10.2656 22.398C10.0837 22.9305 9.28326 24.4214 7.53696 23.9954C5.79066 23.5694 6.08173 21.5106 6.44554 20.8007Z"
                            stroke="var(--primary)"
                        ></path>
                        <ellipse
                            _ngcontent-clx-c235=""
                            class="svgActiveBlue"
                            cx="19.5428"
                            cy="13.3463"
                            rx="1.09144"
                            ry="1.06491"
                            fill="#fff"
                        ></ellipse>
                        <path
                            _ngcontent-clx-c235=""
                            class="strokeToWhite"
                            d="M8.62829 6.95678C6.36835 5.06679 7.53696 1.63229 9.71984 1.09984C12.4484 0.567405 15.177 2.16472 14.0855 5.89187"
                            stroke="var(--primary)"
                            stroke-width="2px"
                            fill="#fff"
                        ></path>
                    </svg>
                    <div class="navText">{{ 'cabinet.navigation.submenu.ak.cashRegister' | translate }}</div>
                </a>
            </li>

            <li class="menu-list__item buttonInDropDownNav">
                <a
                    routerLink="/employeeFund/cashAccounts"
                    routerLinkActive="active"
                    class="link"
                    [ngClass]="{ light: (1 | colourTheme) === false }"
                    (click)="menuOpen()"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="27" height="25" viewBox="0 0 27 25" fill="none">
                        <path
                            class="svgActiveInDropDown"
                            d="M19.5722 3.16393H25.877M22.7246 0V6.38798M13.623 3.02186L2 14.2623H5.32086V24H10.9786V18.2295H16.1444V24H21.7406V14.2623H25L13.623 3.02186Z"
                            stroke="var(--primary)"
                        />
                    </svg>
                    <div class="navText">{{ 'cabinet.navigation.submenu.ak.cashAccounts' | translate }}</div>
                </a>
            </li>

            <li class="menu-list__item buttonInDropDownNav">
                <a
                    routerLink="/employeeFund/allTransactions"
                    routerLinkActive="active"
                    class="link"
                    [ngClass]="{ light: (1 | colourTheme) === false }"
                    (click)="menuOpen()"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="27" height="25" viewBox="0 0 27 25" fill="none">
                        <path
                            class="svgActiveInDropDown"
                            d="M19.5722 3.16393H25.877M22.7246 0V6.38798M13.623 3.02186L2 14.2623H5.32086V24H10.9786V18.2295H16.1444V24H21.7406V14.2623H25L13.623 3.02186Z"
                            stroke="var(--primary)"
                        />
                    </svg>
                    <div class="navText">{{ 'cabinet.navigation.submenu.ak.allTransactions' | translate }}</div>
                </a>
            </li>

            <li class="menu-list__item buttonInDropDownNav">
                <a
                    routerLink="/employeeFund/painFiles"
                    routerLinkActive="active"
                    class="link"
                    [ngClass]="{ light: (1 | colourTheme) === false }"
                    (click)="menuOpen()"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="27" height="25" viewBox="0 0 27 25" fill="none">
                        <path
                            class="svgActiveInDropDown"
                            d="M19.5722 3.16393H25.877M22.7246 0V6.38798M13.623 3.02186L2 14.2623H5.32086V24H10.9786V18.2295H16.1444V24H21.7406V14.2623H25L13.623 3.02186Z"
                            stroke="var(--primary)"
                        />
                    </svg>
                    <div class="navText">{{ 'cabinet.navigation.submenu.ak.painFiles' | translate }}</div>
                </a>
            </li>
        </div>

        <div class="menuColapseButton">
            <li class="menu-list__item">
                <a (click)="menuOpen()" [ngClass]="{ light: (1 | colourTheme) === false }">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path
                            class="svgActive"
                            d="M2.25 12C2.25 17.3845 6.61547 21.75 12 21.75C17.3845 21.75 21.75 17.3845 21.75 12C21.75 6.61547 17.3845 2.25 12 2.25C6.61547 2.25 2.25 6.61547 2.25 12ZM12.218 7.7175C12.2879 7.78687 12.3435 7.86934 12.3816 7.96019C12.4196 8.05105 12.4394 8.14851 12.4398 8.24702C12.4402 8.34552 12.4212 8.44314 12.3838 8.5343C12.3465 8.62545 12.2916 8.70836 12.2222 8.77828L9.76969 11.25H16.0312C16.2302 11.25 16.4209 11.329 16.5616 11.4697C16.7022 11.6103 16.7812 11.8011 16.7812 12C16.7812 12.1989 16.7022 12.3897 16.5616 12.5303C16.4209 12.671 16.2302 12.75 16.0312 12.75H9.76969L12.2222 15.2217C12.2916 15.2917 12.3465 15.3747 12.3838 15.4659C12.4211 15.5571 12.4401 15.6548 12.4396 15.7533C12.4392 15.8519 12.4194 15.9494 12.3812 16.0402C12.3431 16.1311 12.2875 16.2136 12.2175 16.283C12.1475 16.3523 12.0645 16.4073 11.9733 16.4446C11.8821 16.4819 11.7845 16.5009 11.6859 16.5004C11.5874 16.5 11.4899 16.4801 11.399 16.442C11.3081 16.4039 11.2256 16.3483 11.1562 16.2783L7.43484 12.5283C7.29544 12.3878 7.21722 12.1979 7.21722 12C7.21722 11.8021 7.29544 11.6122 7.43484 11.4717L11.1562 7.72172C11.2256 7.65164 11.3082 7.59593 11.3991 7.55778C11.49 7.51964 11.5876 7.4998 11.6862 7.49941C11.7849 7.49902 11.8826 7.51808 11.9738 7.5555C12.0651 7.59292 12.148 7.64797 12.218 7.7175Z"
                            fill="var(--primary)"
                        />
                    </svg>
                    <div class="navText">{{ 'cabinet.navigation.closeMenu' | translate }}</div>
                </a>
            </li>
        </div>
    </ul>
</div>

<div class="nav-menu">
    <div class="menuColapseButtonTablet">
        <li class="menu-list__item">
            <a (click)="menuOpen()" [ngClass]="{ light: (1 | colourTheme) === false }">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                        class="svgActive"
                        d="M2.25 12C2.25 17.3845 6.61547 21.75 12 21.75C17.3845 21.75 21.75 17.3845 21.75 12C21.75 6.61547 17.3845 2.25 12 2.25C6.61547 2.25 2.25 6.61547 2.25 12ZM12.218 7.7175C12.2879 7.78687 12.3435 7.86934 12.3816 7.96019C12.4196 8.05105 12.4394 8.14851 12.4398 8.24702C12.4402 8.34552 12.4212 8.44314 12.3838 8.5343C12.3465 8.62545 12.2916 8.70836 12.2222 8.77828L9.76969 11.25H16.0312C16.2302 11.25 16.4209 11.329 16.5616 11.4697C16.7022 11.6103 16.7812 11.8011 16.7812 12C16.7812 12.1989 16.7022 12.3897 16.5616 12.5303C16.4209 12.671 16.2302 12.75 16.0312 12.75H9.76969L12.2222 15.2217C12.2916 15.2917 12.3465 15.3747 12.3838 15.4659C12.4211 15.5571 12.4401 15.6548 12.4396 15.7533C12.4392 15.8519 12.4194 15.9494 12.3812 16.0402C12.3431 16.1311 12.2875 16.2136 12.2175 16.283C12.1475 16.3523 12.0645 16.4073 11.9733 16.4446C11.8821 16.4819 11.7845 16.5009 11.6859 16.5004C11.5874 16.5 11.4899 16.4801 11.399 16.442C11.3081 16.4039 11.2256 16.3483 11.1562 16.2783L7.43484 12.5283C7.29544 12.3878 7.21722 12.1979 7.21722 12C7.21722 11.8021 7.29544 11.6122 7.43484 11.4717L11.1562 7.72172C11.2256 7.65164 11.3082 7.59593 11.3991 7.55778C11.49 7.51964 11.5876 7.4998 11.6862 7.49941C11.7849 7.49902 11.8826 7.51808 11.9738 7.5555C12.0651 7.59292 12.148 7.64797 12.218 7.7175Z"
                        fill="var(--primary)"
                    />
                </svg>
                <div class="navText">{{ 'cabinet.navigation.closeMenu' | translate }}</div>
            </a>
        </li>
    </div>
    <div class="companySelectionContainer">
        <div *ngIf="!logoUrl" class="logo">
            <img src="../../../../../assets/img/clanLogo/swarm_logo_svg.png" alt="logo" />
        </div>
        <div *ngIf="logoUrl" class="logo ak">
            <img class="ak-logo" [src]="logoUrl" alt="logo" />
        </div>
        <div class="dropDown" *ngIf="companyNameMap && !showAK">
            <mat-form-field appearance="standard">
                <mat-label>{{ 'cabinet.companies' | translate }}</mat-label>
                <mat-select [formControl]="companyForm" multiple>
                    <mat-select-trigger>
                        {{companyNameMap.get(companyForm.value?.[0]?.companyId)}}
                        <span *ngIf="(companyForm.value?.length || 0) > 1" class="example-additional-selection">
                            (+{{ (companyForm.value?.length || 0) - 1 }}
                            {{ (companyForm.value?.length === 2 ? 'cabinet.other' : 'cabinet.others') | translate }})
                        </span>
                    </mat-select-trigger>
                    <mat-option
                        (onSelectionChange)="changeCompany($event)"
                        *ngFor="let trinity of trinities"
                        [value]="trinity"
                        >{{ companyNameMap.get(trinity.companyId) }}</mat-option
                    >
                </mat-select>
            </mat-form-field>
        </div>
        <!--  <div class="dropDown">
            <span>{{ selectedCompany }}</span>
            <svg class="companySelectionArrow" xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8" fill="none">
              <path d="M2.11997 1.29L5.99997 5.17L9.87997 1.29C10.27 0.899998 10.9 0.899998 11.29 1.29C11.68 1.68 11.68 2.31 11.29 2.7L6.69997 7.29C6.30997 7.68 5.67997 7.68 5.28997 7.29L0.699971 2.7C0.309971 2.31 0.309971 1.68 0.699971 1.29C1.08997 0.909998 1.72997 0.899998 2.11997 1.29Z" fill="#04050E"/>
            </svg>
            <div class="dropDown-content">
              <div class="companySelection" *ngFor="let company of companys">
                <p class="companyObject" (click)="selectCompany(company.company)">{{company.company}}</p>
              </div>
                
            </div>
          </div> -->
    </div>
    <ul class="nav-menu__list menu-list">
        <!-- <li class="menu-list__item">
                <a
                    routerLink="/cabinet/dashboard"
                    routerLinkActive="active"
                    [ngClass]="{ light: (1 | colourTheme) === false }"
                >
                    {{ 'cabinet.navigation.dashboard' | translate }}
                </a>
            </li> -->
        <li class="menu-list__item">
            <a routerLink="/home" routerLinkActive="active" [ngClass]="{ light: (1 | colourTheme) === false }">
                <div class="navText">{{ 'cabinet.navigation.home' | translate }}</div>
            </a>
        </li>
        <li class="menu-list__item" *ngIf="showCabinet">
            <a
                routerLink="/cabinet/insurances"
                routerLinkActive="active"
                [ngClass]="{ light: (1 | colourTheme) === false }"
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                        class="svgActive"
                        d="M15.7501 12C14.7863 12 13.8545 11.5697 13.1251 10.7888C12.4159 10.027 11.9827 9.01125 11.9063 7.92938C11.8248 6.77531 12.1768 5.71406 12.8973 4.94063C13.6177 4.16719 14.6251 3.75 15.7501 3.75C16.8671 3.75 17.8773 4.17469 18.5954 4.94625C19.3206 5.72531 19.6735 6.78469 19.592 7.92891C19.5137 9.01219 19.081 10.0275 18.3732 10.7883C17.6457 11.5697 16.7143 12 15.7501 12Z"
                        fill="var(--primary)"
                    />
                    <path
                        class="svgActive"
                        d="M21.9295 20.25H9.5709C9.3722 20.2511 9.17591 20.2065 8.99713 20.1198C8.81834 20.0331 8.66184 19.9065 8.53965 19.7498C8.41004 19.58 8.32054 19.383 8.27784 19.1736C8.23514 18.9643 8.24036 18.748 8.29309 18.5409C8.68778 16.9561 9.66559 15.6417 11.1206 14.7403C12.412 13.9406 14.0559 13.5 15.75 13.5C17.4773 13.5 19.0781 13.9219 20.377 14.7211C21.8353 15.6178 22.8145 16.9397 23.2073 18.5438C23.2594 18.751 23.264 18.9673 23.2208 19.1765C23.1777 19.3857 23.0878 19.5825 22.9579 19.7522C22.8359 19.9082 22.6797 20.0341 22.5014 20.1204C22.3232 20.2067 22.1275 20.251 21.9295 20.25Z"
                        fill="var(--primary)"
                    />
                    <path
                        class="svgActive"
                        d="M6.89067 12.1875C5.24115 12.1875 3.79083 10.6538 3.6563 8.76891C3.58974 7.80328 3.89068 6.91031 4.50005 6.25547C5.10286 5.60719 5.95318 5.25 6.89067 5.25C7.82817 5.25 8.67192 5.60906 9.27802 6.26109C9.89208 6.92109 10.1921 7.81219 10.1218 8.76984C9.98723 10.6542 8.53739 12.1875 6.89067 12.1875Z"
                        fill="var(--primary)"
                    />
                    <path
                        class="svgActive"
                        d="M9.96839 13.6617C9.14386 13.2586 8.0737 13.057 6.89104 13.057C5.51011 13.057 4.16901 13.417 3.11432 14.0705C1.91854 14.8125 1.11417 15.893 0.789322 17.1975C0.741783 17.3852 0.737287 17.5811 0.776169 17.7707C0.815051 17.9604 0.896305 18.1387 1.01385 18.2925C1.12539 18.4357 1.26828 18.5514 1.43154 18.6307C1.5948 18.71 1.77407 18.7508 1.95557 18.75H7.1587C7.24652 18.75 7.33155 18.7192 7.39897 18.6629C7.46638 18.6066 7.51191 18.5285 7.52761 18.4421C7.53276 18.4125 7.53933 18.383 7.54683 18.3539C7.94433 16.7574 8.87573 15.4083 10.252 14.4249C10.3026 14.3884 10.3433 14.3398 10.3704 14.2836C10.3976 14.2274 10.4102 14.1654 10.4073 14.103C10.4044 14.0407 10.3859 13.9801 10.3536 13.9267C10.3214 13.8733 10.2763 13.8288 10.2225 13.7972C10.1489 13.7541 10.0645 13.7086 9.96839 13.6617Z"
                        fill="var(--primary)"
                    />
                </svg>
                <div class="navText">{{ 'cabinet.navigation.insurances' | translate }}</div>
            </a>
        </li>

        <li class="menu-list__item" *ngIf="showCabinet">
            <a
                routerLink="/cabinet/mutations"
                routerLinkActive="active"
                [ngClass]="{ light: (1 | colourTheme) === false }"
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                        class="svgActive"
                        d="M22.2028 9.84374C21.5466 9.35717 20.7403 8.99999 19.8891 8.80733C19.7493 8.77589 19.6214 8.70533 19.5202 8.60391C19.419 8.50248 19.3488 8.37436 19.3177 8.23452C18.952 6.60561 18.165 5.20874 17.0081 4.1503C15.6633 2.91796 13.8848 2.24014 12 2.24014C10.343 2.24014 8.8125 2.75952 7.57641 3.74249C6.73867 4.40973 6.06434 5.25945 5.60484 6.22686C5.55503 6.33271 5.48114 6.42544 5.38908 6.49763C5.29703 6.56983 5.18936 6.61949 5.07469 6.64264C3.82078 6.89577 2.68875 7.43342 1.82437 8.19514C0.630937 9.24983 0 10.6519 0 12.2526C0 13.8708 0.679219 15.3464 1.91203 16.4133C3.08953 17.43 4.67484 17.9901 6.375 17.9901H11.25V10.8009L9.53016 12.5208C9.45835 12.5926 9.37272 12.649 9.27845 12.6867C9.18418 12.7245 9.08323 12.7426 8.98172 12.7402C8.88021 12.7377 8.78026 12.7147 8.68792 12.6725C8.59558 12.6302 8.51278 12.5697 8.44453 12.4945C8.17266 12.1959 8.19891 11.7305 8.48438 11.445L11.4698 8.45999C11.6105 8.31944 11.8012 8.24049 12 8.24049C12.1988 8.24049 12.3895 8.31944 12.5302 8.45999L15.5156 11.444C15.81 11.7389 15.8269 12.2222 15.5339 12.518C15.4644 12.5882 15.3817 12.6439 15.2906 12.6821C15.1995 12.7202 15.1017 12.74 15.0029 12.7403C14.9041 12.7405 14.8063 12.7213 14.715 12.6836C14.6236 12.6459 14.5407 12.5906 14.4708 12.5208L12.75 10.8009V17.9901H18.5625C20.0316 17.9901 21.3708 17.5776 22.3336 16.829C23.4239 15.9806 24 14.7665 24 13.3214C24 11.918 23.3784 10.7147 22.2028 9.84374Z"
                        fill="var(--primary)"
                    />
                    <path
                        class="svgActive"
                        d="M11.25 21.0098C11.25 21.2088 11.329 21.3995 11.4697 21.5402C11.6103 21.6808 11.8011 21.7598 12 21.7598C12.1989 21.7598 12.3897 21.6808 12.5303 21.5402C12.671 21.3995 12.75 21.2088 12.75 21.0098V17.9902H11.25V21.0098Z"
                        fill="var(--primary)"
                    />
                </svg>
                <div class="navText">{{ 'cabinet.navigation.fileupload' | translate }}</div>
            </a>
        </li>

        <li class="menu-list__item" *ngIf="showCabinet">
            <a
                routerLink="/cabinet/mutationHistory"
                routerLinkActive="active"
                [ngClass]="{ light: (1 | colourTheme) === false }"
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path
                        class="svgActive"
                        d="M10 0.25C4.61547 0.25 0.25 4.61547 0.25 10C0.25 15.3845 4.61547 19.75 10 19.75C15.3845 19.75 19.75 15.3845 19.75 10C19.75 4.61547 15.3845 0.25 10 0.25ZM14.5 11.5H10C9.80109 11.5 9.61032 11.421 9.46967 11.2803C9.32902 11.1397 9.25 10.9489 9.25 10.75V4C9.25 3.80109 9.32902 3.61032 9.46967 3.46967C9.61032 3.32902 9.80109 3.25 10 3.25C10.1989 3.25 10.3897 3.32902 10.5303 3.46967C10.671 3.61032 10.75 3.80109 10.75 4V10H14.5C14.6989 10 14.8897 10.079 15.0303 10.2197C15.171 10.3603 15.25 10.5511 15.25 10.75C15.25 10.9489 15.171 11.1397 15.0303 11.2803C14.8897 11.421 14.6989 11.5 14.5 11.5Z"
                        fill="var(--primary)"
                    />
                </svg>
                <div class="navText">{{ 'cabinet.navigation.mutationHistory' | translate }}</div>
            </a>
        </li>
        <li class="menu-list__item" *ngIf="showCabinet">
            <a
                routerLink="/cabinet/invoices"
                routerLinkActive="active"
                [ngClass]="{ light: (1 | colourTheme) === false }"
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                        class="svgActive"
                        d="M20.0625 10.5H13.5C12.9033 10.5 12.331 10.2629 11.909 9.84099C11.4871 9.41903 11.25 8.84674 11.25 8.25V1.6875C11.25 1.63777 11.2302 1.59008 11.1951 1.55492C11.1599 1.51975 11.1122 1.5 11.0625 1.5H6.75C5.95435 1.5 5.19129 1.81607 4.62868 2.37868C4.06607 2.94129 3.75 3.70435 3.75 4.5V19.5C3.75 20.2956 4.06607 21.0587 4.62868 21.6213C5.19129 22.1839 5.95435 22.5 6.75 22.5H17.25C18.0456 22.5 18.8087 22.1839 19.3713 21.6213C19.9339 21.0587 20.25 20.2956 20.25 19.5V10.6875C20.25 10.6378 20.2302 10.5901 20.1951 10.5549C20.1599 10.5198 20.1122 10.5 20.0625 10.5ZM15.75 18H8.25C8.05109 18 7.86032 17.921 7.71967 17.7803C7.57902 17.6397 7.5 17.4489 7.5 17.25C7.5 17.0511 7.57902 16.8603 7.71967 16.7197C7.86032 16.579 8.05109 16.5 8.25 16.5H15.75C15.9489 16.5 16.1397 16.579 16.2803 16.7197C16.421 16.8603 16.5 17.0511 16.5 17.25C16.5 17.4489 16.421 17.6397 16.2803 17.7803C16.1397 17.921 15.9489 18 15.75 18ZM15.75 14.25H8.25C8.05109 14.25 7.86032 14.171 7.71967 14.0303C7.57902 13.8897 7.5 13.6989 7.5 13.5C7.5 13.3011 7.57902 13.1103 7.71967 12.9697C7.86032 12.829 8.05109 12.75 8.25 12.75H15.75C15.9489 12.75 16.1397 12.829 16.2803 12.9697C16.421 13.1103 16.5 13.3011 16.5 13.5C16.5 13.6989 16.421 13.8897 16.2803 14.0303C16.1397 14.171 15.9489 14.25 15.75 14.25Z"
                        fill="var(--primary)"
                    />
                    <path
                        class="svgActive"
                        d="M19.6509 8.84015L12.9098 2.09906C12.8967 2.08602 12.8801 2.07716 12.8619 2.07357C12.8438 2.06999 12.825 2.07184 12.8079 2.07891C12.7908 2.08597 12.7762 2.09793 12.7659 2.11327C12.7556 2.12862 12.7501 2.14666 12.75 2.16515V8.24999C12.75 8.4489 12.829 8.63967 12.9697 8.78032C13.1103 8.92098 13.3011 8.99999 13.5 8.99999H19.5848C19.6033 8.99992 19.6214 8.99438 19.6367 8.98407C19.6521 8.97376 19.664 8.95915 19.6711 8.94207C19.6781 8.92498 19.68 8.9062 19.6764 8.88806C19.6728 8.86993 19.664 8.85326 19.6509 8.84015Z"
                        fill="var(--primary)"
                    />
                </svg>
                <div class="navText">{{ 'cabinet.navigation.invoices' | translate }}</div>
            </a>
        </li>

        <div class="navDropDown" *ngIf="showAK" [@openCloseNav]="isOpenNav ? 'openNav' : 'closedNav'">
            <li class="menu-list__item buttonToOpenDropDownInNav">
                <a [ngClass]="{ light: (1 | colourTheme) === false }" (click)="menuSlideOpen()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <g clip-path="url(#clip0_1675_76238)">
                            <path
                                d="M4.875 23.25H3.375C3.07663 23.25 2.79048 23.1315 2.5795 22.9205C2.36853 22.7095 2.25 22.4234 2.25 22.125V15.375C2.25 15.0766 2.36853 14.7905 2.5795 14.5795C2.79048 14.3685 3.07663 14.25 3.375 14.25H4.875C5.17337 14.25 5.45952 14.3685 5.6705 14.5795C5.88147 14.7905 6 15.0766 6 15.375V22.125C6 22.4234 5.88147 22.7095 5.6705 22.9205C5.45952 23.1315 5.17337 23.25 4.875 23.25Z"
                                fill="var(--primary)"
                            />
                            <path
                                d="M15.375 23.25H13.875C13.5766 23.25 13.2905 23.1315 13.0795 22.9205C12.8685 22.7095 12.75 22.4234 12.75 22.125V10.875C12.75 10.5766 12.8685 10.2905 13.0795 10.0795C13.2905 9.86854 13.5766 9.75002 13.875 9.75002H15.375C15.6734 9.75002 15.9595 9.86854 16.1705 10.0795C16.3815 10.2905 16.5 10.5766 16.5 10.875V22.125C16.5 22.4234 16.3815 22.7095 16.1705 22.9205C15.9595 23.1315 15.6734 23.25 15.375 23.25Z"
                                fill="var(--primary)"
                            />
                            <path
                                d="M20.625 23.25H19.125C18.8266 23.25 18.5405 23.1315 18.3295 22.9205C18.1185 22.7095 18 22.4234 18 22.125V5.62502C18 5.32665 18.1185 5.0405 18.3295 4.82952C18.5405 4.61854 18.8266 4.50002 19.125 4.50002H20.625C20.9234 4.50002 21.2095 4.61854 21.4205 4.82952C21.6315 5.0405 21.75 5.32665 21.75 5.62502V22.125C21.75 22.4234 21.6315 22.7095 21.4205 22.9205C21.2095 23.1315 20.9234 23.25 20.625 23.25Z"
                                fill="var(--primary)"
                            />
                            <path
                                d="M10.125 23.25H8.625C8.32663 23.25 8.04048 23.1315 7.8295 22.9205C7.61853 22.7095 7.5 22.4234 7.5 22.125V1.87502C7.5 1.57665 7.61853 1.2905 7.8295 1.07952C8.04048 0.868542 8.32663 0.750015 8.625 0.750015H10.125C10.4234 0.750015 10.7095 0.868542 10.9205 1.07952C11.1315 1.2905 11.25 1.57665 11.25 1.87502V22.125C11.25 22.4234 11.1315 22.7095 10.9205 22.9205C10.7095 23.1315 10.4234 23.25 10.125 23.25Z"
                                fill="var(--primary)"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_1675_76238">
                                <rect width="24" height="24" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                    <div class="navText">{{ 'cabinet.navigation.ak' | translate }}</div>
                    <svg
                        _ngcontent-jwx-c158=""
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="8"
                        viewBox="0 0 12 8"
                        fill="none"
                        [ngClass]="isOpenNav ? 'rotated' : ''"
                    >
                        <path
                            _ngcontent-jwx-c158=""
                            d="M2.11997 1.28957L5.99997 5.16957L9.87997 1.28957C10.27 0.89957 10.9 0.89957 11.29 1.28957C11.68 1.67957 11.68 2.30957 11.29 2.69957L6.69997 7.28957C6.30997 7.67957 5.67997 7.67957 5.28997 7.28957L0.699971 2.69957C0.309971 2.30957 0.309971 1.67957 0.699971 1.28957C1.08997 0.90957 1.72997 0.89957 2.11997 1.28957Z"
                            fill="#04050E"
                        ></path>
                    </svg>
                </a>
            </li>

            <li class="menu-list__item buttonInDropDownNav">
                <a
                    routerLink="/employeeFund/cashRegister"
                    routerLinkActive="active"
                    class="link"
                    style="height: 72px"
                    [ngClass]="{ light: (1 | colourTheme) === false }"
                >
                    <svg
                        _ngcontent-clx-c235=""
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 26 25"
                        fill="var(--primary)"
                    >
                        <path
                            _ngcontent-clx-c235=""
                            class="svgActive"
                            d="M2.07979 9.61911C0.442588 11.2165 0.988367 12.2814 3.17121 12.2814"
                            stroke="var(--primary)"
                        ></path>
                        <path
                            _ngcontent-clx-c235=""
                            class="svgActive"
                            d="M6.44554 20.8007C3.17125 18.1384 2.62548 15.4761 3.17127 12.8138C4.91756 5.57244 13.358 5.53694 17.3599 6.42437C19.1063 4.72051 20.9981 4.64952 21.7257 4.827L21.18 8.55419C22.4897 8.98015 23.181 10.5065 23.3629 11.2165H25V17.6059H23.3629C23.3629 18.8838 21.5438 20.2682 20.6343 20.8007C21.18 22.9305 20.0885 23.9954 18.9971 23.9954C17.9056 23.9954 16.8142 23.108 16.2685 22.398C14.0856 23.2499 11.357 22.753 10.2656 22.398C10.0837 22.9305 9.28326 24.4214 7.53696 23.9954C5.79066 23.5694 6.08173 21.5106 6.44554 20.8007Z"
                            stroke="var(--primary)"
                        ></path>
                        <ellipse
                            _ngcontent-clx-c235=""
                            class="svgActiveBlue"
                            cx="19.5428"
                            cy="13.3463"
                            rx="1.09144"
                            ry="1.06491"
                            fill="#fff"
                        ></ellipse>
                        <path
                            _ngcontent-clx-c235=""
                            class="strokeToWhite"
                            d="M8.62829 6.95678C6.36835 5.06679 7.53696 1.63229 9.71984 1.09984C12.4484 0.567405 15.177 2.16472 14.0855 5.89187"
                            stroke="var(--primary)"
                            stroke-width="2px"
                            fill="#fff"
                        ></path>
                    </svg>
                    <div *ngIf="isOpen" class="navText">
                        {{ 'cabinet.navigation.submenu.ak.cashRegister' | translate }}
                    </div>
                </a>
            </li>

            <li class="menu-list__item buttonInDropDownNav">
                <a
                    routerLink="/employeeFund/cashAccounts"
                    routerLinkActive="active"
                    class="link"
                    style="height: 72px"
                    [ngClass]="{ light: (1 | colourTheme) === false }"
                >
                    <svg
                        _ngcontent-clx-c235=""
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 26 25"
                        fill="var(--primary)"
                    >
                        <path
                            _ngcontent-clx-c235=""
                            class="svgActive"
                            d="M2.07979 9.61911C0.442588 11.2165 0.988367 12.2814 3.17121 12.2814"
                            stroke="var(--primary)"
                        ></path>
                        <path
                            _ngcontent-clx-c235=""
                            class="svgActive"
                            d="M6.44554 20.8007C3.17125 18.1384 2.62548 15.4761 3.17127 12.8138C4.91756 5.57244 13.358 5.53694 17.3599 6.42437C19.1063 4.72051 20.9981 4.64952 21.7257 4.827L21.18 8.55419C22.4897 8.98015 23.181 10.5065 23.3629 11.2165H25V17.6059H23.3629C23.3629 18.8838 21.5438 20.2682 20.6343 20.8007C21.18 22.9305 20.0885 23.9954 18.9971 23.9954C17.9056 23.9954 16.8142 23.108 16.2685 22.398C14.0856 23.2499 11.357 22.753 10.2656 22.398C10.0837 22.9305 9.28326 24.4214 7.53696 23.9954C5.79066 23.5694 6.08173 21.5106 6.44554 20.8007Z"
                            stroke="var(--primary)"
                        ></path>
                        <ellipse
                            _ngcontent-clx-c235=""
                            class="svgActiveBlue"
                            cx="19.5428"
                            cy="13.3463"
                            rx="1.09144"
                            ry="1.06491"
                            fill="#fff"
                        ></ellipse>
                        <path
                            _ngcontent-clx-c235=""
                            class="strokeToWhite"
                            d="M8.62829 6.95678C6.36835 5.06679 7.53696 1.63229 9.71984 1.09984C12.4484 0.567405 15.177 2.16472 14.0855 5.89187"
                            stroke="var(--primary)"
                            stroke-width="2px"
                            fill="#fff"
                        ></path>
                    </svg>
                    <div *ngIf="isOpen" class="navText">
                        {{ 'cabinet.navigation.submenu.ak.cashAccounts' | translate }}
                    </div>
                </a>
            </li>

            <li class="menu-list__item buttonInDropDownNav">
                <a
                    routerLink="/employeeFund/allTransactions"
                    routerLinkActive="active"
                    class="link"
                    style="height: 72px"
                    [ngClass]="{ light: (1 | colourTheme) === false }"
                >
                    <svg
                        _ngcontent-clx-c235=""
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 26 25"
                        fill="var(--primary)"
                    >
                        <path
                            _ngcontent-clx-c235=""
                            class="svgActive"
                            d="M2.07979 9.61911C0.442588 11.2165 0.988367 12.2814 3.17121 12.2814"
                            stroke="var(--primary)"
                        ></path>
                        <path
                            _ngcontent-clx-c235=""
                            class="svgActive"
                            d="M6.44554 20.8007C3.17125 18.1384 2.62548 15.4761 3.17127 12.8138C4.91756 5.57244 13.358 5.53694 17.3599 6.42437C19.1063 4.72051 20.9981 4.64952 21.7257 4.827L21.18 8.55419C22.4897 8.98015 23.181 10.5065 23.3629 11.2165H25V17.6059H23.3629C23.3629 18.8838 21.5438 20.2682 20.6343 20.8007C21.18 22.9305 20.0885 23.9954 18.9971 23.9954C17.9056 23.9954 16.8142 23.108 16.2685 22.398C14.0856 23.2499 11.357 22.753 10.2656 22.398C10.0837 22.9305 9.28326 24.4214 7.53696 23.9954C5.79066 23.5694 6.08173 21.5106 6.44554 20.8007Z"
                            stroke="var(--primary)"
                        ></path>
                        <ellipse
                            _ngcontent-clx-c235=""
                            class="svgActiveBlue"
                            cx="19.5428"
                            cy="13.3463"
                            rx="1.09144"
                            ry="1.06491"
                            fill="#fff"
                        ></ellipse>
                        <path
                            _ngcontent-clx-c235=""
                            class="strokeToWhite"
                            d="M8.62829 6.95678C6.36835 5.06679 7.53696 1.63229 9.71984 1.09984C12.4484 0.567405 15.177 2.16472 14.0855 5.89187"
                            stroke="var(--primary)"
                            stroke-width="2px"
                            fill="#fff"
                        ></path>
                    </svg>
                    <div *ngIf="isOpen" class="navText">
                        {{ 'cabinet.navigation.submenu.ak.allTransactions' | translate }}
                    </div>
                </a>
            </li>
            <li class="menu-list__item buttonInDropDownNav">
                <a
                    routerLink="/employeeFund/painFiles"
                    routerLinkActive="active"
                    class="link"
                    style="height: 72px"
                    [ngClass]="{ light: (1 | colourTheme) === false }"
                >
                    <svg
                        _ngcontent-clx-c235=""
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 26 25"
                        fill="var(--primary)"
                    >
                        <path
                            _ngcontent-clx-c235=""
                            class="svgActive"
                            d="M2.07979 9.61911C0.442588 11.2165 0.988367 12.2814 3.17121 12.2814"
                            stroke="var(--primary)"
                        ></path>
                        <path
                            _ngcontent-clx-c235=""
                            class="svgActive"
                            d="M6.44554 20.8007C3.17125 18.1384 2.62548 15.4761 3.17127 12.8138C4.91756 5.57244 13.358 5.53694 17.3599 6.42437C19.1063 4.72051 20.9981 4.64952 21.7257 4.827L21.18 8.55419C22.4897 8.98015 23.181 10.5065 23.3629 11.2165H25V17.6059H23.3629C23.3629 18.8838 21.5438 20.2682 20.6343 20.8007C21.18 22.9305 20.0885 23.9954 18.9971 23.9954C17.9056 23.9954 16.8142 23.108 16.2685 22.398C14.0856 23.2499 11.357 22.753 10.2656 22.398C10.0837 22.9305 9.28326 24.4214 7.53696 23.9954C5.79066 23.5694 6.08173 21.5106 6.44554 20.8007Z"
                            stroke="var(--primary)"
                        ></path>
                        <ellipse
                            _ngcontent-clx-c235=""
                            class="svgActiveBlue"
                            cx="19.5428"
                            cy="13.3463"
                            rx="1.09144"
                            ry="1.06491"
                            fill="#fff"
                        ></ellipse>
                        <path
                            _ngcontent-clx-c235=""
                            class="strokeToWhite"
                            d="M8.62829 6.95678C6.36835 5.06679 7.53696 1.63229 9.71984 1.09984C12.4484 0.567405 15.177 2.16472 14.0855 5.89187"
                            stroke="var(--primary)"
                            stroke-width="2px"
                            fill="#fff"
                        ></path>
                    </svg>
                    <div *ngIf="isOpen" class="navText">
                        {{ 'cabinet.navigation.submenu.ak.painFiles' | translate }}
                    </div>
                </a>
            </li>
        </div>

        <div class="menuColapseButton">
            <li class="menu-list__item">
                <a (click)="menuOpen()" [ngClass]="{ light: (1 | colourTheme) === false }">
                    <svg
                        class="arrowIcon"
                        [ngClass]="isOpen ? 'arrowIcon' : 'arrowIconClose'"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                    >
                        <path
                            class="svgActive"
                            d="M2.25 12C2.25 17.3845 6.61547 21.75 12 21.75C17.3845 21.75 21.75 17.3845 21.75 12C21.75 6.61547 17.3845 2.25 12 2.25C6.61547 2.25 2.25 6.61547 2.25 12ZM12.218 7.7175C12.2879 7.78687 12.3435 7.86934 12.3816 7.96019C12.4196 8.05105 12.4394 8.14851 12.4398 8.24702C12.4402 8.34552 12.4212 8.44314 12.3838 8.5343C12.3465 8.62545 12.2916 8.70836 12.2222 8.77828L9.76969 11.25H16.0312C16.2302 11.25 16.4209 11.329 16.5616 11.4697C16.7022 11.6103 16.7812 11.8011 16.7812 12C16.7812 12.1989 16.7022 12.3897 16.5616 12.5303C16.4209 12.671 16.2302 12.75 16.0312 12.75H9.76969L12.2222 15.2217C12.2916 15.2917 12.3465 15.3747 12.3838 15.4659C12.4211 15.5571 12.4401 15.6548 12.4396 15.7533C12.4392 15.8519 12.4194 15.9494 12.3812 16.0402C12.3431 16.1311 12.2875 16.2136 12.2175 16.283C12.1475 16.3523 12.0645 16.4073 11.9733 16.4446C11.8821 16.4819 11.7845 16.5009 11.6859 16.5004C11.5874 16.5 11.4899 16.4801 11.399 16.442C11.3081 16.4039 11.2256 16.3483 11.1562 16.2783L7.43484 12.5283C7.29544 12.3878 7.21722 12.1979 7.21722 12C7.21722 11.8021 7.29544 11.6122 7.43484 11.4717L11.1562 7.72172C11.2256 7.65164 11.3082 7.59593 11.3991 7.55778C11.49 7.51964 11.5876 7.4998 11.6862 7.49941C11.7849 7.49902 11.8826 7.51808 11.9738 7.5555C12.0651 7.59292 12.148 7.64797 12.218 7.7175Z"
                            fill="var(--primary)"
                        />
                    </svg>
                    <div class="navText">{{ 'cabinet.navigation.closeMenu' | translate }}</div>
                </a>
            </li>
        </div>
    </ul>
    <div *ngIf="isOpen" class="version-container">
        <div class="version">Build: {{ version.semverString }} ({{ version.date }})</div>
    </div>
</div>
