import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'app-named-datepicker',
    templateUrl: './named-datapicker.component.html',
    styleUrls: ['./named-datapicker.component.scss'],
})
export class NamedDatepickerComponent implements OnInit {
    @Output() dateChanged: EventEmitter<Date> = new EventEmitter();
    @Input() type: 'input' | 'icon' = 'icon';
    @Input() currentDate: Date = new Date();
    @Input() isBirth = false;
    @Input() title = '';
    formControl: FormControl;
    currentValue: Date = new Date();

    constructor() {}

    ngOnInit(): void {
        this.formControl = new FormControl(this.currentDate);
        this.formControl.valueChanges.subscribe((value) => {
            this.currentValue = value;
            return this.dateChanged.emit(value);
        });
    }

    reformateDate(dt) {
        return !dt
            ? ''
            : (dt.getDate() > 9 ? dt.getDate() : '0' + dt.getDate()) +
                  '.' +
                  (dt.getMonth() > 8 ? dt.getMonth() + 1 : '0' + (dt.getMonth() + 1)) +
                  '.' +
                  dt.getFullYear();
    }
}
