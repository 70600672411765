import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'app-checkbox',
    template: `
        <label class="cb-container" [ngClass]="{ light: (1 | colourTheme) === false }">
            <input
                type="checkbox"
                [checked]="isChecked"
                (change)="onChanged($event)"
                (blur)="onBlur($event)"
                [disabled]="disabled"
            />
            <span class="checkmark" [ngClass]="{ light: (1 | colourTheme) === false }"></span>
            {{ text }}</label
        >
    `,
    styleUrls: ['./checkbox.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CheckboxComponent),
            multi: true,
        },
    ],
})
export class CheckboxComponent implements ControlValueAccessor {
    @Input() text: string;
    @Input() disabled = false;

    isChecked = false;
    onChange = (_) => {};
    onBlur = (_) => {};

    constructor() {}

    writeValue(obj: any): void {
        this.isChecked = obj;
    }
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onBlur = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    onChanged($event) {
        this.isChecked = $event && $event.target && $event.target.checked;
        this.onChange(this.isChecked);
    }
}
