import { Component, OnInit } from '@angular/core';
import { MetricsService } from '../../../core/services/metrics.service';

@Component({
  selector: 'app-metrics',
  template: '{{ metricsOutput | json }}',
  styles: ['']
})
export class MetricsComponent implements OnInit {
  metricsOutput: { health: string } = { health: 'UP' };

  constructor(private metricsService: MetricsService) {}

  ngOnInit() {
    this.updateMetrics();
  }

  updateMetrics() {
    this.metricsService.getHealth().subscribe(health => {
      this.metricsOutput = health;
    });
  }
}