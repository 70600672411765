import { MatPaginator } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { ChangeDetectorRef } from '@angular/core';

export function getPaginatorTranslations(
    paginator: MatPaginator,
    translateService: TranslateService,
    changeDetectorRef: ChangeDetectorRef
): void {
    translateService
        .get(['common.paginator.items-per-page', 'common.paginator.next-page', 'common.paginator.previous-page'])
        .subscribe((translation) => {
            paginator._intl.itemsPerPageLabel = translation['common.paginator.items-per-page'];
            paginator._intl.nextPageLabel = translation['common.paginator.next-page'];
            paginator._intl.previousPageLabel = translation['common.paginator.previous-page'];
            paginator._intl.changes.next();
            changeDetectorRef.detectChanges();
        });
}
