import { Component } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({ template: '' })
export abstract class PageableDataTableComponent {
    paging: any = {};
    sorting: any = {};
    filtering: any = {};

    totalElements: number;
    pageNumber: number = 7;
    pageSize: number = 50;
    dataSource: MatTableDataSource<any>;
    pageEvent: PageEvent;
}
