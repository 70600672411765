import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'InsurancesFilter',
    pure: false,
})
export class searchTableInsurances implements PipeTransform {
    value: string;
    transform(insurancesList: Array<any>, filter: string): any {
        if (insurancesList) {
            return insurancesList.filter(
                (d) =>
                    (d.personId != null && d.personId.indexOf(filter) > -1) ||
                    (d.insurances[0].companyId != null &&
                        d.insurances[0].companyId.toLowerCase().indexOf(filter.toLowerCase()) > -1) ||
                    (d.personalInformation.ssn13 != null && d.personalInformation.ssn13.indexOf(filter) > -1) ||
                    (d.personalInformation.addresses != null &&
                        d.personalInformation.addresses[0].email.toLowerCase().indexOf(filter.toLowerCase()) > -1) ||
                    (d.personalInformation.firstName != null &&
                        d.personalInformation.firstName.toLowerCase().indexOf(filter.toLowerCase()) > -1) ||
                    (d.personalInformation.lastName != null &&
                        d.personalInformation.lastName.toLowerCase().indexOf(filter.toLowerCase()) > -1)
            );
        }
    }
}
