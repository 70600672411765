import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { AkGuard } from './core/guards/ak.guard';
import { CabinetGuard } from './core/guards/cabinet.guard';
import { RoleGuard } from './core/guards/role.guard';
import { MetricsComponent } from './shared/components/metrics/metrics.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'auth',
        pathMatch: 'full',
    },
    {
        path: 'home',
        loadChildren: () => import('./modules/home/home.module').then((m) => m.HomeModule),
        canActivate: [AuthGuard, RoleGuard],
    },
    {
        path: 'auth',
        loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule),
    },
    {
        path: 'cabinet',
        loadChildren: () => import('./modules/cabinet/cabinet.module').then((m) => m.CabinetModule),
        canActivate: [AuthGuard, CabinetGuard, RoleGuard],
    },
    {
        path: 'employeeFund',
        loadChildren: () => import('./modules/ak/ak.module').then((m) => m.AkModule),
        canActivate: [AuthGuard, AkGuard, RoleGuard],
    },
    {
        path: 'metrics/health',
        component: MetricsComponent,
    },
    // {
    //     // path: 'resetPassword', component: ResetPasswordComponent
    // }
];
@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
