import { GlobalEventsService } from 'src/app/core/services/global-events.service';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class BookingTypeService {
    constructor(private globalEventsService: GlobalEventsService, private translateService: TranslateService) {}
    getBookingTypeById(bookingTypeId: string) {
        let config = this.globalEventsService.getBookingTypes();
        for (const key in config) {
            if (config.hasOwnProperty(key) && config[key].bookingTypeId.toString() === bookingTypeId.toString()) {
                return this.translateService.instant(`ak.cashRegister.bookingType.${config[key].bookingShortDesc}`);
            }
        }
        return '';
    }

    getBookingTypeByName(name: string) {
        let config = this.globalEventsService.getBookingTypes();
        for (const key in config) {
            if (
                config.hasOwnProperty(key) &&
                config[key].bookingShortDesc.toString().toLowerCase() === name.toString().toLowerCase()
            ) {
                return config[key].bookingTypeId;
            }
        }
        return '';
    }
}
