import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';

@Injectable({
    providedIn: 'root',
})
export class CustomDateAdapter extends NativeDateAdapter {
    format(date: Date): string {
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${this.custom2Digit(day)}.${this.custom2Digit(month)}.${year}`;
    }

    private custom2Digit(n: number): string {
        return n < 10 ? `0${n}` : `${n}`;
    }
}
