import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CurrencyService } from '../../../../core/services/currency.service';
import { AuthenticationService } from 'clan-auth';

@Component({
    selector: 'app-user-header',
    templateUrl: './user-header.component.html',
    styleUrls: ['./user-header.component.scss'],
})
export class UserHeaderComponent implements OnInit {
    @Input() userName: string;
    @Input() userSsn: string;
    initials;
    firstNameFirstLetter;
    lastNameFirstLetter;

    constructor(
        private router: Router,
        private currencyService: CurrencyService,
        private authService: AuthenticationService
    ) {}

    async ngOnInit() {
        this.initials = ' ';
    }

    get $currencyVisibility(): Observable<boolean> {
        return this.currencyService.$currencyValuesVisibility;
    }
    doLogout() {
        const redirectUrl = sessionStorage.getItem('default_tenant_login_url');
        this.authService.logout().subscribe(() => {
            this.router.navigateByUrl(`auth${redirectUrl}`);
        });
    }
}
