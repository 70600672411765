import { Pipe, PipeTransform } from '@angular/core';
import { VisualisationService } from '../../core/services/visualisation.service';

@Pipe({
    name: 'colourTheme',
    pure: false,
})
export class ColourThemePipe implements PipeTransform {
    private isDark = true;
    constructor(private visibilityService: VisualisationService) {}
    transform(value: string | number): boolean {
        this.visibilityService.$darkTheme.subscribe((data) => {
            this.isDark = data;
        });
        return this.isDark;
    }
}
