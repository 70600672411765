import { Component, ElementRef, HostListener, OnInit } from '@angular/core';

import { CookieService } from 'ngx-cookie-service';
import { map, Subscription } from 'rxjs';
import { Locale } from 'src/app/core/interfaces/locale';
import { GlobalEventsService } from 'src/app/core/services/global-events.service';
import { LocaleService } from 'src/app/core/services/locale.service';
import { UserService } from 'src/app/core/services/user.service';
import { VisualisationService } from 'src/app/core/services/visualisation.service';
import { environment } from 'src/environments/environment';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { AuthObserver, LogoutEvent } from 'clan-auth';
import { DateAdapter } from '@angular/material/core';
import { CustomConfigurationService } from 'src/app/shared/services/custom-configuration.service';
import { CustomConfigType } from 'src/app/core/models/enums/CustomConfigType';
import { Router } from '@angular/router';
import { IdleService } from 'src/app/core/services/idle.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    animations: [
        trigger('openClose', [
            state(
                'open',
                style({
                    width: '17%',
                })
            ),
            state(
                'closed',
                style({
                    width: '3.7%',
                })
            ),
            transition('open => closed', [animate('0.5s')]),
            transition('closed => open', [animate('0.5s')]),
        ]),
    ],
})
export class AppComponent implements OnInit {
    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.screenWidth = window.innerWidth;
    }
    @HostListener('window:scroll', ['$event'])
    onScroll(event) {
        if (this.isOpen && this.screenWidth < 900 && window.pageYOffset > 50) {
            this.menuOpen();
        }
    }

    isOpen = true;
    isOpenNav = true;
    screenWidth;

    title = 'swarm Employer';
    isAuthenticated: boolean = false;

    private subscription: Subscription = new Subscription();

    constructor(
        private localeService: LocaleService,
        private visualisationService: VisualisationService,
        private cookieService: CookieService,
        private globalEventsService: GlobalEventsService,
        private authObserver: AuthObserver,
        private dateAdapter: DateAdapter<Date>,
        private customConfigurationService: CustomConfigurationService,
        private router: Router,
        private idleService: IdleService,
        private _elementRef: ElementRef
    ) {
        this.subscription.add(
            this.globalEventsService.isAuthenticated.subscribe((isAuthenticated) => {
                this.isAuthenticated = isAuthenticated;
            })
        );

        this.authObserver.observer.subscribe({
            next: (value) => {
                if (value instanceof LogoutEvent) {
                    this.globalEventsService.setIsAuthenticated(false);
                    this.isAuthenticated = false;
                }
            },
        });
    }

    reset() {
        if (environment.automaticLogOutActivated) {
            this.idleService.reset();
        }
    }

    @HostListener('mousemove') resetListener1() {
        this.reset();
    }
    @HostListener('click') resetListener2() {
        this.reset();
    }
    @HostListener('keypress') resetListener3() {
        this.reset();
    }
    @HostListener('DOMMouseScroll') resetListener4() {
        this.reset();
    }
    @HostListener('mousewheel') resetListener5() {
        this.reset();
    }
    @HostListener('touchmove') resetListener6() {
        this.reset();
    }
    @HostListener('MSPointerMove') resetListener7() {
        this.reset();
    }

    ngOnInit() {
        this._elementRef.nativeElement.removeAttribute('ng-version');

        let language = environment.localeData.defaultLocale;

        if (
            this.cookieService.get('lang') &&
            environment.localeData.locales.includes(this.cookieService.get('lang') as Locale)
        ) {
            language = this.cookieService.get('lang') as Locale;
        }
        this.localeService.initTranslate(language);
        if (
            !this.cookieService.get('theme') ||
            (this.cookieService.get('theme') && this.cookieService.get('theme') === 'false')
        ) {
            this.visualisationService.setDarkTheme(false);
            document.body.classList.add('light');
        } else {
            this.visualisationService.setDarkTheme(true);
            document.body.classList.remove('light');
        }

        if (this.isAuthenticated) {
            this.customConfigurationService
                .getCustomConfiguration$(CustomConfigType.PORTALS_CONFIG)
                .subscribe((features) => {
                    this.globalEventsService.setHasAk(features.config.portals.employer.features.ak);
                    this.globalEventsService.setHasCabinet(features.config.portals.employer.features.cabinet);
                });

            this.customConfigurationService
                .getCustomConfiguration$(CustomConfigType.BOOKING_TYPES)
                .subscribe((bookingTypes) => {
                    this.globalEventsService.setBookingTypes(bookingTypes.config);
                });

            this.customConfigurationService.getCustomConfiguration$(CustomConfigType.BRANDING).subscribe((branding) => {
                this.globalEventsService.setBranding(branding);

                const themeWrapper = document.querySelector('body');

                if (themeWrapper && branding.config) {
                    if (branding.config.primary_color) {
                        themeWrapper.style.setProperty('--primary', branding.config.primary_color);
                    }

                    if (branding.config.secondary_color) {
                        themeWrapper.style.setProperty('--secondary', branding.config.secondary_color);
                    }

                    if (branding.config.mouse_over) {
                        themeWrapper.style.setProperty('--hover', branding.config.mouse_over);
                    }

                    if (branding.config.primary_font) {
                        themeWrapper.style.setProperty('--primary-font', branding.config.primary_font);
                    }

                    if (branding.config.header_text_color) {
                        themeWrapper.style.setProperty('--header-text-color', branding.config.header_text_color);
                    }

                    if (branding.config.logoUrl) {
                        this.globalEventsService.setLogo(branding.config.logoUrl);
                    }
                }
            });
        }
    }

    menuOpen() {
        if (this.isOpen == true && this.isOpenNav == true) {
            this.menuSlideOpen();
        }
        this.isOpen = !this.isOpen;
    }

    menuSlideOpen() {
        this.isOpenNav = !this.isOpenNav;
    }

    toggleNavigation(open: boolean) {
        this.isOpen = open;
    }
}
