import { Pipe, PipeTransform } from '@angular/core';
import { splitNumberWithComma } from '../functions';

@Pipe({
    name: 'splitNumberWithComma',
    pure: false,
})
export class SplitNumberWithCommaPipe implements PipeTransform {
    transform(value: number | string, withTwoSymbols = false, input = false): string {
        if (!value) {
            return input ? '0' : '0.00';
        }

        if (!withTwoSymbols) {
            return splitNumberWithComma(value);
        }

        const myValue = typeof value === 'string' ? Number.parseFloat(value.toString()) : value;

        if (myValue % 1 === 0) {
            return splitNumberWithComma(value) + '.00';
        }

        let newValue = splitNumberWithComma(myValue.toFixed(2));

        if (newValue.indexOf('.') !== -1 && newValue.split('.')[1].length === 1) {
            newValue += '0';
        }

        return newValue;
    }
}
