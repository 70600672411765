import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { GlobalEventsService } from '../services/global-events.service';

@Injectable({
    providedIn: 'root',
})
export class AkGuard implements CanActivate {
    constructor(private globalEventsService: GlobalEventsService) {}

    canActivate(): boolean {
        return this.globalEventsService.getHasAk();
    }
}
