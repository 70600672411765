import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class VisualisationService {
    private portfolioVisibility: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    private darkTheme: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    constructor(private cookieService: CookieService) {}

    get $portfolioVisibility(): Observable<boolean> {
        return this.portfolioVisibility.asObservable();
    }

    togglePortfolioVisibility() {
        this.portfolioVisibility.next(!this.portfolioVisibility.value);
    }

    get $darkTheme(): Observable<boolean> {
        return this.darkTheme.asObservable();
    }

    toggleTheme() {
        const value = !this.darkTheme.value;
        this.darkTheme.next(value);
        this.cookieService.set('theme', String(this.darkTheme.value), 365, '/', null, null);
    }

    setDarkTheme(value: boolean) {
        this.darkTheme.next(value);
        this.cookieService.set('theme', String(this.darkTheme.value), 365, '/', null, null);
    }
}
