import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'app-form-input-base',
    templateUrl: './form-input-base.component.html',
    styleUrls: ['./form-input-base.component.scss'],
})
export class FormInputBaseComponent implements OnInit {
    @Output() dataChanged: EventEmitter<string> = new EventEmitter();
    @Input() title: string;
    @Input() value: string | number;
    @Input() isLong = false;
    formControl: FormControl;

    constructor() {}

    ngOnInit(): void {
        this.formControl = new FormControl(this.value);
        this.formControl.valueChanges.subscribe((value) => {
            return this.dataChanged.emit(value);
        });
    }

    nameField(): string {
        return this.title.replace(/\s/g, '');
    }
}
