import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class CustomConfigurationService {
    get apiUrl(): string {
        return sessionStorage.getItem('api-url');
    }

    constructor(public http: HttpClient) {}

    getCustomConfiguration$(name: string): Observable<any> {
        const url = `${this.apiUrl}/custom-configs/getByName/${name}`;
        return this.http.get<any>(url);
    }
}
