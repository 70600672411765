import { Injectable } from '@angular/core';
import {
    MatSnackBar,
    MatSnackBarConfig,
    MatSnackBarHorizontalPosition,
    MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';

type TSnackbarType = 'success' | 'error' | 'default';

@Injectable({
    providedIn: 'root',
})
export class SnackBarService {
    constructor(private snackBar: MatSnackBar) {}

    showSuccessAlert(message: string): void {
        this.snackBar.open(message, 'x', {
            duration: -1, // Set the duration (in milliseconds)
            panelClass: ['success-snackbar'], // Define a CSS class for styling
        });
    }

    showErrorAlert(message: string): void {
        this.snackBar.open(message, 'x', {
            duration: -1, // Adjust the duration as needed
            panelClass: ['error-snackbar'], // Define a CSS class for styling
        });
    }

    handleOpenSnackbar(message: string, action: string, type: TSnackbarType, config?: MatSnackBarConfig<unknown>) {
        let typeClass: string;

        switch (type) {
            case 'success':
                typeClass = 'success-snackbar';
                break;
            case 'error':
                typeClass = 'error-snackbar';
                break;
            default:
                typeClass = 'snackbar--default';
        }

        const defaultConfig = {
            duration: 5000,
            verticalPosition: 'top' as MatSnackBarVerticalPosition,
            horizontalPosition: 'right' as MatSnackBarHorizontalPosition,
            panelClass: [typeClass],
        };

        this.snackBar.open(message, action, { ...defaultConfig, ...config });
    }
}
