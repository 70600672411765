import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { TransactionsService } from '../../../core/services/transactions.service';
import { conditionalValidator } from '../../functions/conditionalValidator';
import { validateDateAfter } from '../../functions/validateDateFunctions';
import { UserService } from '../../../core/services/user.service';
import { sovValidator, sovPoints } from '../../functions/sovFunctions';
import { AuthenticationService } from 'clan-auth';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import {
    Address,
    AddressType,
    Child,
    Gender,
    Insurance,
    Language,
    MaritalStatusCodeType,
    Partner,
    Person,
    PersonalInformation,
    Role,
    Salary,
    SalaryType,
    Status,
} from 'models';
@Component({
    selector: 'app-insurant-personaldata-edit',
    templateUrl: './insurant-personaldata-edit.component.html',
    styleUrls: ['./insurant-personaldata-edit.component.scss'],
})
export class InsurantPersonaldataEditComponent implements OnInit {
    @Input() wantToClose: Subject<boolean>;
    @Input() person: Person;
    @Input() newPerson: boolean;
    @Output() closePanel: EventEmitter<void> = new EventEmitter();
    @Output() editDone: EventEmitter<void> = new EventEmitter();
    @Output() editDonee: EventEmitter<void> = new EventEmitter();

    selectedIndex = 0;
    selectedIndexDisplay = 2;
    openPopup = false;

    hasPartner: boolean;
    hasChildren: boolean;
    genderList = Object.values(Gender);
    maritalStatusList = Object.values(MaritalStatusCodeType);
    languageList = Object.values(Language);
    countryList = ['CH', 'FR', 'AT', 'DE', 'IT', 'LI'];
    form: FormGroup;
    childForm: FormGroup;
    children: Array<Child>;
    //partner: Array<Partner>;
    isAddChildren = false;
    currentDate: Date;
    minDateOfBirth: any;
    maxDateOfBirth: any;
    minDateOfBirthForValidation: Date;
    maxDateOfBirthForValidation: Date;
    sovInput = '756.';
    sovInputValid = false;
    showSpinner = false;
    confirmationBoxNewEmployee;
    blendOutSaveButton = false;

    allCompanies: Role[];
    companyNameMap: Map<string, string>;

    formUntouched = true;
    openPopUpConfirmation = false;
    openPopUpConfirmationForNewEmployee = false;

    private emailInput = '';
    private dateOfBirthInput = undefined;
    private entryDateInput = undefined;
    private validityDateInput = undefined;
    private employmentDegreeInput = 0;
    private premiumWaiverInput = 0;
    private ivInput = 0;
    private partnerFirstNameInput = '';
    private partnerLastNameInput = '';
    private partnerSovInput = '';
    private partnerDateOfBirthInput = undefined;
    private partnerGenderInput = '';
    private maritalDateInput = undefined;
    private divorceDateInput = undefined;
    private childFirstNameInput = '';
    private childLastNameInput = '';
    private childSovInput = '';
    private childDateOfBirthInput = undefined;
    private childGenderInput = '';
    private requiredLongStringValidator = [Validators.required, Validators.minLength(1), Validators.maxLength(60)];
    private percentageValidator = [Validators.min(0), Validators.max(100)];
    // salary controls
    private entryDateControl = [
        undefined,
        [
            Validators.required,
            validateDateAfter(
                () => this.entryDateInput,
                () => this.dateOfBirthInput
            ),
        ],
    ];
    private salaryControl = [0, [Validators.required, Validators.min(1)]];
    private validityControl = [undefined, [Validators.required]];
    private employmentDegreeControl = [
        0,
        [Validators.required, Validators.min(1), Validators.max(100), this.notMoreThanFullEmployment()],
    ];
    private premiumWaiverControl = [0, this.percentageValidator];
    private ivControl = [0, this.percentageValidator];

    step1 = false;
    step2 = false;
    step3 = false;
    step4 = false;
    step5 = false;

    firstFormValid = false;

    constructor(
        private fb: FormBuilder,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private transactionService: TransactionsService,
        private userService: UserService,
        private auth: AuthenticationService
    ) {}

    next() {
        this.selectedIndex++;
        this.selectedIndexDisplay++;
    }
    back() {
        this.selectedIndex--;
        this.selectedIndexDisplay--;
    }
    step(value) {
        var c = this.form.controls;
        if (value === 'one') {
            this.step1 = c.sov.valid && c.mandant.valid;
        }
        if (value === 'two') {
            this.step2 = c.firstName.valid && c.lastName.valid && c.genderSelect.valid && c.DateOfBirth.valid;
        }
        if (value === 'three') {
            this.step3 = c.country.valid && c.poBox.valid && c.city.valid;
        }
        if (value === 'four') {
            this.step4 = c.statusSelect.valid && c.languageSelect.valid;
        }
        if (value === 'five') {
            this.step5 = c.employmentDegree.valid && c.salary.valid && c.entryDate.valid;
        }
        this.checkIfFormValid();
    }
    checkIfFormValid() {
        var c = this.form.controls;
        this.firstFormValid =
            c.sov.valid &&
            c.firstName.valid &&
            c.lastName.valid &&
            c.statusSelect.valid &&
            c.languageSelect.valid &&
            c.genderSelect.valid &&
            c.DateOfBirth.valid &&
            c.country.valid &&
            c.poBox.valid &&
            c.city.valid &&
            c.country.valid &&
            c.poBox.valid &&
            c.city.valid &&
            c.entryDate.valid &&
            c.salary.valid &&
            c.employmentDegree.valid;
    }
    isPartnerStarted = () => {
        return (
            this.partnerLastNameInput.length > 0 ||
            this.partnerFirstNameInput.length > 0 ||
            this.partnerSovInput.length > 0 ||
            this.partnerDateOfBirthInput ||
            this.partnerGenderInput.length > 0 ||
            this.maritalDateInput ||
            this.divorceDateInput
        );
    };

    isChildStarted = () => {
        return (
            this.childLastNameInput.length > 0 ||
            this.childFirstNameInput.length > 0 ||
            this.childSovInput.length > 0 ||
            this.childDateOfBirthInput ||
            this.childGenderInput.length > 0
        );
    };

    // child Controls
    private lastNameChildControl = [
        '',
        [conditionalValidator(this.isChildStarted, Validators.required), Validators.maxLength(60)],
    ];
    private firstNameChildControl = [
        '',
        [conditionalValidator(this.isChildStarted, Validators.required), Validators.maxLength(60)],
    ];
    private sovChildControl = [
        '',
        [
            conditionalValidator(this.isChildStarted, Validators.required),
            conditionalValidator(this.isChildStarted, Validators.minLength(16)),
        ],
    ];
    private dateOfBirthChildControl = [
        undefined,
        [
            conditionalValidator(this.isChildStarted, Validators.required),
            conditionalValidator(
                this.isChildStarted,
                validateDateAfter(
                    () => this.childDateOfBirthInput,
                    () => this.dateOfBirthInput
                )
            ),
        ],
    ];
    private genderChildControl = ['', conditionalValidator(this.isChildStarted, Validators.required)];

    setDate() {
        this.minDateOfBirth = new Date(Date.now());
        let years = this.minDateOfBirth.getFullYear();
        let yearsForMax = years - 18;
        years = years - 65;
        this.minDateOfBirth = new Date(this.minDateOfBirth.setFullYear(years));
        this.maxDateOfBirth = new Date(this.minDateOfBirth.setFullYear(yearsForMax));
        this.minDateOfBirthForValidation = new Date(this.minDateOfBirth.setFullYear(years));
        this.maxDateOfBirthForValidation = new Date(this.maxDateOfBirth.setFullYear(years));
        let months = this.minDateOfBirthForValidation.getUTCMonth() + 1;
        let days = this.minDateOfBirthForValidation.getDate();
        let monthsMax = this.minDateOfBirthForValidation.getUTCMonth() + 1;
        let daysMax = this.minDateOfBirthForValidation.getDate();
        if (months.toString().length < 2) {
            this.minDateOfBirth = years + '-0' + months + '-' + days;
        } else {
            this.minDateOfBirth = years + '-' + months + '-' + days;
        }
        if (monthsMax.toString().length < 2) {
            this.maxDateOfBirth = yearsForMax + '-0' + monthsMax + '-' + daysMax;
        } else {
            this.maxDateOfBirth = yearsForMax + '-' + monthsMax + '-' + daysMax;
        }
    }

    ngOnInit(): void {
        if (this.wantToClose != undefined) {
            this.wantToClose.subscribe((v) => {
                this.abort('closeCreationOfUser');
            });
        }

        this.currentDate = new Date();
        if (this.newPerson) {
            this.children = [];
            this.setDate();
            this.createRegisterNewForm();
            this.userService.$companyNameMap.subscribe((newMap) => (this.companyNameMap = newMap));
            this.userService.$allCompanies.subscribe((allCompanies) => {
                this.allCompanies = allCompanies;
                if (this.allCompanies.length === 1) {
                    this.form.controls.mandant.setValue(this.allCompanies[0]);
                }
            });
            // TODO: do we want this??
            // this.userService.currentCompanies.subscribe((currentCompanies) => {
            //     if (currentCompanies.length === 1){
            //         this.form.controls.mandant.setValue(currentCompanies[0]);
            //     }
            // })
        } else {
            this.children = this.person.personalInformation.children;
            this.hasPartner = this.person.personalInformation.partner != null;
            this.hasChildren = this.person.personalInformation.children != null;
            this.createEditPersonForm();
        }
        this.personalSubscriptions();
        this.familySubscriptions();
        if (!this.newPerson) {
            this.form.controls.sov.disable();
            this.form.controls.firstName.disable();
            this.form.controls.lastName.disable();
            this.form.controls.DateOfBirth.disable();
            this.form.controls.genderSelect.disable();
            this.form.controls.email.disable();
            this.form.controls.languageSelect.disable();
            this.form.controls.statusSelect.disable();
            this.form.controls.phone.disable();
            this.form.controls.telephone.disable();

            this.form.controls.lastNamePartner.disable();
            this.form.controls.firstNamePartner.disable();
            this.form.controls.sovPartner.disable();
            this.form.controls.dateOfBirthPartner.disable();
            this.form.controls.genderSelectPartner.disable();
            this.form.controls.maritalDate.disable();
            this.form.controls.divorceDate.disable();
        }
    }

    sovPoints(event) {
        this.sovInput = sovPoints(event);
    }

    formChanged() {
        this.formUntouched = false;
    }

    numberOnly(event): boolean {
        const charCode = event.which ? event.which : event.keyCode;
        if (charCode != 43 && charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }
    checkBoxAnswer(value) {
        if (value === 'no') {
            this.openPopup = false;
        }
        if (value === 'yes') {
            this.editDonee.emit();
            this.editDone.emit();
        }
    }
    setValidityDate(event) {
        this.form.controls.validityDate.setValue(event.target.value);
        this.validityDateInput = event.target.value;

        /// this.setDate.emit(this.validityDateNg);
    }

    convertToDate(date) {
        if (typeof date === 'string') {
            return new Date(date);
        }
    }

    addChildren(): void {
        this.childForm = this.fb.group({
            lastNameChild: this.lastNameChildControl,
            firstNameChild: this.firstNameChildControl,
            sovChild: this.sovChildControl,
            dateOfBirthChild: this.dateOfBirthChildControl,
            genderSelectChild: this.genderChildControl,
        });
        this.childSubcriptions();
        this.isAddChildren = true;
    }

    addChildrenAbort() {
        this.isAddChildren = false;
    }

    addChild() {
        const child = new Child();
        child.dateOfBirth = this.childForm.controls.dateOfBirthChild.value;
        child.firstName = this.childForm.controls.firstNameChild.value;
        child.lastName = this.childForm.controls.lastNameChild.value;
        child.language = this.form.controls.languageSelect.value;
        child.sex = this.childForm.controls.genderSelectChild.value;
        child.ssn13 = this.childForm.controls.sovChild.value;

        this.children.push(child);
        // rebuild form and reset all input values
        this.addChildren();
        this.childFirstNameInput = '';
        this.childLastNameInput = '';
        this.childSovInput = '';
        this.childDateOfBirthInput = undefined;
        this.childGenderInput = '';
        this.childForm.updateValueAndValidity();
        this.isAddChildren = false;
    }
    abort(value) {
        if (value === 'cancel') {
            if (this.form.dirty) {
                this.openPopup = true;
            } else {
                this.editDonee.emit();
            }
        } else {
            if (value === 'closeConfirmation') {
                this.openPopUpConfirmation = false;
                this.openPopUpConfirmationForNewEmployee = false;
            } else {
                this.editDonee.emit();
            }
        }
        if (value === 'closeCreationOfUser') {
            if (this.form.dirty) {
                this.openPopup = true;
            } else {
                this.editDone.emit();
            }
        }
    }
    submitStepOne() {
        this.openPopUpConfirmation = true;
    }
    openConfirmatinForNewEmployee() {
        this.openPopUpConfirmationForNewEmployee = true;
    }
    submit() {
        this.showSpinner = true;
        if (this.newPerson) {
            this.blendOutSaveButton = true;
            var arrayForControl = [
                this.form.controls.salary,
                this.form.controls.premiumWaiver,
                this.form.controls.iv,
                this.form.controls.employmentDegree,
            ];

            arrayForControl.forEach((element) => {
                var numberAsString = element.value.toString();

                element.setValue(parseInt(numberAsString));
            });
            this.createNewPerson();
        } else {
            this.person.personalInformation.addresses[0].street = this.form.controls.street.value;
            this.person.personalInformation.addresses[0].poBox = this.form.controls.poBox.value;
            this.person.personalInformation.addresses[0].city = this.form.controls.city.value;
            this.person.personalInformation.addresses[0].postalCode = this.form.controls.postalCode.value;
            this.person.personalInformation.addresses[0].country = this.form.controls.country.value;
            this.userService.createMutationAddressTransaction(this.person).subscribe({
                next: (_) => {
                    this.openPopUpConfirmationForNewEmployee = false;
                    this.showSpinner = false;
                    this.editDonee.emit();
                },
                error: async (err) => {
                    this.openPopUpConfirmationForNewEmployee = false;
                    this.showSpinner = false;
                    this.editDonee.emit();
                },
            });
        }
    }

    private createRegisterNewForm(): void {
        this.form = this.fb.group({
            mandant: [undefined, [Validators.required]],
            sov: this.generateSovControl(''),
            firstName: this.generateNameControl(''),
            lastName: this.generateNameControl(''),
            DateOfBirth: this.generateDateOfBirthControl(undefined),
            genderSelect: this.generateGenderControl(),
            statusSelect: this.generateStatusControl(MaritalStatusCodeType.UNKNOWN),
            // TODO: dynamic start Language?
            languageSelect: this.generateLanguageControl(Language.de),
            street: this.generateStreetControl(''),
            poBox: this.generatePoBoxControl(''),
            city: this.generateCityControl(''),
            postalCode: this.generatePostalCodeControl(''),
            country: this.generateCountryControl(''),
            email: this.generateEmailControl(''),
            phone: this.generatePhoneControl(''),
            telephone: this.generateTelephoneControl(''),
            // Secondary TAB: Employment Info:
            entryDate: this.entryDateControl,
            salary: this.salaryControl,
            validityDate: this.validityControl,
            employmentDegree: this.employmentDegreeControl,
            premiumWaiver: this.premiumWaiverControl,
            iv: this.ivControl,
            // Third TAB: family Info;
            lastNamePartner: this.generatePartnerLastNameControl(null),
            firstNamePartner: this.generatePartnerFirstNameControl(null),
            sovPartner: this.generatePartnerSovControl(null),
            dateOfBirthPartner: this.generatePartnerDateOfBirthControl(null),
            genderSelectPartner: this.generatePartnerGenderControl(null),
            maritalDate: this.generateMaritalDateControl(null),
            divorceDate: this.generateDivorceDateControl(null),
        });
        this.salarySubscriptions();
    }

    private createEditPersonForm() {
        // TODO what if multiple addresses?
        // TODO what if multiple partners?
        this.sovInput = this.person.personalInformation.ssn13 ? this.person.personalInformation.ssn13 : '';
        let address: Address;
        let partner: Partner;
        if (this.person.personalInformation.partner == null) {
        } else {
            partner = this.person.personalInformation.partner[0];
        }

        address = this.person.personalInformation.addresses[0];
        this.form = this.fb.group({
            // TODO: adapt for multiple insurances
            mandant: [this.person.insurances[0].companyId],
            sov: this.generateSovControl(
                this.person.personalInformation.ssn13 ? this.person.personalInformation.ssn13 : ''
            ),
            firstName: this.generateNameControl(this.person.personalInformation.firstName),
            lastName: this.generateNameControl(this.person.personalInformation.lastName),
            DateOfBirth: this.generateDateOfBirthControl(this.person.personalInformation.dateOfBirth),
            genderSelect: this.generateGenderControl(Gender[this.person.personalInformation.sex]),
            // TODO what if maritalStatus null?
            statusSelect: this.generateStatusControl(
                MaritalStatusCodeType[this.person.personalInformation.maritalStatus.code]
            ),
            // TODO what if language null?
            languageSelect: this.generateLanguageControl(Language[this.person.personalInformation.language]),

            // TODO does this work like this?
            street: this.generateStreetControl(address ? address.street : ''),
            poBox: this.generatePoBoxControl(address ? address.poBox : ''),
            city: this.generateCityControl(address ? address.city : ''),
            postalCode: this.generatePostalCodeControl(address ? address.postalCode : ''),
            country: this.generateCountryControl(address ? address.country : ''),
            email: this.generateEmailControl(address ? address.email : ''),
            phone: this.generatePhoneControl(address ? address.mobile : ''),
            telephone: this.generateTelephoneControl(address ? address.phone : ''),
            // Third TAB: family Info;

            lastNamePartner: this.generatePartnerLastNameControl(partner),
            firstNamePartner: this.generatePartnerFirstNameControl(partner),
            sovPartner: this.generatePartnerSovControl(partner),
            dateOfBirthPartner: this.generatePartnerDateOfBirthControl(partner),
            genderSelectPartner: this.generatePartnerGenderControl(partner),
            maritalDate: this.generateMaritalDateControl(partner),
            divorceDate: this.generateDivorceDateControl(partner),
        });
    }

    private generateSovControl(startValue: string) {
        return [startValue, [Validators.required, Validators.minLength(16), sovValidator()]];
    }

    private generateNameControl(startValue: string) {
        return [startValue, this.requiredLongStringValidator];
    }

    private generateGenderControl(startValue?: Gender) {
        if (startValue) {
            return [startValue, Validators.required];
        } else {
            return ['', Validators.required];
        }
    }

    private generateStatusControl(startValue: MaritalStatusCodeType) {
        return [startValue];
    }

    private generateLanguageControl(startValue: Language) {
        return [startValue];
    }

    private generateStreetControl(startValue: string) {
        return [startValue];
    }

    private generatePoBoxControl(startValue: string) {
        return [startValue];
    }

    private generateCityControl(startValue: string) {
        return [startValue, this.requiredLongStringValidator];
    }

    private generatePostalCodeControl(startValue: string) {
        return [startValue, [Validators.required, Validators.minLength(1), Validators.maxLength(10)]];
    }

    private generateCountryControl(startValue: string) {
        return [startValue, Validators.required];
    }

    private generateEmailControl(startValue: string) {
        return [startValue, conditionalValidator(() => this.emailInput.length > 0, Validators.email, 'emailError')];
    }

    private generatePhoneControl(startValue: string) {
        return [startValue];
    }

    private generateTelephoneControl(startValue: string) {
        return [startValue];
    }

    private generatePartnerLastNameControl(partner: Partner) {
        let name = '';
        if (partner) {
            name = partner.lastName;
        }
        return [name, [conditionalValidator(this.isPartnerStarted, Validators.required), Validators.maxLength(60)]];
    }

    private generatePartnerFirstNameControl(partner: Partner) {
        let name = '';
        if (partner) {
            name = partner.firstName;
        }
        return [name, [conditionalValidator(this.isPartnerStarted, Validators.required), Validators.maxLength(60)]];
    }

    private generatePartnerSovControl(partner: Partner) {
        let start = '';
        if (partner && partner.ssn13) {
            start = partner.ssn13;
        }
        return [
            start,
            [
                conditionalValidator(this.isPartnerStarted, Validators.required),
                conditionalValidator(this.isPartnerStarted, Validators.minLength(16)),
            ],
        ];
    }

    private generateDateOfBirthControl(startValue) {
        // TODO: check value type and pass

        return [
            undefined,
            [
                Validators.required,
                conditionalValidator(
                    () => this.dateOfBirthInput !== undefined,
                    validateDateAfter(
                        () => this.dateOfBirthInput,
                        () => this.minDateOfBirth
                    )
                ),
                conditionalValidator(
                    () => this.dateOfBirthInput !== undefined,
                    validateDateAfter(
                        () => this.maxDateOfBirth,
                        () => this.dateOfBirthInput
                    )
                ),
            ],
        ];
    }

    private generatePartnerDateOfBirthControl(partner: Partner) {
        let start;
        if (partner) {
            // TODO: test date
            start = partner.dateOfBirth;
        }
        return [
            start,
            [
                conditionalValidator(this.isPartnerStarted, Validators.required),
                conditionalValidator(
                    this.isPartnerStarted,
                    validateDateAfter(
                        () => this.maritalDateInput,
                        () => this.partnerDateOfBirthInput
                    )
                ),
            ],
        ];
    }

    private generatePartnerGenderControl(partner: Partner) {
        let start = '';
        if (partner && partner.sex) {
            start = partner.sex;
        }
        return [start, conditionalValidator(this.isPartnerStarted, Validators.required)];
    }

    private generateMaritalDateControl(partner: Partner) {
        let start;
        if (partner && partner.marriageDate) {
            // TODO: test date
            start = partner.marriageDate;
        }
        return [
            start,
            conditionalValidator(
                () => this.maritalDateInput !== undefined,
                validateDateAfter(
                    () => this.maritalDateInput,
                    () => this.dateOfBirthInput
                )
            ),
        ];
    }

    private generateDivorceDateControl(partner: Partner) {
        let start;
        if (partner && partner.divorceDate) {
            // TODO: test date
            start = partner.divorceDate;
        }
        // TODO: maybe check if divorce after marital??
        return [
            start,
            conditionalValidator(
                () => this.divorceDateInput !== undefined,
                validateDateAfter(
                    () => this.divorceDateInput,
                    () => this.dateOfBirthInput
                )
            ),
        ];
    }

    private personalSubscriptions(): void {
        this.form.controls.email.valueChanges.subscribe((value) => {
            this.emailInput = value;
            this.form.controls.email.updateValueAndValidity({ emitEvent: false });
        });

        this.form.controls.DateOfBirth.valueChanges.subscribe((value) => {
            this.dateOfBirthInput = value;
            this.updateAllDateFields();
        });
    }

    private salarySubscriptions(): void {
        this.form.controls.validityDate.valueChanges.subscribe((value) => {
            this.validityDateInput = value;
            this.updateAllDateFields();
        });
        this.form.controls.entryDate.valueChanges.subscribe((value) => {
            this.entryDateInput = value;
            this.updateAllDateFields();
        });

        this.form.controls.employmentDegree.valueChanges.subscribe((value) => {
            this.employmentDegreeInput = value;
            this.updateAllPercentageFields();
        });
        this.form.controls.premiumWaiver.valueChanges.subscribe((value) => {
            this.premiumWaiverInput = value;
            this.updateAllPercentageFields();
        });
        this.form.controls.iv.valueChanges.subscribe((value) => {
            this.ivInput = value;
            this.updateAllPercentageFields();
        });
    }

    private familySubscriptions(): void {
        this.form.controls.lastNamePartner.valueChanges.subscribe((value) => {
            this.partnerLastNameInput = value;
            this.updateAllPartnerFields();
        });
        this.form.controls.firstNamePartner.valueChanges.subscribe((value) => {
            this.partnerFirstNameInput = value;
            this.updateAllPartnerFields();
        });
        this.form.controls.sovPartner.valueChanges.subscribe((value) => {
            this.partnerSovInput = value;
            this.updateAllPartnerFields();
        });
        this.form.controls.dateOfBirthPartner.valueChanges.subscribe((value) => {
            this.partnerDateOfBirthInput = value;
            this.updateAllPartnerFields();
        });
        this.form.controls.genderSelectPartner.valueChanges.subscribe((value) => {
            this.partnerGenderInput = value;
            this.updateAllPartnerFields();
        });
        this.form.controls.maritalDate.valueChanges.subscribe((value) => {
            this.maritalDateInput = value;
            this.updateAllDateFields();
            this.updateAllPartnerFields();
        });
        this.form.controls.divorceDate.valueChanges.subscribe((value) => {
            this.divorceDateInput = value;
            this.updateAllDateFields();
            this.updateAllPartnerFields();
        });
    }

    private childSubcriptions(): void {
        this.childForm.controls.lastNameChild.valueChanges.subscribe((value) => {
            this.childLastNameInput = value;
            this.updateAllChildFields();
        });
        this.childForm.controls.firstNameChild.valueChanges.subscribe((value) => {
            this.childFirstNameInput = value;
            this.updateAllChildFields();
        });
        this.childForm.controls.sovChild.valueChanges.subscribe((value) => {
            this.childSovInput = value;
            this.updateAllChildFields();
        });
        this.childForm.controls.dateOfBirthChild.valueChanges.subscribe((value) => {
            this.childDateOfBirthInput = value;
            this.updateAllChildFields();
        });
        this.childForm.controls.genderSelectChild.valueChanges.subscribe((value) => {
            this.childGenderInput = value;
            this.updateAllChildFields();
        });
    }

    private updateAllDateFields(): void {
        this.form.controls.DateOfBirth.updateValueAndValidity({ emitEvent: false });
        this.form.controls.maritalDate.updateValueAndValidity({ emitEvent: false });
        this.form.controls.divorceDate.updateValueAndValidity({ emitEvent: false });
        if (this.newPerson) {
            this.form.controls.validityDate.updateValueAndValidity({ emitEvent: false });
            this.form.controls.entryDate.updateValueAndValidity({ emitEvent: false });
        }
    }

    private updateAllPercentageFields(): void {
        this.form.controls.employmentDegree.updateValueAndValidity({ emitEvent: false });
        this.form.controls.premiumWaiver.updateValueAndValidity({ emitEvent: false });
        this.form.controls.iv.updateValueAndValidity({ emitEvent: false });
    }

    private updateAllPartnerFields(): void {
        this.form.controls.lastNamePartner.updateValueAndValidity({ emitEvent: false });
        this.form.controls.firstNamePartner.updateValueAndValidity({ emitEvent: false });
        this.form.controls.sovPartner.updateValueAndValidity({ emitEvent: false });
        this.form.controls.dateOfBirthPartner.updateValueAndValidity({ emitEvent: false });
        this.form.controls.genderSelectPartner.updateValueAndValidity({ emitEvent: false });
        this.form.controls.maritalDate.updateValueAndValidity({ emitEvent: false });
        this.form.controls.divorceDate.updateValueAndValidity({ emitEvent: false });
    }

    private updateAllChildFields(): void {
        this.childForm.controls.lastNameChild.updateValueAndValidity({ emitEvent: false });
        this.childForm.controls.firstNameChild.updateValueAndValidity({ emitEvent: false });
        this.childForm.controls.sovChild.updateValueAndValidity({ emitEvent: false });
        this.childForm.controls.dateOfBirthChild.updateValueAndValidity({ emitEvent: false });
        this.childForm.controls.genderSelectChild.updateValueAndValidity({ emitEvent: false });
    }

    private notMoreThanFullEmployment(): ValidatorFn {
        return (_) => {
            let error = null;
            if (this.employmentDegreeInput + this.premiumWaiverInput + this.ivInput > 100) {
                error = {
                    employment: 'over employed',
                };
            }
            return error;
        };
    }

    private getSalary(): Salary {
        const salary = new Salary();

        salary.amount = this.form.controls.salary.value;
        salary.levelOfDisability = this.form.controls.iv.value;
        salary.levelOfEmployment = this.form.controls.employmentDegree.value;
        salary.levelOfPremiumWaiver = this.form.controls.premiumWaiver.value;
        // TODO: just 1 Status??
        salary.status = Status.DEFINITE;
        // TODO: how to know which one?
        salary.type = SalaryType.FULL;
        // TODO: check if right Date toString function
        salary.validity = this.form.controls.validityDate.value;

        return salary;
    }

    private getAddress(): Address {
        const address = new Address();
        address.street = this.form.controls.street.value;
        address.city = this.form.controls.city.value;
        address.postalCode = this.form.controls.postalCode.value;
        address.email = this.form.controls.email.value;
        address.country = this.form.controls.country.value;
        address.language = this.form.controls.languageSelect.value;
        address.mobile = this.form.controls.phone.value;
        address.phone = this.form.controls.telephone.value;
        address.poBox = this.form.controls.poBox.value;
        address.addressType = AddressType.MAIN;
        return address;
    }

    private getPersonalInformation(address: Address): PersonalInformation {
        const personalInformation = new PersonalInformation();
        personalInformation.dateOfBirth = this.form.controls.DateOfBirth.value;
        personalInformation.firstName = this.form.controls.firstName.value;
        personalInformation.lastName = this.form.controls.lastName.value;
        personalInformation.language = this.form.controls.languageSelect.value;
        personalInformation.sex = this.form.controls.genderSelect.value;
        personalInformation.ssn13 = this.form.controls.sov.value;
        // TODO: add title to form?
        personalInformation.addresses = [address];
        personalInformation.maritalStatus.code = this.form.controls.statusSelect.value;
        // TODO: add marital Date to maritalStatus
        // TODO: add Partner
        personalInformation.children = this.children;
        return personalInformation;
    }

    private getInsurance(salary: Salary): Insurance {
        const insurance = new Insurance();
        const role: Role = this.form.controls.mandant.value;
        insurance.companyId = role.companyId;
        insurance.foundationId = role.foundationId;
        insurance.pensionFundId = role.pensionFundId;
        insurance.employmentAdmittance = this.form.controls.entryDate.value;
        // TODO: what if they shouldn't be the same?
        insurance.insuranceAdmittance = insurance.employmentAdmittance;
        insurance.salaries = [salary];
        return insurance;
    }

    private createNewPerson() {
        const address = this.getAddress();
        const personalInformation = this.getPersonalInformation(address);
        const person = new Person();
        const salary = this.getSalary();
        const insurance = this.getInsurance(salary);

        person.personalInformation = personalInformation;
        // TODO: what to do if multiple?
        person.insurances = [insurance];
        this.transactionService
            .sendEmployeeAdmissionTransaction(
                person,
                this.form.controls.mandant.value.companyId,
                this.form.controls.mandant.value.pensionFundId,
                this.form.controls.mandant.value.foundationId
            )
            .subscribe({
                next: (_) => {
                    //this.confirmationBoxNewEmployee = true;
                    this.showSpinner = false;
                    this.openPopUpConfirmationForNewEmployee = false;
                    this.router.navigate(['/cabinet/insurances'], {
                        relativeTo: this.activatedRoute,
                        queryParams: {
                            createdNewUser: true,
                        },
                    });
                    // reload all Transactions
                    // TODO not anymore relevant after server-sent Events
                    this.transactionService.getAllEmployerTransactionsForUser();
                    this.editDone.emit();
                },
                error: async (err) => {
                    if (err.status === 401) {
                        // TODO this should not happen?? because it should be caught by auth interceptor
                        await this.auth.refreshTokenReq();
                        this.createNewPerson();
                    }
                    // TODO do something useful with error
                },
            });
    }
}
