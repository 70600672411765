import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from 'clan-auth';
const HEALTH_METRICS_ENDPOINT = '/metrics/health';
@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate {
    constructor(private authentication: AuthenticationService, private router: Router) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (state.url === HEALTH_METRICS_ENDPOINT) {
            return true;
          }
        
        if (this.authentication.isValid()) {
            return true;
        } else {
            this.authentication.removeFromStorage();
            this.router.navigate(['/auth'], {
                queryParams: {
                    redirectFrom: state.url,
                },
            });
            return false;
        }
    }
}
