import { Component, ViewEncapsulation, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { UserService } from 'src/app/core/services/user.service';
import { AuthenticationService } from 'clan-auth';

import { trigger, state, transition, animate, style } from '@angular/animations';
import { LocaleService } from 'src/app/core/services/locale.service';
import { TransactionsService } from 'src/app/core/services/transactions.service';
import { Router } from '@angular/router';
import de from '../../../../assets/locale/de.json';
import en from '../../../../assets/locale/en.json';
import fr from '../../../../assets/locale/fr.json';
import it from '../../../../assets/locale/it.json';
import { Subject } from 'rxjs';
import { Person, Salary } from 'models';

@Component({
    selector: 'app-insurant-show',
    templateUrl: './insurant-show.component.html',
    styleUrls: ['./insurant-show.component.scss'],
    animations: [
        trigger('openClose', [
            state(
                'open',
                style({
                    height: '300px',
                    opacity: 1,
                    //backgroundColor: 'red',
                })
            ),
            state(
                'closed',
                style({
                    height: '0px',
                    opacity: 0.5,
                    overflow: 'hidden',
                })
            ),
            transition('open => closed', [animate('0.5s')]),
            transition('closed => open', [animate('0.5s')]),
        ]),
    ],
    encapsulation: ViewEncapsulation.None,
})
export class InsurantShowComponent implements OnInit {
    @Output() clickClosePanel: EventEmitter<void> = new EventEmitter();
    @Input() person: Person;
    @Input() editPerson: (person: Person) => void;
    @Input() pageOpen = 'personalData';
    @Input() edit;
    @Input() wantToClose: Subject<boolean>;

    hasChildren: Boolean;
    showSpinner = false;
    result = null;
    stepTwoEmployment = false;
    stepTwoSalary = false;
    stepTwoUnpaidHoliday = false;
    isOpen = false;
    openPopup = false;

    //Employment
    terminateDate = new Date();
    terminationReason;
    isTerminateDateValid;
    terminateButtonActive = false;
    terminateConfirmationDisplay = false;

    //salary
    salaryDate = new Date();
    salary = 0;
    newSalary: Salary;
    isSalaryDateValid = false;
    degreeOfEmployment = 0;
    salaryConfirmationDisplay = false;
    salaryButtonActive = false;
    showSalaryHistory = false;

    employmentDegreeInvalid = false;
    //inpaid Holiday
    holidayStartDate = new Date();
    holidayEndDate = new Date();
    isHolidayStartDateValide;
    isHolidayEndDateValide;
    holidayInsurance;
    holidayReason;
    unpaidHolidayConfirmationDisplay = false;
    unpaidHolidayButtonAcitve = false;
    selectedIndex = 0;

    minDateOfBirth: any;
    maxDateOfBirth: any;

    clearValue = '';

    reasonList = ['Stellenwechsel', 'Pension', 'Tod'];
    reasonListContinueInsurance = [];
    reasonListCreateDelete = [];
    terminationReasonValue: string;
    terminationDateValue: string;
    salaryDateValue: string;
    salaryValue: string;
    degreeOfEmplymentValue: string;
    unpaidHolidayStartValue: string;
    unpaidHolidayEndValue: string;
    insuranceChangeValue: string;
    deleteValue: string;
    currentLocale;
    lang;

    currentlyCreatedTransactionId: string;

    errorMessage = false;

    fakeData = [
        { start: '2022-05-05', end: '2022-05-10', text: 'Sparen/Risik zulasten Arbeitnehmer', status: 'Fertig' },
        { start: '2022-06-06', end: '2022-06-12', text: 'Sparen/Risik zulasten Arbeitnehmer', status: 'Fertig' },
    ];
    constructor(
        private localeService: LocaleService,
        private transactionService: TransactionsService,
        private userService: UserService,
        private auth: AuthenticationService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.wantToClose.subscribe((v) => {
            this.abort(0);
        });
        if (this.edit) {
            this.showHistory();
        }
        this.localeService.$currentLocale.subscribe((locale) => {
            this.currentLocale = locale;
            this.lang = this.currentLocale.toString();
        });
        this.pageOpen = 'startPage';
        if (this.lang === 'de') {
            this.reasonList = [
                de.cabinet.profile.employment.reasonList.death,
                de.cabinet.profile.employment.reasonList.jobChange,
                de.cabinet.profile.employment.reasonList.pension,
            ];
            this.reasonListContinueInsurance = [
                de.cabinet.profile.employment.reasonListContinueInsurance.riskToEmployee,
                de.cabinet.profile.employment.reasonListContinueInsurance.riskSafeToEmployee,
            ];
            this.reasonListCreateDelete = [
                de.cabinet.profile.employment.reasonListCreateDelete.create,
                de.cabinet.profile.employment.reasonListCreateDelete.delete,
            ];
        }
        if (this.lang === 'en') {
            this.reasonList = [
                en.cabinet.profile.employment.reasonList.death,
                en.cabinet.profile.employment.reasonList.jobChange,
                en.cabinet.profile.employment.reasonList.pension,
            ];
            this.reasonListContinueInsurance = [
                en.cabinet.profile.employment.reasonListContinueInsurance.riskToEmployee,
                en.cabinet.profile.employment.reasonListContinueInsurance.riskSafeToEmployee,
            ];
            this.reasonListCreateDelete = [
                en.cabinet.profile.employment.reasonListCreateDelete.create,
                en.cabinet.profile.employment.reasonListCreateDelete.delete,
            ];
        }
        if (this.lang === 'fr') {
            this.reasonList = [
                fr.cabinet.profile.employment.reasonList.death,
                fr.cabinet.profile.employment.reasonList.jobChange,
                fr.cabinet.profile.employment.reasonList.pension,
            ];
            this.reasonListContinueInsurance = [
                fr.cabinet.profile.employment.reasonListContinueInsurance.riskToEmployee,
                fr.cabinet.profile.employment.reasonListContinueInsurance.riskSafeToEmployee,
            ];
            this.reasonListCreateDelete = [
                fr.cabinet.profile.employment.reasonListCreateDelete.create,
                fr.cabinet.profile.employment.reasonListCreateDelete.delete,
            ];
        }
        if (this.lang === 'it') {
            this.reasonList = [
                it.cabinet.profile.employment.reasonList.death,
                it.cabinet.profile.employment.reasonList.jobChange,
                it.cabinet.profile.employment.reasonList.pension,
            ];
            this.reasonListContinueInsurance = [
                it.cabinet.profile.employment.reasonListContinueInsurance.riskToEmployee,
                it.cabinet.profile.employment.reasonListContinueInsurance.riskSafeToEmployee,
            ];
            this.reasonListCreateDelete = [
                it.cabinet.profile.employment.reasonListCreateDelete.create,
                it.cabinet.profile.employment.reasonListCreateDelete.delete,
            ];
        }

        if (this.person?.personalInformation?.children == null) {
            this.hasChildren = false;
        } else {
            this.hasChildren = true;
        }
        this.newSalary = new Salary();
        this.newSalary.validity = new Date().toString();
    }

    open(value) {
        this.pageOpen = value;
        this.stepTwoEmployment = false;
        this.stepTwoSalary = false;
        this.stepTwoUnpaidHoliday = false;
    }
    abort(value) {
        var hasChanges = false;
        if (
            this.salaryValue != undefined ||
            this.salaryDateValue != undefined ||
            this.degreeOfEmplymentValue != undefined
        ) {
            hasChanges = true;
        }
        if (this.terminationReason != undefined || this.terminationDateValue != undefined) {
            hasChanges = true;
        }
        if (hasChanges) {
            this.openPopup = true;
        } else {
            this.clickClosePanel.emit();
        }

        /* if (value === 'cancel') {
        if (this.form.dirty) {
            this.openPopup = true;
        } else {
            this.editDonee.emit();
        }
    } else {
        if (value === 'closeConfirmation') {
            this.openPopUpConfirmation = false;
            this.openPopUpConfirmationForNewEmployee = false;
        }else{
            this.editDonee.emit();
        }
    }
    if(value === 'closeCreationOfUser'){
        if(this.form.dirty){
            this.openPopup = true;
        }else{
        this.editDone.emit();
        }
    } */
    }
    checkBoxAnswer(value) {
        if (value === 'no') {
            this.openPopup = false;
        }
        if (value === 'yes') {
            this.clickClosePanel.emit();
        }
    }
    //Employment Termination
    terminationDateChange(event) {
        this.terminateDate = event.target.value;
        var year = this.terminateDate.toString().substring(0, 4);
        if (year > '2000' && year < '2030') {
            this.isTerminateDateValid = true;
            if (this.terminationReason != undefined) {
                this.terminateButtonActive = true;
            }
        } else {
            this.terminateButtonActive = false;
            this.isTerminateDateValid = false;
        }
    }
    terminateReasonChange(event) {
        this.terminationReason = event.value;
        if (this.isTerminateDateValid) {
            this.terminateButtonActive = true;
        }
    }
    cancleConfirmation() {
        this.terminateConfirmationDisplay = false;
        this.salaryConfirmationDisplay = false;
        this.unpaidHolidayConfirmationDisplay = false;
        this.stepTwoEmployment = false;
        this.stepTwoSalary = false;
        this.stepTwoUnpaidHoliday = false;
    }
    openTerminationConfirmation() {
        this.terminateConfirmationDisplay = true;
    }
    openSalaryConfirmation() {
        if (this.degreeOfEmployment > 100 || this.degreeOfEmployment < 0) {
            this.employmentDegreeInvalid = true;
            return;
        } else {
            this.employmentDegreeInvalid = false;
        }
        this.salaryConfirmationDisplay = true;
    }
    openUnpaidHolidayConfirmation() {
        this.unpaidHolidayConfirmationDisplay = true;
    }
    clickCloseErrorMessage() {
        this.errorMessage = false;
    }

    //Salary
    salaryDateChange(event) {
        this.salaryDate = event.target.value;
        var year = this.salaryDate.toString().substring(0, 4);
        if (year > '2000' && year < '2030') {
            this.isSalaryDateValid = true;
            if (this.degreeOfEmployment > 0 && this.salary > 0) {
                this.salaryButtonActive = true;
            }
        } else {
            this.salaryButtonActive = false;
            this.isSalaryDateValid = false;
        }
    }
    salaryChange(event) {
        this.salary = event.target.value;
        if (this.salary.toString().charAt(0) == '0') {
            this.salary = parseInt(this.salary.toString());
        }
        if (this.salary < 1) {
            this.salaryButtonActive = false;
        }
        if (this.degreeOfEmployment == 0 || this.degreeOfEmployment == undefined || !this.isSalaryDateValid) {
            this.salaryButtonActive = false;
        }
        if (this.salary > 0 && this.degreeOfEmployment > 0 && this.isSalaryDateValid) {
            this.salaryButtonActive = true;
        }
    }
    onlyNumbers(event): boolean {
        const charCode = event.which ? event.which : event.keyCode;
        if (
            event.key == '*' ||
            event.key == '+' ||
            event.key == '"' ||
            event.key == 'ç' ||
            event.key == '%' ||
            event.key == '&' ||
            event.key == '/' ||
            event.key == '(' ||
            event.key == ')' ||
            event.key == '=' ||
            event.key == '?' ||
            event.key == '^' ||
            event.key == '@' ||
            event.key == '§' ||
            event.key == '#' ||
            event.key == '°' ||
            event.key == '|' ||
            event.key == '¢' ||
            event.key == '~~' ||
            event.key == '¬' ||
            event.key == '¦' ||
            event.key == '`=' ||
            event.key == '^^'
        ) {
            return false;
        }
        if (charCode > 95 && charCode < 106) {
            return true;
        }
        if (charCode != 43 && charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }
    degreeOfEmplyomentChange(event) {
        this.degreeOfEmployment = event.target.value;
        if (this.degreeOfEmployment.toString().charAt(0) == '0') {
            this.degreeOfEmployment = parseInt(this.degreeOfEmployment.toString());
        }
        if (this.degreeOfEmployment == 0 || this.degreeOfEmployment == undefined) {
            this.salaryButtonActive = false;
        }
        if (this.salary == 0 || this.salary == undefined || !this.isSalaryDateValid) {
            this.salaryButtonActive = false;
            return;
        }
        if (this.degreeOfEmployment > 0 && this.salary > 0 && this.isSalaryDateValid) {
            this.salaryButtonActive = true;
        }
    }
    changeSalary() {
        // send request
        this.showSpinner = true;

        //TODO: check if everything entered
        this.newSalary.amount = this.salary;
        this.newSalary.levelOfEmployment = this.degreeOfEmployment;
        this.newSalary.validity = this.salaryDate.toString();
        this.userService.changeSalaryTransaction(this.person, this.newSalary).subscribe({
            next: (success) => {
                this.currentlyCreatedTransactionId = success.transactionId;
                this.transactionService.getAllEmployerTransactionsForUser();
                this.salaryDateValue = '';
                this.salaryValue = '';
                this.degreeOfEmplymentValue = '';
                this.salaryConfirmationDisplay = false;
                this.stepTwoSalary = true;
                this.showSpinner = false;
                this.salaryButtonActive = false;
            },
            error: async (error) => {
                // if not ok - do anything
                this.errorMessage = true;
                this.salaryDateValue = '';
                this.salaryValue = '';
                this.degreeOfEmplymentValue = '';
                if (error.status === 401) {
                    this.showSpinner = false;
                    await this.auth.refreshTokenReq();
                    this.changeSalary();
                    return;
                }
                this.salaryButtonActive = false;
                this.showSpinner = false;
                this.salaryConfirmationDisplay = false;
            },
        });
    }
    goToPreview() {
        this.router.navigate(['/cabinet/mutationHistory'], {
            queryParams: {
                search: this.currentlyCreatedTransactionId,
                status: '',
                type: '',
            },
        });
    }
    //terminate
    terminate() {
        this.showSpinner = true;

        this.userService.terminateUserTransaction(this.person, this.terminateDate.toString()).subscribe({
            next: (success) => {
                this.currentlyCreatedTransactionId = success.transactionId;
                this.terminationDateValue = '';
                this.terminationReasonValue = '';
                this.terminateConfirmationDisplay = false;
                this.terminateButtonActive = false;
                this.stepTwoEmployment = true;
                this.result = true;
                this.showSpinner = false;
            },
            error: async (error) => {
                this.terminationDateValue = '';
                this.terminationReasonValue = '';
                this.errorMessage = true;
                this.result = false;
                this.terminateConfirmationDisplay = false;
                this.terminateButtonActive = false;
                this.showSpinner = false;
            },
        });
    }
    //unpaidHoliday
    unpaidHolidayDateChange(event, type) {
        if (type === 'start') {
            this.holidayStartDate = event.target.value;
            var year = this.holidayStartDate.toString().substring(0, 4);
            if (year > '2000' && year < '2030') {
                this.isHolidayStartDateValide = true;
                this.checkIfFormValid();
            }
        }
        if (type === 'end') {
            this.holidayEndDate = event.target.value;
            var year = this.holidayEndDate.toString().substring(0, 4);
            if (year > '2000' && year < '2030') {
                this.isHolidayEndDateValide = true;
                this.checkIfFormValid();
            }
        }
    }
    checkIfFormValid() {
        if (
            this.isHolidayEndDateValide &&
            this.isHolidayStartDateValide &&
            this.holidayInsurance != undefined &&
            this.holidayReason != undefined
        ) {
            this.unpaidHolidayButtonAcitve = true;
        } else {
            this.unpaidHolidayButtonAcitve = false;
        }
    }
    continueInsuranceChange(event) {
        this.holidayInsurance = event.value;
        this.checkIfFormValid();
    }
    deleteChange(event) {
        this.holidayReason = event.value;
        this.checkIfFormValid();
    }
    unpaidHolidayRequest() {
        //logic when sending the request
        this.unpaidHolidayConfirmationDisplay = false;
        this.unpaidHolidayButtonAcitve = false;
        //this.stepTwoUnpaidHoliday = true;
        this.errorMessage = true;
        this.unpaidHolidayStartValue = '';
        this.unpaidHolidayEndValue = '';
        this.insuranceChangeValue = '';
        this.deleteValue = '';
    }

    selectLastSalary(person: Person): Salary {
        let lastSalary = person.insurances[0].salaries[0];

        person.insurances[0].salaries.forEach((item) => {
            const d1 = new Date(lastSalary.validity);
            const d2 = new Date(item.validity);

            if (d2 > d1) {
                lastSalary = item;
            }
        });

        /*  this.salaryInput = lastSalary.amount.toString();
    this.employmentInput = lastSalary.levelOfEmployment.toString();
    this.disabilityInput = lastSalary.levelOfDisability.toString(); */
        return lastSalary;
    }
    reformateDate(dt) {
        return (
            dt.getFullYear() +
            '-' +
            (dt.getMonth() > 8 ? dt.getMonth() + 1 : '0' + (dt.getMonth() + 1)) +
            '-' +
            (dt.getDate() > 9 ? dt.getDate() : '0' + dt.getDate())
        );
    }
    convertToDate(date) {
        if (typeof date === 'string') {
            return new Date(date);
        }
    }

    callEditPerson() {
        this.editPerson(this.person);
    }
    showHistory() {
        this.showSalaryHistory = !this.showSalaryHistory;
        this.isOpen = !this.isOpen;
    }
}
