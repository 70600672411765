import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-common-document',
    templateUrl: './common-document.component.html',
    styleUrls: ['./common-document.component.scss'],
})
export class CommonDocumentComponent implements OnInit {
    @Input() documentTitle: string;
    @Input() documentType: DocType;

    @Output() downloadDocument: EventEmitter<void> = new EventEmitter<void>();

    constructor() {}

    downloadButtonClick(): void {
        this.downloadDocument.emit();
    }

    ngOnInit(): void {}
}

type DocType = 'small' | 'big';
