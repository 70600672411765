import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Console } from 'console';
import { PaginationService } from '../../services/pagination.service';
@Component({
    selector: 'app-paginator-costum',
    templateUrl: './paginator-costum.component.html',
    styleUrls: ['./paginator-costum.component.scss'],
})
export class PaginatorCostumComponent implements OnInit {
    @Input() countOfElements = 0;
    @Input() listCountOnPage = [9, 18, 36];
    @Output() changePagesOnList: EventEmitter<number> = new EventEmitter();
    @Output() changeCurrentPage2: EventEmitter<number | string> = new EventEmitter();
    countOnPage = 9;
    selectedPage = 1;
    pages = [1];
    showDropdown = false;

    constructor(private paginationService: PaginationService) {
        this.paginationService.currentPage2.subscribe((selectedPage) => (this.selectedPage = selectedPage));
    }

    ngOnInit(): void {
        this.countOnPage = this.listCountOnPage[0];
        this.calculatePages();
    }

    calculatePages() {
        this.pages = [];
        const pageCount = Math.ceil(this.countOfElements / this.countOnPage);
        if (pageCount <= 5) {
            for (let i = 1; i <= pageCount; i++) {
                this.pages.push(i);
            }
        } else {
            if (this.selectedPage === 1 || this.selectedPage === 2 || this.selectedPage === 3) {
                for (let i = 1; i <= 3; i++) {
                    this.pages.push(i);
                }
                if (this.selectedPage === 3) {
                    this.pages.push(4);
                    this.pages.push(0);
                } else {
                    this.pages.push(0);
                }
                this.pages.push(pageCount);
            } else if (this.selectedPage === pageCount || this.selectedPage >= pageCount - 2) {
                this.pages.push(1);
                this.pages.push(0);
                for (let i = this.selectedPage === pageCount - 2 ? pageCount - 3 : pageCount - 2; i <= pageCount; i++) {
                    this.pages.push(i);
                }
            } else {
                this.pages.push(1);
                this.pages.push(0);
                for (let i = this.selectedPage - 1; i <= this.selectedPage + 1; i++) {
                    this.pages.push(i);
                }
                this.pages.push(0);
                this.pages.push(pageCount);
            }
        }
    }

    setPage(settedPage: number | string) {
        const page = parseInt(settedPage + '', 10);
        if (page > 0) {
            if (page > Math.ceil(this.countOfElements / this.countOnPage)) {
                this.selectedPage = Math.ceil(this.countOfElements / this.countOnPage);
            } else {
                this.selectedPage = page;
            }
            this.calculatePages();

            this.changeCurrentPage2.emit(settedPage);
        }
    }

    setShowDropdown(show: boolean) {
        this.showDropdown = show;
        event.stopPropagation();
    }

    setCountPerPage(cnt: number) {
        this.countOnPage = cnt;
        this.selectedPage = 1;
        this.calculatePages();
        this.showDropdown = false;
        this.changePagesOnList.emit(cnt);
        this.changeCurrentPage2.emit(1);
    }
}
