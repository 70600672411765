import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthenticationService } from 'clan-auth';
import { Observable } from 'rxjs';
import { UserService } from '../services/user.service';
import { Person, RoleType } from 'models';

@Injectable({
    providedIn: 'root',
})
export class RoleGuard implements CanActivate {
    private myData: Person;

    constructor(
        private authentication: AuthenticationService,
        private router: Router,
        private userService: UserService
    ) {
        userService.currentUser.subscribe((data) => (this.myData = data));
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (this.myData) {
            for (const role of this.myData.roles) {
                if (RoleType.EMPLOYER === role.roleType) {
                    return true;
                }
            }
            this.authentication.removeFromStorage();
            //TODO: navigate to no access allowed site
            this.router.navigate(['/auth'], {
                queryParams: {
                    redirectFrom: state.url,
                },
            });
            return false;
        }
        return new Promise<boolean>((resolve, reject) => {
            this.userService.getData().then(
                (userdata) => {
                    for (const role of userdata.roles) {
                        if (RoleType.EMPLOYER === role.roleType) {
                            resolve(true);
                            return;
                        }
                    }
                    this.authentication.removeFromStorage();
                    //TODO: navigate to no access allowed site
                    this.router.navigate(['/auth'], {
                        queryParams: {
                            redirectFrom: state.url,
                        },
                    });
                    resolve(false);
                },
                () => {
                    resolve(false);
                }
            );
        });
    }
}
