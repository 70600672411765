import { ValidatorFn } from '@angular/forms';

export function sovValidator(): ValidatorFn {
    return (control) => {
        let sov = control.value;
        let error = {};
        if (typeof control.value !== 'string') {
            error['type'] = true;
            return error;
        }
        if (sov.length < 16) {
            error['length'] = 'too short';
        } else if (sov.length > 16) {
            error['length'] = 'too long';
        } else {
            let checkDigit =
                parseInt(sov.charAt(14)) * 3 +
                /* POINT */
                parseInt(sov.charAt(12)) +
                parseInt(sov.charAt(11)) * 3 +
                parseInt(sov.charAt(10)) +
                parseInt(sov.charAt(9)) * 3 +
                /* POINT */
                parseInt(sov.charAt(7)) +
                parseInt(sov.charAt(6)) * 3 +
                parseInt(sov.charAt(5)) +
                parseInt(sov.charAt(4)) * 3 +
                /* POINT */
                parseInt(sov.charAt(2)) +
                parseInt(sov.charAt(1)) * 3 +
                parseInt(sov.charAt(0));

            let newCheckDigit = Math.ceil(checkDigit / 10) * 10;
            if (newCheckDigit - checkDigit == parseInt(sov.charAt(15))) {
                error = null;
            } else {
                error['valid'] = 'sov is invalid';
            }
        }
        return error;
    };
}

export function sovPoints(event) {
    let sovInput = event.target.value;
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode == 46) {
        return;
    }
    if (sovInput.length == 17) {
        sovInput = sovInput.slice(0, -1);
        return sovInput;
    }
    if (sovInput.length == 3 || sovInput.length == 8 || sovInput.length == 13) {
        if (charCode == 8) {
            sovInput = sovInput.slice(0, -1);
            return sovInput;
        } else {
            sovInput = sovInput + '.';
            return sovInput;
        }
    }
    return sovInput;
}
