<div class="dropdown" [ngClass]="{ light: (1 | colourTheme) === false }" (clickOutside)="closeMenu()">
    <div class="dropdown-label" [ngClass]="{ light: (1 | colourTheme) === false }">{{ title }}</div>
    <div class="dropdown-text" (click)="openMenu()" [ngClass]="{ light: (1 | colourTheme) === false }">
        <input
            [ngClass]="{ light: (1 | colourTheme) === false }"
            type="text"
            [value]="localeTranslationPath + value | translate"
            readonly
        />
        <div class="dropdown-button" [ngClass]="{ light: (1 | colourTheme) === false }">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.83337 8.33398L10 12.5007L14.1667 8.33398H5.83337Z" fill="#61646E" />
            </svg>
        </div>
    </div>
    <div class="dropdown-menu" [ngClass]="{ light: (1 | colourTheme) === false, show: showMenu }">
        <div
            class="dropdown-menu__item"
            [ngClass]="{ light: (1 | colourTheme) === false }"
            *ngFor="let element of list"
            (click)="selectElement(element)"
            [title]="element"
        >
            {{ localeTranslationPath + element | translate }}
        </div>
    </div>
</div>
