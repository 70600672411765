import { HttpClient } from '@angular/common/http';
import { Injectable, Optional, SkipSelf } from '@angular/core';
import { environment } from '../../../environments/environment';
import { LocaleService } from './locale.service';
import { lastValueFrom, Observable, ReplaySubject } from 'rxjs';
import { UserService } from './user.service';
import {
    Assignee,
    Person,
    Role,
    Transaction,
    TransactionStatus,
    TransactionType,
    EmployeeAdmissionTransaction,
} from 'models';

@Injectable({
    providedIn: 'root',
})
export class TransactionsService {
    private allTransactionsData: Transaction[][] = null;
    private selectedTrinities: Role[] = null;
    private allTransactions: ReplaySubject<Transaction[][]> = new ReplaySubject<Transaction[][]>(1);
    private transactionsPendingPerPerson: ReplaySubject<Map<string, number>> = new ReplaySubject<Map<string, number>>(
        1
    );
    private admissionsPending: ReplaySubject<Person[]> = new ReplaySubject<Person[]>(1);

    constructor(
        private http: HttpClient,
        private localeService: LocaleService,
        private userService: UserService,
        @Optional() @SkipSelf() parentModule?: TransactionsService
    ) {
        if (parentModule) {
            throw new Error('TransactionsService is already loaded. Import it in the AppModule only');
        }
        this.userService.currentCompanies.subscribe({
            next: (selectedTrinities) => {
                this.selectedTrinities = selectedTrinities;
                if (this.allTransactionsData != null) {
                    this.filterTransactionsBySelectedCompanies();
                }
            },
        });

        this.allTransactions.subscribe({
            next: (value) => {
                this.updatePendingTransactions(value);
                this.updatePendingAdmissions(value);
            },
        });
    }

    get allEmployerTransactions(): Observable<Transaction[][]> {
        return this.allTransactions.asObservable();
    }

    get pendingTransactionsPerPerson(): Observable<Map<string, number>> {
        return this.transactionsPendingPerPerson.asObservable();
    }

    get pendingAdmissions(): Observable<Person[]> {
        return this.admissionsPending.asObservable();
    }
    get apiUrl(): string {
        return sessionStorage.getItem('api-url');
    }
    // TODO: not enabled on clan-client
    // private getTransaction(transactionId) {
    //     return this.http.get<Company>(`${this.apiUrl}/transaction/${transactionId}`);
    // }
    // public getPurchaseTransaction(): Observable<Transaction[]>{
    //     return this.getTransactions(null, null, null, null, null, null, null);
    // }

    // TODO: check for best return type
    sendEmployeeAdmissionTransaction(
        person: Person,
        companyId: string,
        pensionFundId: string,
        foundationId: string
    ): Observable<EmployeeAdmissionTransaction> {
        const transaction = new EmployeeAdmissionTransaction();
        transaction.company = foundationId;
        transaction.tenant = foundationId;
        transaction.data = person;
        transaction.assignee = Assignee.PVCLAN;
        transaction.originator = {
            companyId,
            pensionFundId,
            foundationId,
            personId: person.personId,
        };
        return this.http.post<EmployeeAdmissionTransaction>(`${this.apiUrl}/transactions`, transaction);
    }

    async getAllEmployerTransactionsForUser(): Promise<void> {
        let transactions = await lastValueFrom(this.getEmployerRelevantTransactions());
        if (!transactions.length || transactions.length == 0) {
            this.allTransactionsData = [];
        } else {
            this.allTransactionsData = this.sortTransactionArrayByDate(transactions);
        }
        this.filterTransactionsBySelectedCompanies();
    }

    private filterTransactionsBySelectedCompanies() {
        let relevantTransactions: Transaction[][] = [];
        for (const trinity of this.selectedTrinities) {
            let trinityTransactions: Transaction[][] = this.allTransactionsData.filter(
                (transaction) =>
                    transaction[0].originator.foundationId == trinity.foundationId &&
                    transaction[0].originator.pensionFundId == trinity.pensionFundId &&
                    transaction[0].originator.companyId == trinity.companyId
            );
            relevantTransactions = trinityTransactions.concat(relevantTransactions);
        }
        this.allTransactions.next(relevantTransactions);
    }

    private updatePendingTransactions(value: Transaction[][]) {
        let pendingTransactionCount = new Map<string, number>();
        const pendingTransactions = value.filter((t) => t[0].status == TransactionStatus.PENDING);
        pendingTransactions.forEach((transaction) => {
            const personId = transaction[0].originator.personId;
            if (pendingTransactionCount.has(personId)) {
                pendingTransactionCount.set(personId, pendingTransactionCount.get(personId) + 1);
            } else {
                pendingTransactionCount.set(personId, 1);
            }
        });
        this.transactionsPendingPerPerson.next(pendingTransactionCount);
    }

    private updatePendingAdmissions(value: Transaction[][]) {
        let admissionsList: Person[] = [];
        const pendingAdmissions = value.filter(
            (t) =>
                t[0].status == TransactionStatus.PENDING && t[0].transactionType == TransactionType.EMPLOYER_ADMISSION
        ) as EmployeeAdmissionTransaction[][];
        pendingAdmissions.forEach((admission) => {
            let person: Person = admission[0].data;
            admissionsList.push(person);
        });
        this.admissionsPending.next(admissionsList);
    }

    public sortTransactionArrayByDate(transactions: Transaction[][]): Transaction[][] {
        return transactions.sort((a, b) => (new Date(a[0].timestamp) > new Date(b[0].timestamp) ? -1 : 1));
    }

    async getAllUploadTransactions(): Promise<Transaction[][]> {
        let transactions = await lastValueFrom(this.getUploadTransactions());
        return this.sortTransactionArrayByDate(transactions);
    }

    private getEmployerRelevantTransactions() {
        return this.http.get<Transaction[][]>(`${this.apiUrl}/transactions/employer`);
    }

    private getUploadTransactions() {
        return this.http.get<Transaction[][]>(
            `${this.apiUrl}/transactions?type=${TransactionType.EMPLOYER_BULK_MUTATIONS}`
        );
    }

    public sendTransaction(transaction: Transaction) {
        return this.http.post<any>(`${this.apiUrl}/transactions`, transaction);
    }

    public getTransactionById(transactionId: number) {
        return this.http.get<Transaction[]>(`${this.apiUrl}/transactions/${transactionId}`);
    }
}
